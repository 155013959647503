import * as React from 'react'
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { ScheduledActivity } from '../../../components/activity_generator/ScheduledActivity';
import { deleteData } from '../../../utils/Firebase';
import { ActivityStatus, data, getDataPath, getModulesPath, modules } from '../../../utils/constants';
import ScheduledActivityViewer from './ScheduledActivityViewer';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { removeModule } from '../../../app/utils';
import "./ScheduledActivityCard.scss";
import { CheckIcon } from '../../../icons/CheckIcon';
import { covertTimestampToStringTime } from '../../../utils/TimeUtils';
import { CalendarData } from '../../../components/calendar/CalendarData';
import { generateId } from '../../../utils/Utils';
import { BaseModule, IBaseModule } from '../BaseModule';
import { Column } from '../../../components/layout/column/Column';
import { BoxedModule } from '../../BoxedModule';
import { DataStore } from '../../../app/DataStore/DataStore';

export class ScheduledActivityCardModule extends CardModule {
    scheduledActivity: ScheduledActivity;

    constructor(
        baseProps,
        cardModuleProps: ICardModuleProps,
        dataStore: DataStore
    ) {
        super(baseProps, cardModuleProps, dataStore);
        if (this.checkbox === undefined) {
            this.checkbox = true;
            this.checkboxChecked = false;
        }
        Object.assign(this);
    }

    async init() {
        // if ((this as ScheduledActivityCardModule).scheduledActivity instanceof ScheduledActivity === false) {
        //     const scheduledActivityId: string = (this as unknown as ScheduledActivityCardModule).scheduledActivity as unknown as string;
        //     // dataContext.scheduledActivities.getScheduledActivity(scheduledActivityId).then((scheduledActivity: ScheduledActivity) => {
        //     //     (this as unknown as ScheduledActivityCardModule).scheduledActivity = scheduledActivity;
        //     // })
        // }
        this.dataStore.data.scheduledActivities.getScheduledActivity(this.id).then((scheduledActivity: ScheduledActivity) => {
            this.scheduledActivity = scheduledActivity;
        })
    }

    toggleCardContainer = () =>  <>
        <Column alignLeft>
            <div>parent: {this.parentModule?.name}</div>
            <div>user: {this.user}</div>
            <div>id: {this.id}</div>
            <div>type: {this.type}</div>
        </Column>
        {(this as ScheduledActivityCardModule).scheduledActivityContainerContent()}
        <BoxedModule
            testId={`card-container`}
            module={this}
            moduleName={this.name}
            onDelete={() => {
                removeModule(this);
            }}
            dragHandle={''}
            onHeaderNameUpdate={(name: string) => {

            }}
            content={<div>
                {(this as ScheduledActivityCardModule).cardContainerContent()}
            </div>}
        />
    </>

    onCheckboxChange = (module: BaseModule) => {
        // super.onCheckboxChange(module);
        // (this as CardModule).highlighted = false;
        // (this as ScheduledActivityCardModule).scheduledActivity.finish();
        // this.dataContext.scheduledActivities.updateScheduledActivity((this as ScheduledActivityCardModule).scheduledActivity, this.dataContext);
    }

    onUpdateScheduledActivity = async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => {
        this.dataStore.data.modules.set(updatedScheduledActivity, true, true);
        this.dataStore.data.scheduledActivities.setStartTime(updatedScheduledActivity.scheduledActivity, updatedScheduledActivity.scheduledActivity.startTime, oldDate, true, true);
    }

    onDeleteScheduledActivity = async (scheduledActivity: ScheduledActivityCardModule, date: string) => {
        // const calendarData: CalendarData = await this.dataContext.calendarData.getCalendarDate(date, false, false, this.dataContext);
        // this.dataContext.calendarData.deleteScheduledActivity(calendarData.date, scheduledActivity.id, this.dataContext);
        // this.dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity.scheduledActivity);
        // scheduledActivity.scheduledActivity.deleteFirebase(thisContext.user);

        // if (scheduledActivity.parentModule instanceof CalendarEventsModule) {
        //     scheduledActivity.parentModule.childModules = scheduledActivity.parentModule.childModules.filter(m => m.id !== scheduledActivity.id);
        //     const dateIndex = scheduledActivity.parentModule.dates.findIndex(d => d.date === date)
        //     scheduledActivity.parentModule.dates[dateIndex].schedule = scheduledActivity.parentModule.dates[dateIndex]?.schedule.filter(s => s.id !== scheduledActivity.id);
        //     scheduledActivity.parentModule.forceRender();
        // }

        // calendarData.updateFirebase(thisContext.user);
    }

    getDateFromCalendarEventsModule = () => {
        const date = (this.parentModule as any)?.dates?.find((d: CalendarData) => {
            const foundDate = d.schedule.find(s => s.id === this.scheduledActivity.id)
            if (foundDate) {
                return d;
            }
        });
        return date?.date || "";
    }

    getScheduledActivityViewerDialog = () => {
        const oldDate = this.scheduledActivity.getStartTimeDate() || this.getDateFromCalendarEventsModule();
        return <ScheduledActivityViewer
            hideHeader
            scheduledActivity={this.scheduledActivity}
            onDeleteScheduledActivity={() => {
                const date = this.scheduledActivity.getStartTimeDate() || this.getDateFromCalendarEventsModule();
                removeModule(this);
                this.dataStore.data.modules.delete(this, true, true);
                this.dataStore.data.scheduledActivities.delete(this.scheduledActivity, true, true);
                this.dataStore.data.calendarDates.removeScheduleFromDate(this.scheduledActivity, date);
                // this.onDeleteScheduledActivity(this, oldDate);
                
            }}
            onUpdateScheduledActivity={(scheduledActivity) => {
                this.scheduledActivity = scheduledActivity;
                this.onUpdateScheduledActivity(this, oldDate);
                this.closeModal();
            }}
        />
    }

    scheduledActivityContainerContent = () => {
        return this.scheduledActivity && this.getScheduledActivityViewerDialog()
    }

    // containerContent = () => {
    //     return <Column alignLeft>
    //         <Row>
    //             {this.scheduledActivityContainerContent()}
    //             {/* {this.createCardDialog()} */}
    //             {this.renderMoveModule()}
    //             {this.renderMoveModuleUp()}
    //             {this.renderSettings()}
    //         </Row>
    //         {this.renderGrid()}
    //     </Column>
    // }

    deleteFromFirebase() {
        deleteData(getDataPath(this.user, data.SCHEDULED_ACTIVITY, this.id))
        deleteData(getModulesPath(this.user, this.id))
    }

    renderContent(): JSX.Element {
        return <div onClick={() => {
            this.onCardClick();
            // this.showCardContainer()
        }}>
            {this.name}{this.scheduledActivity?.startTime > 0 && " " + covertTimestampToStringTime(this.scheduledActivity.startTime)}
        </div>
    }

    toggleChecked = (checked: boolean) => {
        super.toggleChecked(checked);
        if(checked){
            this.scheduledActivity.finish();
            this.dataStore.data.scheduledActivities.set(this.scheduledActivity, true, true);
        }
            // this.checkboxChecked = checked;
            // this.parentModule.forceRender();
            // this.dataStore.data.modules.set(this, true, true);
        }

    renderCardStandard(): JSX.Element {
        return <>
            {!this.checkboxChecked && this.renderCheckbox()}
            {this.renderContent()}
            {this.checkboxChecked && <CheckIcon />}
        </>
    }

    updateFirebase() {
        super.updateFirebase();
        this.scheduledActivity && this.scheduledActivity.updateFirebase(this.user)
    }

    static createScheduledActivityCardModule = (
        dataStore: DataStore,
        baseModuleProps?: Partial<IBaseModule>,
        scheduledActivityProps?: Partial<ScheduledActivity>
    ): ScheduledActivityCardModule => {
        const id = generateId();
        const newScheduledActivityCardModule = new ScheduledActivityCardModule(
            BaseModule.getDefaultBaseModuleProps(
                baseModuleProps.name,
                modules.SCHEDULED_ACTIVITY_CARD, 
                dataStore.user,
                { id, ...baseModuleProps }
            ),
            {
                checkbox: true,
                checkboxChecked: false,
                moduleOptions: [],
            },
            dataStore
        )

        const scheduledActivity = new ScheduledActivity(
            { id, name: baseModuleProps.name, status: ActivityStatus.NOT_STARTED, ...scheduledActivityProps },
        );
        newScheduledActivityCardModule.scheduledActivity = scheduledActivity;
        return newScheduledActivityCardModule;
    }

    static getCreationDialog = (onCreate: (newModuleString: string, hasCheckbox: boolean) => void): JSX.Element => {
        return <div>
            <InputConfirm
                label='Namn på uppgift'
                onConfirm={text => onCreate(text, true)}
            />
        </div>
    }

}