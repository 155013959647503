import * as React from 'react'
import { BaseModule, IBaseModule } from '../BaseModule';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { breakpointTypes, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import "./Card.scss";
import classNames from 'classnames';
import { GridItem, IGrid } from '../ReactGridModule/GridItem';
import { ReactGrid } from '../ReactGridModule/ReactGrid';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Column } from '../../../components/layout/column/Column';
import { Row } from '../../../components/layout/row/Row';
import { Button } from '../../../components/button/Button';
import { MarginRight } from '../../../components/layout/margin/MarginRight';
import ModulePicker from '../../../components/pickers/module_picker/ModulePicker';
import { TData } from '../../../app/DataContextInterface';
import { TAppData } from '../../../app/AppContext';
import { DataStore } from '../../../app/DataStore/DataStore';
import { BoxedModule } from '../../BoxedModule';
import { removeModule } from '../../../app/utils';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { ModuleCreator } from '../../module_creator/ModuleCreator';

export interface ICardModuleProps {
    checkbox?: boolean;
    checkboxChecked?: boolean;
    moduleOptions?: any[];
    // modulePicker: JSX.Element;
    highlighted?: boolean;
    // app: CardAppInterface;
}

export class CardModule extends BaseModule implements ICardModuleProps {
    checkbox?: boolean;
    checkboxChecked?: boolean;
    moduleOptions?: any[];
    // modulePicker: JSX.Element;
    destinationModule: BaseModule;
    highlighted?: boolean;
    test: number[] = [];

    constructor(
        baseProps: IBaseModule,
        cardModuleProps: ICardModuleProps,
        dataStore: DataStore
    ) {
        super(baseProps, dataStore);
        this.toggleChecked = this.toggleChecked.bind(this);
        this.noBox = true;
        //@ts-ignore
        this.checkbox = baseProps.checkbox
        //@ts-ignore
        this.checkboxChecked = baseProps.checkboxChecked
        //@ts-ignore
        this.highlighted = baseProps.highlighted;
        Object.assign(this, cardModuleProps);
    }

    async init() {
        // console.log(`data.modules.data.${this.id}`);
        // this.dataStore.subscribe(`modules.data`, (data) => {
            // console.log(this.name, data);
            // console.log(this.id, data);
            // this.rerender();
        // });
    }

    toggleCardContainer = () => {
        return <>
            <Column alignLeft>
                <div>parent: {this.parentModule?.name}</div>
                <div>user: {this.user}</div>
                <div>id: {this.id}</div>
                <div>type: {this.type}</div>
            </Column>
            <BoxedModule
                testId={`card-container`}
                module={this}
                moduleName={this.name}
                onDelete={() => {
                    removeModule(this);
                }}
                dragHandle={''}
                onHeaderNameUpdate={(name: string) => {

                }}
                content={<div>
                    {(this as unknown as CardModule).cardContainerContent()}
                </div>}
            />
        </>
    }

    public onCheckboxChange(module: BaseModule) {
        // this.dataContext.modules.updateModules([module], true)
    }

    onCardClick = async () => {
        console.log("clicked", this.name);
        this.loadAllChildren();
        this.toggleModal(this.toggleCardContainer);
    }

    convertToBoardModule = () => {
        // const baseProps: IBaseModule = {
        //     id: this.id,
        //     user: this.user,
        //     type: modules.BOARDS,
        //     name: this.name,
        //     layout: this.layout,
        //     parentModule: this.parentModule,
        //     childModules: this.childModules,
        // }
        // const reactGridProps: IReactGridModuleProps = { currentBreakpoint: this.getCurrentBreakpoint() }
        // const boardProps: IBoardModuleProps = {}
        // const boardModule = new BoardModule(baseProps, reactGridProps, boardProps);
        // const previousParentModule = this.parentModule;
        // this.parentModule.removeChildModule(this, this.parentModule);
        // this.dataContext.modules.deleteModule(this, true);
        // this.dataContext.modules.addModule(boardModule);
        // previousParentModule.addChildModule(boardModule, previousParentModule);
        // boardModule.updateFirebase();
        // boardModule.forceRender && boardModule.forceRender();
        // previousParentModule.forceRender && previousParentModule.forceRender();
    }

    containerOnDragStop = () => {
        this.updateFirebase();
    }

    renderModulePicker = (moduleId: string, dataContext: TData, appContext: TAppData) => {
        return <ModulePicker
            // modules={[]}
            onPick={async (selectedModule: BaseModule) => {
                // const moduleToMove = await dataContext.modules.getModule(moduleId, dataContext, appContext, false) as BaseModule;
                // moduleToMove.moveToAnotherModule(selectedModule);
                // moduleToMove.parentModule.forceRender();
                // selectedModule.forceRender();
            }}
            dataStore={this.dataStore}
        />
    }

    // moduleCreator = getModuleCreator => (
    //     thisContext.user,
    //     async (newModuleName: string, newModuleType: string) => {
    //         const newModule = await getModuleType(
    //             BaseModule.getDefaultBaseModuleProps(newModuleName, newModuleType, { user: thisContext.user }),
    //             this.dataContext,
    //             thisContext
    //         );
    //         this.addChildModule(newModule, this);
    //     })

    onDelete: (self: BaseModule) => {}

    // onRemoveChildModule = (removedModule?: BaseModule, yourself?: BaseModule) => {
    //     removedModule.parentModule.layout.updateFirebaseData(thisContext.user, removedModule.parentModule.id, thisContext.currentBreakpoint);
    //     removedModule.deleteFromFirebase();
    // }

    // onCreateChildModule = (createdModule: BaseModule, yourSelf: BaseModule) => {
    //     createdModule.updateFirebase();
    //     yourSelf.updateFirebase();
    //     yourSelf.closeModal();
    //     (yourSelf as CardModule).renderContent()
    // }
    // modulePicker: this.renderModulePicker(baseProps.id, dataContext, appContext)

    containerOnResizeStop = () => {
        this.updateFirebase();
    }

    getCreationDialog(onCreate: (module: BaseModule) => void): JSX.Element {
        return null;
        // return null;
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }, this.id)
        }
    }

    renderTopMenu(): JSX.Element {
        return null
    }

    renderSettings = (): JSX.Element => {
        return <Checkbox label='Använd checkbox' checked={this.checkbox} onChange={checked => {
            this.toggleUseCheckbox(checked);
        }} />
    }

    // addModule = async (params) => {
    //     const childModule = await this.addChildModule(params, this);
    //     this.closeModal();
    //     childModule.closeModal();
    // }



    renderGrid = () => {
        return this.layout && <ReactGrid
            id={this.id}
            layout={this.layout}
            modules={this.childModules}
            onDragStart={function (newGrid: any): void {

            }}
            onDragStop={(newGrid: IGrid[], modules: any[]) => {
                this.layout.updateGridItems(newGrid, breakpointTypes.LG);
                this.layout.updateGridItems(newGrid, breakpointTypes.SM);
                this.containerOnDragStop()
            }}
            onDragOver={function (e: React.DragEvent<HTMLDivElement>): void {

            }}
            onResizeStop={(newGrid: IGrid[]) => {
                this.layout.updateGridItems(newGrid, breakpointTypes.LG);
                this.layout.updateGridItems(newGrid, breakpointTypes.SM);
                this.containerOnResizeStop();
            }}
            currentBreakpoint={this.getCurrentBreakpoint() || breakpointTypes.LG}
        />
    }

    renderMoveModule = () => {
        return <Button label={'Flytta'} onClick={() => {
            this.toggleModal(() => <ModulePicker
                onPick={(module: BaseModule) => {
                    this.moveToAnotherModule(module);
                    this.closeModal();
                }}
                dataStore={this.dataStore}
            />)
        }} />
    }

    renderMoveModuleUp = () => {
        return <Button label={'Flytta upp'} onClick={() => {
            this.moveModuleUp();
        }} />
    }

    toggleBulkMoveDialog = () => {
        this.toggleModal(() =>
            <Column alignLeft>
                <Row>
                    {this.destinationModule && <Button label={`Flytta ${this.childModules.filter(c => (c as CardModule).checkboxChecked).length} markerade till `}
                        onClick={() => {
                            this.childModules.filter(m => m instanceof CardModule && m.checkboxChecked).forEach(m => {
                                this.removeChildModule(m, this);
                                console.log(`Flyttar ${m.name} till &/%/&(%)`);
                            })
                            this.closeModal();
                        }} />}
                    <MarginRight />
                    <Button label={this.destinationModule ? this.destinationModule.name : 'Välj destination'}
                        onClick={() => {
                            this.toggleModal(() => <ModulePicker
                                onPick={(module: BaseModule) => {
                                    this.childModules.filter(m => m instanceof CardModule && m.checkboxChecked).forEach(m => {
                                        if (m instanceof CardModule) {
                                            m.toggleChecked(false);
                                        }
                                        m.moveToAnotherModule(module);
                                    });
                                }}
                                dataStore={this.dataStore}
                            />);
                        }} />
                </Row>
                {/* @ts-ignore */}
                <Column alignLeft>{this.childModules.filter((c) => c.checkboxChecked).map((child, index) => {
                    return <div>{`${index + 1}. ${child.name}`}</div>;
                })}</Column>
            </Column>
        )
    }

    loadAllChildren = () => {
        const promises = this.dataStore.data.modules.getModulePromises(this.layout.getKeys(breakpointTypes.LG), this.dataStore, this);
        this.childModules = []
        promises.forEach(p => p.then(m => {
            if (m) {
                if(!this.childModules.some(c => c.id === m.id)) {
                    this.childModules.push(m);
                    this.refreshModal();
                }
                // this.toggleModal(this.toggleCardContainer);
            }
        }))

    }

    cardContainerContent = () => {
        return <Column alignLeft className="scheduled-activity-content">
            <Row>
                <ModuleCreator
                    horisontal
                    user={this.dataStore.user}
                    onCreate={async (newModuleName: string, moduleType: string, params?: any) => {
                        // const startModule: ReactGridModule = await dataStore.data.modules.getModule("LayoutStartPoint", dataStore, null) as ReactGridModule;
                        // console.log(newModuleName, newModuleType, params);
                        const newModule = await this.dataStore.appUtils.getModuleType(
                            BaseModule.getDefaultBaseModuleProps(newModuleName, moduleType, this.dataStore.user),
                            this,
                            params
                        )
                        this.addChildModule(newModule, this);
                        this.toggleModal(this.toggleCardContainer);
                    }} />
                {this.renderMoveModule()}
                {this.renderMoveModuleUp()}
                <MarginRight />
                {this.childModules?.some(m => m instanceof CardModule && m.checkboxChecked) && <>
                    {/*@ts-ignore */}
                    <Button label={`Flytta ${this.childModules.filter(c => c.checkboxChecked).length} markerade`}
                        onClick={() => {
                            this.toggleModal(() =>
                                <Column alignLeft>
                                    {this.destinationModule && <Button label={`Flytta`}
                                        onClick={() => {
                                            return this.toggleModal(() => <ModulePicker
                                                onPick={(module: BaseModule) => {
                                                    this.closeModal();
                                                }}
                                                dataStore={this.dataStore}
                                            />);
                                        }} />}

                                    {/* @ts-ignore */}
                                    <Column alignLeft>{this.childModules.filter((c) => c.checkboxChecked).map((child, index) => {
                                        return <div>{`${index + 1}. ${child.name}`}</div>;
                                    })}</Column>
                                </Column>
                            )
                            this.toggleBulkMoveDialog();
                            // console.log(this.childModules.filter(m => m instanceof CardModule && m.checkboxChecked));
                        }} />
                    <MarginRight />

                    {/* <Button label={this.destinationModule ? this.destinationModule.name : 'Välj destination'}
                        onClick={() => {
                            this.childModules.filter(m => m instanceof CardModule && m.checkboxChecked).forEach(m => {
                                this.toggleModal(thisPicker);
                            })
                        }} /> */}
                </>
                }
                <Button label="refresh" onClick={() => {
                    console.log(this.childModules);
                    this.rerender();
                }}/>
                <Button
                    label={`Konvertera till lista`}
                    onClick={() => {
                        this.convertToBoardModule();
                    }}
                />
                <MarginRight />
                <Button
                    label={`Skapa uppgift`}
                    onClick={() => {
                        const scheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createScheduledActivityCardModule(
                            this.dataStore,
                            { name: this.name }
                        );
                        this && this.sendToTodoList(scheduledActivityCardModule);
                    }}
                />
                <MarginRight />
                <Checkbox
                    label="Highlighta"
                    checked={this.highlighted}
                    onChange={checked => {
                        this.highlighted = checked;
                        this.dataStore.data.modules.set(this, true, true);
                        // this.renderContent();
                    }}
                />
                <MarginRight />
                {this.renderSettings()}
            </Row>
            {this.renderGrid()}
        </Column>
    }

    toggleChecked(checked: boolean) {
        this.checkboxChecked = checked;
        this.parentModule.forceRender();
        this.dataStore.data.modules.set(this, true, true);
    }

    toggleUseCheckbox = (checked: boolean) => {
        this.checkbox = checked;
        this.onCheckboxChange && this.onCheckboxChange(this);
    }

    renderCheckbox(): JSX.Element {
        return this.checkbox && <Checkbox
            checked={this.checkboxChecked}
            onChange={this.toggleChecked} />
    }

    renderContent(): JSX.Element {
        return <div onClick={() => {
            this.onCardClick();
            // this.showCardContainer()
        }}>
            {this.name}
        </div>
    }

    renderCardStandard(): JSX.Element {
        return <>
            {this.renderCheckbox()}
            {this.renderContent()}
        </>
    }

    renderFront(): JSX.Element {
        return <div
            className={classNames(
                `card`,
                this.parentModule?.id,
                this.parentModule?.id === "LayoutStartPoint" && 'card-no-parent',
                this.highlighted && 'highlighted'
            )}
        >

            <div
                data-testid="card-click-area"
                className="prevent-drag card-click-area"
            >
                {this.renderCardStandard()}
                {this.layout.getKeys(breakpointTypes.LG).length > 0 ? ` (${this.layout.getKeys(breakpointTypes.LG).length})` : null}
            </div>
        </div>
    }

    render(): JSX.Element {
        return this.renderFront()
    }

    toFirebaseObject() {
        return {
            ...super.toFirebaseObject(),
            checkbox: this.checkbox,
            checkboxChecked: this.checkboxChecked,
            highlighted: this.highlighted
        }
    }

    static createModule = (dataStore: DataStore, baseModuleProps?: Partial<IBaseModule>, cardModuleProps?: Partial<ICardModuleProps>): CardModule => {
        const newCardModule = new CardModule(
            BaseModule.getDefaultBaseModuleProps(
                baseModuleProps?.name || "",
                modules.CARD,
                dataStore.user,
                baseModuleProps
            ),
            {
                ...cardModuleProps
            },
            dataStore
            // cardAppInterface
        )
        // if (newCardModule.parentModule) {
        //     newCardModule.onCreateChildModule = newCardModule.parentModule.onCreateChildModule;
        //     newCardModule.onRemoveChildModule = newCardModule.parentModule.onRemoveChildModule;
        // }

        return newCardModule;
    }

    static getCreationDialog(
        onCreate: (newModuleName: string, hasCheckbox) => void
    ): JSX.Element {
        let hasCheckbox = false;
        return <Column alignLeft>
            <InputConfirm
                label='Namn på kort'
                onConfirm={text => onCreate(text, hasCheckbox)}
            />
            <Checkbox
                label='Använd checkbox'
                checked={hasCheckbox}
                onChange={(checked) => {
                    hasCheckbox = checked;
                }}
            />
        </Column>
    }

}