import { Calendar } from '../../../components/calendar/Calendar';
import { BaseModule, IBaseModule } from '../BaseModule';
import { breakpointTypes, GridHeightsSM, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import { GridItem } from '../ReactGridModule/GridItem';
import { DataStore } from '../../../app/DataStore/DataStore';

export class CalendarModule extends BaseModule {
 
    constructor(props: IBaseModule, dataStore: DataStore) {
        super(props, dataStore);
        this.autoPackingDimensions = { w: 24, h: 30 };
    }

    async init() {

    }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }

    renderTopMenu(): JSX.Element {
        return null;
    }
    customDelete(): void {
        return null;
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }, this.id)
        }
    }

    static createModule(dataStore: DataStore, baseModuleProps?: Partial<IBaseModule>): CalendarModule {
        return new CalendarModule(
            // { id: "calendarModule", type: modules.CALENDAR, user, name: "CalendarModule", onDelete: (self) => { console.log(self) } }
            BaseModule.getDefaultBaseModuleProps(
                "CalendarModule", 
                modules.CALENDAR, 
                dataStore.user,
                baseModuleProps
            ),
            dataStore
        )
    }

    render(): JSX.Element {
        return <Calendar calendarModule={this} />
    }

}