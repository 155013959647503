import { clearObjectOfUndefines, objectHasProperties, shuffleArray } from './../utils/Utils';
import { breakpointTypes, getModulesPath, GridHeightsLG, GridWidthsLG, IGridLayout, ILayouts } from "../utils/constants";
import { TData } from '../app/DataContextInterface';
import { GridItem, IGrid } from './modules/ReactGridModule/GridItem';
import { writeData } from '../utils/Firebase';

export interface ILayout {
    id: string;
    [breakpointTypes.SM]?: { [key: string]: GridItem },
    [breakpointTypes.LG]?: { [key: string]: GridItem },
}

export class Layout implements ILayout {
    id: string;
    //@ts-ignore
    [breakpointTypes.SM]: { [key: string]: GridItem };
    //@ts-ignore
    [breakpointTypes.LG]: { [key: string]: GridItem };


    constructor(layout: ILayout) {
        Object.assign(this, layout);
        if (!this.lg) {
            this.lg = {};
        }
        if (!this.sm) {
            this.sm = {};
        }
        this.lg = Object.values(this[breakpointTypes.LG]).reduce((previous: {}, current: IGrid, i, arr) => {
            previous[current.i] = new GridItem(current, this.id);
            return previous;
        }, {})
        this.sm = Object.values(this[breakpointTypes.SM]).reduce((previous: {}, current: IGrid, i, arr) => {
            previous[current.i] = new GridItem(current, this.id);
            return previous;
        }, {})
    }

    getILayout = (): ILayout => {
        const iLayout: ILayout = { id: this.id, [breakpointTypes.SM]: { ...this[breakpointTypes.LG] }, [breakpointTypes.LG]: { ...this[breakpointTypes.LG] } }
        return iLayout;
    }

    // getFirebaseLayout = () => {
    //     console.log(this.lg);
    //     return { [breakpointTypes.SM]: { ...this[breakpointTypes.LG] }, [breakpointTypes.LG]: { ...this[breakpointTypes.LG] } }}

    getGridIds = (): string[] => {
        return Object.keys(this[breakpointTypes.LG]);
    }

    gridIsEqual(incomingGrid: GridItem, breakpoint: breakpointTypes) {
        const grid: GridItem = this[breakpoint][incomingGrid.i];
        return grid.w === incomingGrid.w && grid.h === incomingGrid.h && grid.x === incomingGrid.x && grid.y === incomingGrid.y && grid.isDraggable === incomingGrid.isDraggable;
    }

    layoutIsEqual(incomingLayout: GridItem[], breakpoint: breakpointTypes) {
        return incomingLayout.every((incomingGrid: GridItem) => {
            const isEqual = this.gridIsEqual(incomingGrid, breakpoint)
            return isEqual;
        })
    }

    setAllDraggable() {
        Object.values(this[breakpointTypes.LG]).forEach((grid: GridItem) => {
            this[breakpointTypes.LG][grid.i].isDraggable = true;
        })
    }

    setAllNonDraggable() {
        Object.values(this[breakpointTypes.LG]).forEach((grid: GridItem) => {
            this[breakpointTypes.LG][grid.i].isDraggable = false;
        })
    }

    updateGridItems(gridItems: GridItem[], breakpoint: breakpointTypes) {
        gridItems.forEach((gridItem: GridItem) => {
            clearObjectOfUndefines(gridItem);
            this[breakpoint][gridItem.i] = gridItem;
        })
        // if (dataContext && updateLayout) {
        //     // dataContext.layout.setLayout(this);
        // }
        // if (updateFirebase) {
        //     this.updateFirebaseModule(user);
        // }
    }


    setLockedItems = (locked, currentBreakpoint: breakpointTypes) => {
        const currentLayout = this[currentBreakpoint];
        Object.values(currentLayout).forEach((grid: GridItem) => {
            grid.isDraggable = !locked;
        })
    }

    layoutArrayToLayoutObject(layout: GridItem[]): { [key: string]: GridItem } {
        const layoutObject = {}
        for (const l of layout) {
            layoutObject[l.i] = l;
        }
        return layoutObject;
    }

    objectToArray(layout: { [key: string]: GridItem }): GridItem[] {
        return Object.values(layout);
    }

    updateLayout(dataContext: TData, user: string, layout: GridItem[], breakpoint: breakpointTypes, updateFirebase: boolean) {
        const otherLayout: GridItem[] = this.objectToArray(this.getOtherLayout(breakpoint));
        console.log(otherLayout.length, layout.length);
        // if (layout.length !== otherLayout.length) {
        //     alert("items have been removed from array, wont update!");
        // }
        // else {
        this[breakpoint] = this.layoutArrayToLayoutObject(layout);
        // dataContext.layout.setLayout(this);
        // }
    }

    updateLayouts(dataContext: TData, user: string, layouts: IGridLayout) {
        this[breakpointTypes.SM] = layouts[breakpointTypes.SM].reduce((previous, current, i, arr) => {
            previous[current.i] = current;
            return previous;
        }, {});
        this[breakpointTypes.LG] = layouts[breakpointTypes.LG].reduce((previous, current, i, arr) => {
            previous[current.i] = current;
            return previous;
        }, {});
        // dataContext.layout.setLayout(this);
    }

    getGrid(gridid: string) {
        return {
            id: gridid,
            [breakpointTypes.SM]: this[breakpointTypes.SM][gridid],
            [breakpointTypes.LG]: this[breakpointTypes.LG][gridid]
        }
    }

    getGrids(gridIds: string[], breakpoint): GridItem[] {
        const grids: GridItem[] = gridIds.map((gridId: string) => {
            return this[breakpoint][gridId];
        })
        return grids?.filter(g => g) || [];
    }

    updateGridItem(grid: IGrid) {
        this[breakpointTypes.SM][grid.i] = new GridItem(grid, this.id);
        this[breakpointTypes.LG][grid.i] = new GridItem(grid, this.id);
    }

    removeGrid(gridId: string): ILayouts {
        const gridLayout: ILayouts = { id: gridId, [breakpointTypes.SM]: new GridItem({ ...this[breakpointTypes.SM][gridId] }, this.id), [breakpointTypes.LG]: new GridItem({ ...this[breakpointTypes.LG][gridId] }, this.id) }
        delete this[breakpointTypes.SM][gridId];
        delete this[breakpointTypes.LG][gridId];
        return gridLayout;
    }

    getGridsClone(breakpoint): GridItem[] {
        const grids: GridItem[] = Object.values(this[breakpoint])
        return grids.map((grid: GridItem) => {
            const gridClone: GridItem = { ...grid } as GridItem
            return gridClone
        });
    }

    addGrid(grid: ILayouts) {
        if (!objectHasProperties(grid)) {
            console.log(grid);
        }
        this[breakpointTypes.SM][grid.id] = new GridItem({ ...grid[breakpointTypes.SM] }, this.id);
        this[breakpointTypes.LG][grid.id] = new GridItem({ ...grid[breakpointTypes.LG] }, this.id);
        // dataContext.layout.setLayout(this);
    }

    getOtherLayout(breakpoint: breakpointTypes) {
        return breakpoint === breakpointTypes.SM ? this.getLayout(breakpointTypes.LG) : this.getLayout(breakpointTypes.SM)
    }

    getLayout(breakpoint: breakpointTypes) {
        return this[breakpoint]
    }

    getAllGrids(breakpoint: breakpointTypes): GridItem[] {
        const layout = this.getLayout(breakpoint);
        return Object.values(layout);
    }

    getKeys(breakpoint: breakpointTypes): string[] {
        if (this[breakpoint]) {
            return Object.keys(this[breakpoint])
        }
        return [];
    }

    // async verticalSort(user, sortedIds: string[], breakpoint: breakpointTypes, dataContext, updateLayout, updateFirebase) {
    //     let lastAccumulatedY = 0;
    //     sortedIds.forEach((id: string) => {
    //         this[breakpoint][id].y = lastAccumulatedY;
    //         lastAccumulatedY += this[breakpoint][id].h;
    //     })
    //     if (updateLayout) {
    //         this.updateGridItems(Object.values(this[breakpoint]), breakpoint, dataContext, updateLayout, updateFirebase);
    //     }
    // }

    autoLayout(dataContext: TData, user: string, shuffle: boolean = false) {
        let x = 0;
        let y = 0;
        const lgArr = Object.values(this[breakpointTypes.LG]);
        const arr = shuffle ? shuffleArray(lgArr) : lgArr;
        arr.forEach((grid, i) => {
            let newX, newY;

            newX = x === 0 ? 0 : x * GridWidthsLG.CARD;
            newY = y < 1 ? 1 : y * GridHeightsLG.CARD;
            // if (i > 3) {
            // }
            // else {
            //     newY = 1;
            // }

            console.log(newX, newY);
            grid.x = newX;
            grid.y = newY;
            ++x;
            if (x > 3) {
                x = 0;
                ++y;
            }
        })
        this.updateLayouts(dataContext, user, this.getArrays());
    }

    printGridItems(currentBreakpoint: breakpointTypes) {
        console.log(Object.values(this[currentBreakpoint]).map(l => ({ id: l.i, y: l.y })).sort((a, b) => a.y - b.y)[0].id)
        // const lay = Object.values(this[currentBreakpoint]).map(l => ({ id: l.i, y: l.y })).sort((a, b) => a.y - b.y)
        // lay.forEach((l, i) => {
        //     console.log(l.id, l.y)
        // })
    }

    get(): ILayout {
        return { id: this.id, [breakpointTypes.SM]: this[breakpointTypes.SM], [breakpointTypes.LG]: this[breakpointTypes.LG] }
    }

    getArrays(): IGridLayout {
        return { [breakpointTypes.SM]: Object.values(this[breakpointTypes.SM]), [breakpointTypes.LG]: Object.values(this[breakpointTypes.LG]) }
    }

    updateFirebaseData(user: string, moduleId: string, breakpoint: breakpointTypes) {
        writeData(
            `${getModulesPath(user, moduleId)}/layout/${breakpoint}`,
             this[breakpoint]
        );
    }

    toString() {
        return Object.values(this.lg).map((gridItem) => {
            return `${gridItem.i} ${gridItem.x} ${gridItem.y} ${gridItem.w} ${gridItem.h}`
        })
    }

    static createILayouts = (id: string, layout: IGrid, smGrid?: IGrid, lgGrid?: IGrid): ILayouts => {
        return {
            id,
            [breakpointTypes.SM]: smGrid || layout,
            [breakpointTypes.LG]: lgGrid || layout
        }
    }

}