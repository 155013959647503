import { ScheduledActivity } from "../../components/activity_generator/ScheduledActivity";
import { GET_USER } from "../../utils/constants";
import { readData } from "../../utils/Firebase";
import { getStringDatesBetween } from "../../utils/TimeUtils";
import { asyncMap } from "../../utils/Utils";
import { BaseData } from "./BaseData";
import { DataStore } from "./DataStore";
import {CalendarData, getDefaultCalendarData, ICalendarData} from "../../components/calendar/CalendarData";

export class CalendarDates extends BaseData<CalendarData>{

    constructor(dataStore: DataStore) {
        super(dataStore, "calendarDates.data", `${GET_USER(dataStore.user)}/data/calendar_dates`);
    }

    async init(){
        
    }

    async getDates (startDate: string, endDate: string, fillEmptyDates: boolean, updateCalendar: boolean = false): Promise<CalendarData[]> {
        const dates: CalendarData[] = []
            const stringDates = getStringDatesBetween(startDate, endDate)
    
            for (const date of stringDates) {
                const d = await this.getDate(date, fillEmptyDates);
                if (d) {
                    dates.push(d);
                }
            }
            if (updateCalendar) {
                dates.forEach(d => this.set(d, false));
                this.dataStore.broadcast(this.appPath, this.getAsArray())
            }
            return dates;
    };

    getDate = async (date: string, createIfDoesntExist: boolean): Promise<CalendarData> => {
        let foundCalendarData: CalendarData | ICalendarData = this.getAsArray().find(c => c.date === date);
        if (!foundCalendarData) {
            foundCalendarData = await readData(`${this.firebasePath}/${date}`) as ICalendarData;
            if (!foundCalendarData) {
                foundCalendarData = { id: date, date, schedule: [] }
            }
            if (!foundCalendarData?.schedule) {
                foundCalendarData.schedule = [];
            }
            foundCalendarData.schedule = foundCalendarData?.schedule?.length > 0 ? await asyncMap(
                foundCalendarData.schedule,
                async (s: string) => {
                    return await this.dataStore.data.scheduledActivities.getScheduledActivity(s)
                }
            ) :
                []
            if (foundCalendarData) {
                foundCalendarData = new CalendarData(foundCalendarData);
            }
        }
        if (!foundCalendarData && createIfDoesntExist) {
            foundCalendarData = new CalendarData(getDefaultCalendarData(date))
        }

        return foundCalendarData as CalendarData;
    }

    async addScheduleToDate (scheduledActivity: ScheduledActivity, date: string) {
        const calendarDate = await this.getDate(date, true);
        calendarDate.addScheduledActivity(scheduledActivity);
        this.dataStore.data.calendarDates.set(calendarDate, true, true);
        // this.set(calendarDate);
    }

    async removeScheduleFromDate (scheduledActivity: ScheduledActivity, date: string) {
        const calendarDate = await this.getDate(date, false);
        calendarDate.removeScheduledActivity(scheduledActivity);
        this.dataStore.data.calendarDates.set(calendarDate, true, true);
    }

    // flattenYearData = (firebaseCalendarData): CalendarData[] => {
    //     let data: CalendarData[] = [];
    //     Object.values(firebaseCalendarData).forEach((year) => {
    //         const flattenedData = flattenYearData(year);
    //         data = [...data, ...flattenedData]
    //     })
    //     return data;
    // }

    // // const scheduleActivity = (name: string, duetime: number, scheduledActivityId?: Activity): ScheduledActivity => {
    // //     const stringDate = convertTimestampToStringDate(duetime);
    // //     // const scheduledActivity = new ScheduledActivity({ id: getRandomId(), date: stringDate, content: name, status: ActivityStatus.NOT_STARTED, dueTime: duetime });
    // //     // scheduledActivity.updateFirebase(user);
    // //     // clearObjectOfUndefines(scheduledActivity);
    // //     // if (activity) {
    // //     //     scheduledActivity.activityId = activity.id;
    // //     // }
    // //     addToSchedule(stringDate, scheduledActivity, true);
    // //     return scheduledActivity;
    // // }

    // convertCardsToSchedule = (cards: CardModule[]): ScheduledActivity[] => {
    //     const schedule = [];
    //     if (!cards?.length) {
    //         return schedule;
    //     }
    //     cards.forEach((card, index) => {
    //         // const data: ScheduledActivity = new ScheduledActivity({ id: card.id, ...card?.extra?.card })
    //         // schedule.push(data);
    //     })
    //     return schedule;
    // }

    // convertBoardToCalendarData = (board) => {
    //     // const schedule = convertCardsToSchedule(board.cards)
    //     // const calendarData: CalendarData = { id: board.id, date: "fel datum", data: board?.extra?.data || {}, schedule }
    //     return null;
    //     // return calendarData
    // }

    // convertBoardsToDailySchedules = (boards: []): CalendarData[] => {
    //     const dailySchedules: CalendarData[] = [];
    //     boards.forEach((board, boardIndex) => {
    //         const calendarData = this.convertBoardToCalendarData(board);
    //         dailySchedules.push(calendarData);
    //     })
    //     return dailySchedules;
    // }

    // setCalendarDataFromBoards = (boards: []): CalendarData[] => {
    //     const calendarData: CalendarData[] = this.convertBoardsToDailySchedules(boards)
    //     this.calendarData = calendarData;
    //     return calendarData;
    // }

    // // const registerMerchandisePurchase = async (time: number, merchandiseId: string) => {
    // //     const stringDate = convertTimestampToStringDate(time);
    // //     const calendarDate: CalendarData = await getCalendarDate(stringDate);
    // //     if (!calendarDate.data) {
    // //         calendarDate.data = { icons: [], events: [], notes: [], logs: [], metrics: {} }
    // //     }
    // //     if (!calendarDate.data.purchases) {
    // //         calendarDate.data.purchases = [];
    // //     }
    // //     calendarDate.data.purchases.push({ merchandiseId, time });
    // //     synkFirebaseCalendarDataCalendarData(calendarData);
    // // }

    // synkFirebaseCalendarDataCalendarData = async (newCalendarData: CalendarData[], user: string) => {
    //     for (const calendarData of newCalendarData) {
    //         if (calendarData?.date) {
    //             // synkFirebaseCalendarDateData(weekday)
    //             calendarData.updateFirebase(user);
    //         }
    //     }
    // }

    // getDatesWithSchedule = () => {
    //     if (this.calendarData) {
    //         return this.calendarData.filter((c: CalendarData) => c.schedule?.length)
    //     }
    //     return []
    // }

    // setScheduledActivity = (date: string, scheduledActivity: ScheduledActivity): CalendarData[] => {
    //     const calendarDate: CalendarData = this.calendarData.find((calendarData: CalendarData) => calendarData.date === date)
    //     if (calendarDate?.schedule?.length && scheduledActivity) {
    //         // const i = calendarDate.schedule.findIndex((s: ScheduledActivity) => s.id === scheduledActivity.id)
    //         calendarDate.schedule[scheduledActivity.id] = scheduledActivity;
    //     }
    //     return this.calendarData;
    // }

    // setCalendarDate = (calendarDate: CalendarData) => {
    //     const currentIndex = this.calendarData.findIndex((c: CalendarData) => c.date === calendarDate.date);
    //     if (currentIndex > -1) {
    //         this.calendarData[currentIndex] = calendarDate;
    //     }
    //     else {
    //         this.calendarData.push(calendarDate);
    //     }
    // }

    // getStarttimesForActivity = async (activityId: string): Promise<number[]> => {
    //     const startTimes = [];
    //     const datesWithSchedules: CalendarData[] = this.getDatesWithSchedule();
    //     // datesWithSchedules.forEach((d: CalendarData) => {
    //     for (const d of datesWithSchedules) {
    //         // const schedule = await d.getSchedule(dataContext)
    //         d.schedule.forEach((s: ScheduledActivity) => {
    //             if (s?.extra?.activityId === activityId) {
    //                 if (s.lastStartTime) {
    //                     startTimes.push(s.lastStartTime);
    //                 }
    //             }
    //         })
    //     }
    //     return startTimes
    // }

    // getAverageTimeBetweenActivityStarts = async (activityId) => {
    //     const startTimes: number[] = await this.getStarttimesForActivity(activityId);
    //     const millisBetweenStarttimes = startTimes.sort((a, b) => a > b ? 1 : -1).map((startTime: number, i: number) => {
    //         if (i < startTimes.length - 1) {
    //             return startTimes[i + 1] - startTimes[i];
    //         }
    //     }).filter(v => v)
    //     if (!millisBetweenStarttimes?.length) {
    //         return -1;
    //     }
    //     const millisFromLastStarttimeToNow = new Date().getTime() - startTimes[startTimes.length - 1];
    //     millisBetweenStarttimes.push(millisFromLastStarttimeToNow);
    //     const average = millisBetweenStarttimes.reduce((a, b) => (a + b), 0) / millisBetweenStarttimes.length

    //     if (millisFromLastStarttimeToNow > average) {
    //         millisBetweenStarttimes.push(millisFromLastStarttimeToNow);
    //         const recalulatedAverage = millisBetweenStarttimes.reduce((a, b) => (a + b), 0) / millisBetweenStarttimes.length
    //         const t = timeUnitConverterMinutsHoursDays(recalulatedAverage)
    //         return t.value + t.unit;
    //     }
    //     else {
    //         const t = timeUnitConverterMinutsHoursDays(average)
    //         return t.value + t.unit;
    //     }
    // }

    // addDateToCalendar = async (calendarDate: CalendarData, updateFirebase: boolean) => {
    //     this.calendarData.push(calendarDate);
    //     if (updateFirebase) {
    //         calendarDate.updateFirebase(this.user);
    //     }
    // }

    // getCalendarDate = async (date: string, createIfDoesntExist: boolean, updateCalendarData): Promise<CalendarData> => {
    //     let foundCalendarData: CalendarData | ICalendarData = this.calendarData.find(c => c.date === date);
    //     if (!foundCalendarData) {
    //         foundCalendarData = await readData(firebaseModulePaths.GET_CALENDAR_DATE_MODULE_PATH(this.user, date)) as ICalendarData;
    //         if (!foundCalendarData) {
    //             foundCalendarData = { id: generateId(), date, schedule: [] }
    //         }
    //         if (!foundCalendarData?.schedule) {
    //             foundCalendarData.schedule = [];
    //         }
    //         foundCalendarData.schedule = foundCalendarData?.schedule?.length > 0 ? await asyncMap(
    //             foundCalendarData.schedule,
    //             async (s: string) => {
    //                 // await this.scheduledActivities.getScheduledActivity(s)
    //             }
    //         ) :
    //             []
    //         if (foundCalendarData) {
    //             foundCalendarData = new CalendarData(foundCalendarData);
    //         }
    //     }
    //     if (!foundCalendarData && createIfDoesntExist) {
    //         foundCalendarData = new CalendarData(getDefaultCalendarData(date))
    //     }
    //     updateCalendarData && this.setCalendarDate(foundCalendarData as CalendarData);
    //     return foundCalendarData as CalendarData;
    // }

    // deleteScheduledActivity = async (date: string, scheduledActivityId: string, ) => {
    //     if (date) {
    //         const calendarDate = await this.getCalendarDate(date, true, true);
    //         if (calendarDate) {
    //             calendarDate.schedule = calendarDate.schedule.filter(s => s.id !== scheduledActivityId)
    //             // updateFirebase && this.synkFirebaseCalendarDataCalendarData(this.calendarData);
    //         }
    //     }
    // }

    // addScheduleToDate = async (scheduledActivity: ScheduledActivity, date: string, updateFirebase: boolean = true): Promise<CalendarData> => {
    //     const CalendarData = await this.getCalendarDate(date, true, true);
    //     if (!CalendarData.schedule.some(s => s.id === scheduledActivity.id)) {
    //         CalendarData.schedule.push(scheduledActivity);
    //         // update && updateCalendarData();
    //         updateFirebase && CalendarData.updateFirebase(this.user);
    //     }
    //     return CalendarData;
    // }

    // moveScheduledActivityToAnotherDate = async (scheduledActivity: ScheduledActivity, fromDate: string, toDate: string) => {
    //     fromDate && await this.deleteScheduledActivity(fromDate, scheduledActivity.id);
    //     toDate && await this.addScheduleToDate(scheduledActivity, toDate);
    // }

    // updateScheduledActivity = async (scheduledActivity: ScheduledActivity, oldDate: string, newDate: string) => {

    //     if (oldDate !== newDate) {
    //         this.moveScheduledActivityToAnotherDate(scheduledActivity, oldDate, newDate);
    //     }
    //     else {
    //         const calendarDate = await this.getCalendarDate(newDate, true, true);
    //         if (!calendarDate.schedule.some(s => s.id === scheduledActivity.id)) {
    //             calendarDate.schedule.push(scheduledActivity);
    //         }
    //     }

    //     // updateCalendarData();
    // }

    // addCardToSchedulePlanner = (scheduledActivity: ScheduledActivity, date: string, deleteFunction: Function) => {
    //     let calendarDate: CalendarData = this.calendarData.find((calendarData: CalendarData) => calendarData.date === date)
    //     if (!calendarDate) {
    //         const newCalendarData: CalendarData = new CalendarData(getDefaultCalendarData(date))
    //         this.calendarData.push(newCalendarData);
    //         calendarDate = newCalendarData;
    //     }
    //     if (!calendarDate?.schedule) {
    //         calendarDate.schedule = [];
    //     }
    //     if (scheduledActivity) {
    //         calendarDate.schedule[scheduledActivity.id] = scheduledActivity;
    //         calendarDate.updateFirebase(this.user);
    //     }
    //     if (deleteFunction) {
    //         deleteFunction();
    //     }
    // }

    // getDates = async (startDate: string, endDate: string, fillEmptyDates: boolean, updateCalendar: boolean = false): Promise<CalendarData[]> => {
    //     const dates: CalendarData[] = []
    //     const stringDates = getStringDatesBetween(startDate, endDate)

    //     for (const date of stringDates) {
    //         const d = await this.getCalendarDate(date, fillEmptyDates, false);
    //         if (d) {
    //             dates.push(d);
    //         }
    //     }
    //     if (updateCalendar) {
    //         dates.forEach(d => this.setCalendarDate(d))
    //     }
    //     return dates;
    // }

    // getScheduledActivitiesInTimespan = async (startDate: string, endDate: string): Promise<ScheduledActivity[]> => {
    //     const dates = await this.getDates(startDate, endDate, false, false);
    //     return dates.reduce((previousValue, currentValue, currentIndex, array) => {
    //         const newScheds = Object.values(currentValue.schedule);
    //         return [...previousValue, ...newScheds];
    //     }, []);
    // }

    // getLatestActivitiesInTimeSpan = async (startDate: string, endDate: string, filterFinished): Promise<ScheduledActivity[]> => {
    //     const dates = await this.getDates(startDate, endDate, false, false);
    //     const scheduledActivities = dates.reduce((previousValue, currentValue, currentIndex, array) => {
    //         if (currentValue.hasSchedule()) {
    //             return [...previousValue, ...Object.values(currentValue.schedule)];
    //         }
    //         return previousValue;
    //     }, [])
    //     scheduledActivities.sort((a, b) => a.date > b.date ? -1 : 1);
    //     if (filterFinished) {
    //         return scheduledActivities.filter(s => s.isFinished())
    //     }
    //     return scheduledActivities;
    // }

    // getAllScheduledActivitiesOfTypeInTimeSpan = async (searchActivityName: string, startDate: string, endDate: string, fillEmptyDates: boolean, updateCalendar: boolean): Promise<ScheduledActivity[]> => {
    //     let foundActivities = [];
    //     const dates = await this.getDates(startDate, endDate, fillEmptyDates, updateCalendar);
    //     // if(searchActivityName.includes("Jogga")){
    //     //     console.log(dates);
    //     // }
    //     // dates.forEach((calendarDate: CalendarData) => {
    //     for (const calendarDate of dates) {
    //         // const schedule: ScheduledActivity[] = await this.scheduledActivities.getSchedule(calendarDate.schedule.map(s => s.id));
    //         // calendarDate.getSchedule(dataContext)?.forEach((scheduledActivity: ScheduledActivity) => {
    //         //     if (scheduledActivity.content === searchActivityName) {
    //         //         foundActivities.push(scheduledActivity);
    //         //     }
    //         // })
    //         // const activitiesWithName = schedule?.filter(s => s?.content === searchActivityName);
    //         // foundActivities = [...foundActivities, ...activitiesWithName];
    //     }
    //     if (foundActivities?.length) {
    //         foundActivities.sort((a, b) => a.date > b.date ? -1 : 1)
    //     }
    //     return foundActivities.sort((a, b) => a.date > b.date ? -1 : 1);
    // }

    // getLatesScheduledActivityOfTypeInTimeSpan = async (searchActivity: string, startDate: string, endDate: string, fillEmptyDates: boolean, updateCalendar: boolean): Promise<ScheduledActivity> => {
    //     const allActivititesOfType: ScheduledActivity[] = await this.getAllScheduledActivitiesOfTypeInTimeSpan(searchActivity, startDate, endDate, fillEmptyDates, updateCalendar);
    //     if (allActivititesOfType?.length) {
    //         allActivititesOfType.sort((a, b) => a.dueTime > b.dueTime ? -1 : 1);
    //         return allActivititesOfType[0]
    //     }
    //     return null;
    // }

    // scheduleScheduledActivity = async (scheduledActivity: ScheduledActivity, date: string) => {
    //     // const date = convertTimestampToStringDate(scheduledActivity.startTime);
    //     let calendarDate: CalendarData = await this.getCalendarDate(date, true, false);
    //     if (!calendarDate.schedule) {
    //         calendarDate.schedule = [];
    //     }
    //     calendarDate.schedule.push(scheduledActivity)
    // }

    // const createEventLog = async (title: string, content: string, type: LogTypeEnums, updateFirebase: boolean, extra: any = {}): Promise<IEventLog> => {
    //     const calendarDate = await getCalendarDate(getToday(), true, false);
    //     const newEventLog: IEventLog = { id: getRandomId(), created: new Date().getTime(), title, content, type, extra }

    //     if (!calendarDate.applicationEventLog) {
    //         calendarDate.applicationEventLog = [];
    //     }
    //     calendarDate.applicationEventLog.push(newEventLog);
    //     if (updateFirebase) {
    //         calendarDate.updateFirebase(this.user);
    //     }
    //     updateCalendarData()
    //     return newEventLog;
    // }

    // const getEventLogs = async (fromDate: string, toDate: string): Promise<IEventLog[]> => {
    //     const dates = await getDates(fromDate, toDate, true, true)
    //     const logs = dates.reduce((previousValue, currentValue, currentIndex, array) => {
    //         if (currentValue.applicationEventLog) {
    //             return [...previousValue, ...currentValue.applicationEventLog];
    //         }
    //         return previousValue;
    //     }, [])
    //     return logs
    // }

    tofirebaseData(data: CalendarData) {
        return null
    }
    fromFirebaseData(): CalendarData {
        return null
    }
    
}