import "./styles.scss"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import { TData } from "../../../app/DataContextInterface";
import { BaseModule, IBaseModule } from "../BaseModule";
import { breakpointTypes, getModulesPath, modules } from "../../../utils/constants";
import { Layout } from "../../Layout";
import { writeData } from "../../../utils/Firebase";
import { GridItem } from "./GridItem";
import { ReactGrid } from "./ReactGrid";
import { DataStore } from "../../../app/DataStore/DataStore";
import { BoardModule } from "../BoardModule/BoardModule";
import { CardModule } from "../CardModule/CardModule";

export interface IReactGridModuleProps {
    testDraggable?: boolean;
    useFilter?: boolean;
    currentBreakpoint: breakpointTypes;
}

export class ReactGridModule extends BaseModule {
    testDraggable?: boolean;
    // private filteredIds?: string[];
    useFilter: boolean = false;
    filters: { [key: string]: { func: (module: BaseModule) => boolean } } = {}//Array<(module: BaseModule) => boolean> = [];

    constructor(baseProps: IBaseModule, reactGridModuleProps: IReactGridModuleProps, dataStore: DataStore) {
        super(baseProps, dataStore);
        Object.assign(this, reactGridModuleProps);
        this.layout = new Layout(baseProps.layout);
        // if (this.id === "LayoutStartPoint") {
        //     console.log("h:", this.layout?.lg["we697a99-30e8-4177-b37d-8bdc47625032"]?.h);
        // }
        this.childModules = baseProps.childModules || [];
        this.testDraggable = reactGridModuleProps.testDraggable;
        this.noBox = true;
    }

    async init() {
        // const modulePromises = this.dataStore.data.modules.getModulePromises(this.layout.getGridIds(), this.dataStore);
        const moduleDataPromises = this.dataStore.data.modules.getModuleDataPromises(this.layout.getGridIds(), this.dataStore);

        moduleDataPromises.forEach(p => {
            this.initChildModule(p);
        })

        Promise.all(moduleDataPromises).then((modules) => {
            this.forceRender && this.forceRender();
        })
    }

    initChildModule(promise) {
        promise.then((m) => {
            if (m) {
                this.dataStore.appUtils.getModuleType(m, this).then((module) => {
                    this.childModules.push(module);
                    if (module.type === modules.BOARDS) {
                        console.log(module);
                    }
                    this.dataStore.data.modules.set(module);
                })
            }
        })

    }

    onDragStart = (newGrid: GridItem) => { console.log("Drag Start", newGrid) }
    onDragOver = (e: React.DragEvent<HTMLDivElement>) => { /* console.log("Drag Over", e)  */ }
    onResizeStop = (reactGridModule: ReactGridModule) => {
        // reactGridModule.layout.updateFirebaseData(thisContext.user, reactGridModule.id, thisContext.currentBreakpoint);
    }

    getDataContextSubscribeArgs(dataContext: TData): any[] {
        return []
    }

    getCreationDialog(): JSX.Element {
        return null;
    }
    customDelete(dataContext: TData): void {

    }
    renderTopMenu(): JSX.Element {
        return null;
    }

    filterModules(modules: BaseModule[]): BaseModule[] {
        const filterArray = Object.values(this.filters);
        const filteredModules = modules.filter((c: BaseModule) => {
            if (this instanceof BoardModule && c instanceof CardModule && c.checkboxChecked === true && !this.showChecked) {
                return false;
            }
            if (!filterArray.length) {
                return true;
            }
            return filterArray.some(f => {
                const funcResult = f.func(c)
                return funcResult;
            })
        })
        return filteredModules;
    }

    render(): JSX.Element {
        // const filteredModules = this.filteredIds?.length > 0 ? this.childModules.filter(m => !this.filteredIds.includes(m.id)) : this.childModules;
        const modules = this.childModules;
        const filteredModules = this.filterModules(modules);
        // console.log(this.filters);
        // console.log(filteredModules);
        return <>
            <ReactGrid
                id={this.id}
                layout={this.layout}
                modules={filteredModules}
                onDragStart={(newGrid) => this && this.onDragStart(newGrid)}
                onDragStop={(newGrid: GridItem[], modules: BaseModule[]) => {
                    this.layout.updateGridItems(newGrid, this.dataStore.currentBreakpoint);
                    this.dataStore.data.modules.set(this, true, true);
                    // console.log(`$users/${this.user}/modules/${this.id}/layout`, this.layout.updateFirebaseData(this.user, this.id));
                }}
                onDragOver={(e) => this && this.onDragOver(e)}
                onResizeStop={(gridItems: GridItem[]) => {
                    this.layout.updateGridItems(gridItems, this.dataStore.currentBreakpoint);
                    this.dataStore.data.modules.set(this, true, true);
                    // this.layout.updateFirebaseData(this.user, this.id, this.dataStore.currentBreakpoint);
                }}
                currentBreakpoint={this.dataStore.currentBreakpoint}
                testDraggable={this.testDraggable}
            />
        </>
    }

    updateLayoutFirebase() {
        writeData(`${getModulesPath(this.user, this.id)}/layout`, { lg: this.layout?.lg, sm: this.layout?.lg });
    }

}