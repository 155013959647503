import * as React from 'react';
import { BoardModule, IBoardModuleProps } from '../BoardModule/BoardModule';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { breakpointTypes, modules } from '../../../utils/constants';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Column } from '../../../components/layout/column/Column';
import { Row } from '../../../components/layout/row/Row';
import { PlusIcon } from '../../../icons/PlusIcon';
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { Activity } from '../../../app/DataStore/ItemTypes/Activity';
import { BaseModule, IBaseModule } from '../BaseModule';
import { IReactGridModuleProps } from '../ReactGridModule/ReactGridModule';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { DataStore } from '../../../app/DataStore/DataStore';

export class TodoBoardModule extends BoardModule {
    activities: Activity[];
    dataTestid = "todo-board-module";
    dataContextArgs?: string[];
    moduleCreator: JSX.Element;

    constructor(
        baseProps: IBaseModule,
        reactGridProps: IReactGridModuleProps,
        dataStore: DataStore,
        moduleCreator: JSX.Element,
        boardProps?: IBoardModuleProps,
        activities?: Activity[],
    ) {
        super(baseProps, reactGridProps, dataStore, boardProps);
        this.dataContextArgs = ["activities.activities", "modules.allModules"]
        this.activities = activities || [];
        this.moduleCreator = moduleCreator;
    }

    init = async() => {
        await super.init();
        this.dataStore.subscribe("scheduledActivities.data", (data) => {
            Object.values(data).forEach((scheduledActivity: any) => {
                if (scheduledActivity.startTime > 0) {
                    const moduleToRemove = this.childModules.find(m => m.id === scheduledActivity.id);
                    if (moduleToRemove) {
                        this.removeChildModule(moduleToRemove, this);
                        this.updateFirebase();
                    }
                }
            })
            this.forceRender();
        });
    }


    addScheduledActivity = (scheduledActivityCardModule: ScheduledActivityCardModule) => {
        // scheduledActivityCardModule.parentModule = this;
        // this.addChildModule(scheduledActivityCardModule, this);
        // this.forceRender();
        // this.closeModal();
    }

    async createScheduledActivity(name: string) {
            const scheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createScheduledActivityCardModule(
                this.dataStore,
                { name, user: this.user },
            );
            await this.addChildModule(scheduledActivityCardModule, this);
            this.renderModule();
            this.closeModal();
        }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(() => 
                            <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                this.createScheduledActivity(name);
                            }} />
                        )
                    }}>
                    Lägg till en temporär aktivitet
                    <PlusIcon />
                </div>
                <div
                    data-testid='add-existing-activity-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(
                            () => <ActivityPicker testId="activity-picker" activities={this.activities} onSelect={(activity: Activity) => this.createBoardItem(activity.name, true)} />
                        )
                    }}>
                    Lägg till befintlig aktivitet
                    <PlusIcon />
                </div>
                <div>
                    <Checkbox label='Dölj klara aktiviteter' checked={this.showChecked} onChange={checked => {/* this.hideCheckedModules(checked, true) */}} />
                </div>
            </Row>
        </Column>
    }

    render(): JSX.Element {
        // this.hideCheckedModules(this.hideChecked, false);
        return super.render()
    }

    static createTodoBoardModule = (
        dataStore: DataStore,
        baseProps?: Partial<IBaseModule>,
        // customRelationProps?: Partial<IModuleRelationsProps>,
        customBoardModuleProps?: Partial<IReactGridModuleProps>,
        activities?: Activity[],
    ): TodoBoardModule => {
        const boardModuleProps: IReactGridModuleProps = {
            // onDragStart: (newGrid: GridItem) => { console.log("Drag Start", newGrid) },
            // onDragStop: (reactGridModule: ReactGridModule) => { console.log("Drag Stop", reactGridModule) },
            // onDragOver: (e: React.DragEvent<HTMLDivElement>) => { console.log("Drag Over", e) },
            // onResizeStop: (reactGridModule: ReactGridModule) => {
            //     // reactGridModule.layout.updateFirebaseData(baseProps.user, reactGridModule.id);
            // },
            ...customBoardModuleProps,
            currentBreakpoint: breakpointTypes.LG
        }
        // const relationProps: IModuleRelationsProps = ModuleRelations.getDefaultModuleRelationsProps(customRelationProps)
        const todoBoardModule = new TodoBoardModule(
            BaseModule.getDefaultBaseModuleProps(
                "TodoBoardModule",
                modules.TODO_BOARD,
                dataStore.user,
                baseProps
            ),
            // ModuleRelations.getDefaultModuleRelationsProps(relationProps),
            boardModuleProps,
            dataStore,
            null,
            undefined,
            activities,
        );
        return todoBoardModule
    }

    toFirebaseObject() {
        return {
            ...super.toFirebaseObject(),
            // activities: this.activities.map(activity => activity.toFirebaseObject())
        }
    }

}