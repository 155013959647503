import * as React from 'react'
import { BaseModule, IBaseModule } from "../BaseModule";
import { GridHeightsSM, GridWidthsSM, ILayouts, breakpointTypes, modules } from '../../../utils/constants';
import { CardModule } from '../CardModule/CardModule';
import { PlusIcon } from '../../../icons/PlusIcon';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { Row } from '../../../components/layout/row/Row';
import { Column } from '../../../components/layout/column/Column';
import { IReactGridModuleProps, ReactGridModule } from '../ReactGridModule/ReactGridModule';
import "./BoardModule.scss";
import { GridItem } from '../ReactGridModule/GridItem';
import { removeModule } from '../../../app/utils';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { DataStore } from '../../../app/DataStore/DataStore';

export interface IBoardModuleProps {
    showChecked?: boolean
    createCardWithCheckbox?: boolean;
}

export class BoardModule extends ReactGridModule implements IBoardModuleProps {
    showChecked?: boolean
    createCardWithCheckbox?: boolean;

    constructor(baseProps: IBaseModule, reactGridProps: IReactGridModuleProps, dataStore: DataStore, boardProps?: IBoardModuleProps) {
        // const filteredIds = boardProps?.hideChecked ? baseProps.childModules?.filter(m => "checkboxChecked" in m && m.checkboxChecked).map(m => m.id) : []
        super(
            baseProps,
            { ...reactGridProps, /* filteredIds: []  */ },
            dataStore
        );
        this.useFilter = true;
        Object.assign(this, boardProps);
        //@ts-ignore
        this.showChecked = baseProps.showChecked //orkar inte
        this.showCheckedModules(this.showChecked, false);
        this.noBox = false;
        this.dataTestid = `board-module`;
    }

    // async init() {
    //     // this.module.childModules = []
    //     // this.module.layout.getGridIds().forEach(id => {
    //     //     dataContext.modules.getModule(id, dataContext, appContext, true).then((m) => {
    //     //         if (m) {
    //     //             if ((this.module as BoardModule).hideChecked) {
    //     //                 if ((m as CardModule).checkboxChecked) {
    //     //                     (this.module as BoardModule).filteredIds.push(m.id);
    //     //                 }
    //     //             }
    //     //             m.parentModule = this.module;
    //     //             this.module.childModules.push(m);
    //     //         }
    //     //     })
    //     // });
    // }

    // onScheduledActivityUpdate = async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => {
    //     // console.log(updatedScheduledActivity, self, oldDate);
    //     if (updatedScheduledActivity.scheduledActivity.startTime > 0) {
    //         const todoBoardModule = await getTodoModule(this.dataContext, thisContext);
    //         todoBoardModule.removeChildModule(updatedScheduledActivity, updatedScheduledActivity.parentModule);
    //         const calendarEventsModule = await getCalendarEventsModule(this.dataContext, thisContext);
    //         calendarEventsModule.app.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
    //         // todoModule.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
    //     }
    // }

    // onCreateChildModule(createdModule: BaseModule, parentModule: BaseModule, params?: any) {
    //     // createdModule.app = new CardAppInterface(createdModule as CardModule, this.dataContext, thisContext, this.state);
    //     this.dataStore.data.modules.addModule(createdModule, true, true);
    //     // createdModule.moduleCreator = getModuleCreator(
    //     //     thisContext.user,
    //     //     async (newModuleName: string, newModuleType: string) => {
    //     //         const newModule = await getModuleType(
    //     //             BaseModule.getDefaultBaseModuleProps(newModuleName, newModuleType, { user: thisContext.user }),
    //     //             this.dataContext,
    //     //             thisContext
    //     //         );
    //     //         createdModule.addChildModule(newModule, createdModule);
    //     //         createdModule.app.onCardClick(createdModule);
    //     //     })
    //     this.updateFirebase();
    //     createdModule.updateFirebase();
    // }

    convertToCardModule = () => {
        // const baseProps: IBaseModule = {
        //     id: this.module.id,
        //     user: this.module.user,
        //     type: modules.CARD,
        //     name: this.module.name,
        //     layout: this.module.layout,
        //     parentModule: this.module.parentModule,
        //     childModules: this.module.childModules,
        // }
        // const cardProps: ICardModuleProps = {
        //     checkbox: false,
        //     checkboxChecked: false,
        //     // moduleOptions: this.module.moduleOptions,
        //     moduleCreator: getModuleCreator(
        //         this.module.user,
        //         async (newModuleName: string, newModuleType: string) => {
        //             // const newModule = await getModuleType(
        //             //     BaseModule.getDefaultBaseModuleProps(newModuleName, newModuleType, { user: thisContext.user }),
        //             //     this.dataContext,
        //             //     thisContext
        //             // );
        //             // this.module.addChildModule(newModule, module);
        //         }),
        // }
        // const cardModule = new CardModule(baseProps, cardProps);
        // cardModule.app = new CardAppInterface(cardModule, this.state);
        // const previousParentModule = this.module.parentModule;
        // this.module.parentModule.removeChildModule(this.module, this.module.parentModule);
        // this.dataContext.modules.deleteModule(this.module, true);
        // this.dataContext.modules.addModule(cardModule);
        // previousParentModule.addChildModule(cardModule, previousParentModule);
        // cardModule.updateFirebase();
        // cardModule.forceRender && cardModule.forceRender();
        // previousParentModule.forceRender();
    }

    getCheckedModule = (): BaseModule[] => {
        return this.childModules?.filter((m: BaseModule) => {
            //@ts-ignore
            return m.checkboxChecked === true
        }) || []
    }

    getCheckedModuleIds = (): string[] => {
        //@ts-ignore
        return this.childModules?.filter((m: CardModule) => m.checkboxChecked === true).map(m => m.id) || []
    }

    cardArrayToObject = (cardArray: GridItem[]): { [key: string]: GridItem } => {
        return cardArray.reduce((previous, current, index, array) => {
            previous[current.i] = current;
            return previous;
        }, {})
    }

    actionMenu(): JSX.Element[] {
        return [
            <div onClick={() => {
                // this.convertToCardModule();
            }}
            >
                Konvertera till kort
            </div>
        ]
    }

    // renderFilterButton = () => <Row>
    //     {this.renderCheckedBoxesControl()}
    // </Row>

    // customIcons = (): JSX.Element[] => {
    //     return [
    //         <CloseIcon
    //             onClick={() => {
    //                 // <Tooltip title="Ta bort listan" placement="top">
    //                 //     <div>test</div>
    //                 // </Tooltip>
    //                 this.toggleModal(() => this.renderCheckedBoxesControl())
    //             }}
    //         />
    //     ]
    // }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem(({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.FULL,
                h: GridHeightsSM.FULL,
            }), this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    showCheckedModules = (activate: boolean, forceRender: boolean = true) => {
        this.showChecked = activate;
        // const filterFunction = (baseModule: BaseModule) => (
        //     //@ts-ignore
        //     this.showChecked === true
        // );
        // if (this.showChecked) {
        //     this.filters["checked"] = { func: filterFunction }
        // }
        // else{
        //     //@ts-ignore
        //     this.filters["checked"] = {func: (baseModule: BaseModule) => !baseModule.checkboxChecked};
        // }
        this.dataStore.data.modules.set(this, true, true);
        forceRender && this.forceRender();
    }

    // hideCheckedModules = (hide: boolean, update: boolean) => {
    //     this.hideChecked = hide;
    //     // this.filteredIds = this.hideChecked ? this.getCheckedModuleIds() : [];
    //     if (hide) {
    //         this.setFilteredIds(this.getCheckedModuleIds())
    //     }
    //     else if(!hide){
    //         this.unsetFilteredIds(this.childModules.map(c => c.id))
    //     }
    //     if (update) {
    //         this.forceRender();
    //         this.updateFirebase();
    //     }
    // }

    async createBoardItem(name: string, useCheckbox: boolean) {
        const cardModule: CardModule = CardModule.createModule(
            this.dataStore,
            { name, user: this.user },
            { checkbox: useCheckbox },
            // { parentModule: this }
        );
        await this.addChildModule(cardModule, this);
        // this.addModule(cardModule);
        this.renderModule();
        this.closeModal();
        // this.onCreateCard(cardModule, this);
    }

    renderCheckedBoxesControl = () => <div>
        <Checkbox
            label='Visa checkade boxar'
            checked={this.showChecked}
            onChange={checked => this.showCheckedModules(checked, true)} />
    </div>

    renderTopMenu = (): JSX.Element => {
        let useCheckbox = this.createCardWithCheckbox;
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(() =>
                            <Column alignLeft>
                                <InputConfirm testId='add-board-card' label='Namn på kortet' onConfirm={name => {
                                    this.createBoardItem(name, useCheckbox);
                                }} />
                                <Checkbox label="Använd checkbox" checked={useCheckbox} onChange={checked => {
                                    useCheckbox = checked;
                                }} />
                            </Column>
                        )
                    }}>
                    Lägg till kort
                    <PlusIcon />
                </div>
                {this.renderCheckedBoxesControl()}
            </Row>
        </Column>
    }

    onDelete = () => {
        this.childModules.forEach(m => removeModule(m));
        removeModule(this);
    }

    render(): JSX.Element {
        // this.hideCheckedModules(this.hideChecked, false);
        return <div className="board-module">
            {super.render()}
        </div>
    }

    static getCreationDialog(onCreate: (newModuleName: string, checked: boolean) => void): JSX.Element {
        let checked = false;
        return <Column alignLeft>
            <InputConfirm
                label='Namn på lista'
                onConfirm={(name) => {
                    onCreate(name, checked);
                }}
            />
            <Checkbox label='Checklista' checked={checked} onChange={newChecked => checked = newChecked} />
        </Column>
    }

    static createModule = (
        dataStore: DataStore,
        baseProps?: Partial<IBaseModule>,
        customBoardModuleProps?: Partial<IReactGridModuleProps>,
    ): BoardModule => {
        const boardModuleProps: IReactGridModuleProps = {
            ...customBoardModuleProps,
            currentBreakpoint: breakpointTypes.LG
        }
        // const todoBoardModule = new BoardModule(
        //     BaseModule.getDefaultBaseModuleProps("BoardModule", modules.TODO_BOARD, baseProps),
        //     boardModuleProps,
        // );
        const boardModule = new BoardModule(
            BaseModule.getDefaultBaseModuleProps(
                "BoardModule",
                modules.BOARDS,
                dataStore.user,
                baseProps
            ),
            boardModuleProps,
            dataStore,
            { createCardWithCheckbox: (baseProps as any)?.createCardWithCheckbox });
        return boardModule
    }

    toFirebaseObject() {
        return {
            ...super.toFirebaseObject(),
            showChecked: this.showChecked,
            createCardWithCheckbox: this.createCardWithCheckbox
        };
    }

}