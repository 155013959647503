import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.scss'
import Main from './Main'
import { AppContext } from "./AppContext"
import { DataContext } from './DataContext';
import { TopBar } from './TopBar';
import { SubBar } from './SubBar';
import { useContainerDimensions } from '../components/calendar/useContainerDimensions';
import { TData } from './DataContextInterface';
import { DataStore } from './DataStore/DataStore'
import { getFirebaseSession } from '../utils/Firebase'

export interface IAppProps {
  appContext: any;
  dataContext: TData;
}

const App = (/* props: IAppProps */) => {

  const componentRef = React.useRef(null)
  const mobileBreakpoint = 796;
  const { width, height } = useContainerDimensions(componentRef)
  const isMobile = width < mobileBreakpoint
  // appContext.width = width;
  // appContext.isMobile = isMobile;

  // if (!dataStore) {
  //   return null;
  // }

  return (
    <div className="main-responsive" ref={componentRef} data-testid="main">
      <div className='main'>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </div>
    </div>
  )
}

export default App
