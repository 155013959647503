interface IItem {
    id: string;
    name?: string;
}

export abstract class Item {
    id: string;
    name?: string;

    constructor(id: string, name?: string) {
        this.id = id;
        this.name = name;
    }

    abstract toFirebaseObject(): any
}