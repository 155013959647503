import * as React from 'react'
import { useNavigate } from 'react-router-dom';
import './Login.scss';
import { getFirebaseSession } from '../utils/Firebase';
//test
export const Login = ({onUser}: { onUser: Function}) => {
    
    React.useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const firebaseSession = await getFirebaseSession();
        firebaseSession.auth().onAuthStateChanged(function(user) {
            if (user) {
                onUser(user.uid);
                navigate('/home');
                // setUser(user.uid);
            }
          });
    }

    let navigate = useNavigate();

    const login = async (event) => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
                const firebaseSession = await getFirebaseSession();
                //@ts-ignore
                const test = await firebaseSession.auth().signInWithEmailAndPassword(email.value, password.value);
                // setUser(test.user.uid);
                onUser(test.user.uid);
                navigate('/home');
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="login">
            <h1>Login</h1>
            <form className="login__form" onSubmit={login}>
                <label className="margin-bottom">
                    Email{" "}
                    <input data-testid="email-login" name="email" type="email" placeholder="Email" />
                </label>
                <label className="margin-bottom">
                    Password{" "}
                    <input data-testid="password-login" name="password" type="password" placeholder="Password" />
                </label>
                <button data-testid="submit-login" type="submit">Login</button>
            </form>
        </div>
    )
}
