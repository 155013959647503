import * as React from 'react';
import "./PageLoader.scss";
import { MarginRight } from '../../layout/margin/MarginRight';
import { ReactGridModule } from '../../../module/modules/ReactGridModule/ReactGridModule';
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { Button } from '../../button/Button';
import { resetFirebase } from '../../../debugUtils/firebaseDebugUtils';
import { deleteData } from '../../../utils/Firebase';
import { GET_USER } from '../../../utils/constants';
import { Row } from '../../layout/row/Row';
import { createStartKit, createStartKitNoModules } from '../../../login/Signup';
import { DataStore } from '../../../app/DataStore/DataStore';
import { createActivityModule, createBoardModule, createCalendarEventsModule, createCalendarModule, createEmptyCard, createShoppingListModule, createTodoBoardModule, resetData } from './Startkits';

interface IProps {
    dataStore: DataStore;
}

const PageLoader = (props: IProps) => {
    const { dataStore } = props;
    const [startModule, setStartModule] = React.useState<ReactGridModule>();

    React.useEffect(() => {
        initLayout();
    }, [])

    const initLayout = async () => {
        const startModule: ReactGridModule = await dataStore.data.modules.getModule("LayoutStartPoint", dataStore, null) as ReactGridModule;
        setStartModule(startModule);
    }

    return (
        <div className="page-container" data-testid="page-container">
            <Row>
                <Button
                    onClick={async () => {
                        await resetFirebase(dataStore.user, "TestName", true, true, dataStore);
                    }}
                    label={'Återställ firebase'}
                />
                <MarginRight />
                <Button
                    onClick={async () => {
                        await deleteData(`${GET_USER(dataStore.user)}`);
                        createStartKit(dataStore.user, "TestName", true, dataStore);
                    }}
                    label='Set StartKit'
                />
                <MarginRight />
                <Button
                    onClick={async () => {
                        await deleteData(`${GET_USER(dataStore.user)}`);
                        createStartKitNoModules(dataStore.user, "TestName", true, dataStore);
                    }}
                    label='Set StartKit (no modules)'
                />
                <MarginRight />
                <Button
                    onClick={async () => {
                        await resetData(dataStore);
                        createCalendarEventsModule(dataStore, startModule, { x: 0, y: 0, w: 4, h: 20 });
                        // createTodoBoardModule(dataStore, startModule, {x: 4, y: 0, w: 4, h: 20 });
                        // createActivityModule(dataStore, startModule, {x: 8, y: 0, w: 4, h: 20 });
                        // createCalendarModule(dataStore, startModule, {x: 0, y: 20, w: 12, h: 20 }); 
                    }}
                    label='Set StartKit (custom)'
                />
                <MarginRight />
                <Button
                    onClick={async () => {
                        dataStore.set("modules.test", "test123");
                    }}
                    label='set modules value'
                />
                <MarginRight />
                <Button
                    onClick={async () => {
                        // await deleteData(`${GET_USER(dataStore.user)}`);
                        // createStartKit(dataStore.user, "TestName", true, dataStore);
                        await resetData(dataStore);
                        // createShoppingListModule(dataStore, startModule, { x: 0, y: 0, w: 4, h: 20 });
                        // // createBoardModule(dataStore, startModule, { x: 4, y: 0, w: 4, h: 20 })
                        // createCalendarEventsModule(dataStore, startModule, {x: 0, y: 0, w: 4, h: 20 });
                        // readData(`${GET_USER(dataStore.user)}/data/calendar_dates`).then((data) => {
                        //     console.log(data);
                        //     Object.entries(data).forEach((d) => {
                        //         const key = d[0];
                        //         const value = d[1];
                        //         //@ts-ignore
                        //         const path = `${GET_USER(dataStore.user)}/data/calendar_dates/${value.date}`
                        //         //@ts-ignore
                        //         value.id = value.date;
                        //         writeData(path, value);
                        //         console.log(path, value);
                        //     })
                        // })
                        // createEmptyCard(dataStore, startModule, {x: 0, y: 0, w: 4, h: 6 });
                        // const todoboardModule = await createTodoBoardModule(dataStore, startModule, {x: 4, y: 0, w: 4, h: 20 });
                        // todoboardModule.createScheduledActivity("TestName");
                        createActivityModule(dataStore, startModule, {x: 0, y: 0, w: 4, h: 20 });
                    }}
                    label='Custom function'
                />
                <MarginRight />
                <Button label="Refresh mainlayout" onClick={async() => {
                    console.log(dataStore.data.modules.getModuleByName("TestName")?.modalContent)
                    console.log(dataStore.data.modules.getModuleByName("TestName")?.modalContentFunction)
                    startModule.forceRender();
                }}/>
            </Row>
            <MarginBottom />
            {startModule && <div>{startModule.renderModule()}</div>}
        </div>
    )
}

export { PageLoader }