import { CalendarData } from "../../components/calendar/CalendarData";
import { IActivityTableModuleProps, ActivityTableModule } from "../../module/modules/ActivityTableModule/ActivityTableModule";
import { IBaseModule, BaseModule } from "../../module/modules/BaseModule";
import { BoardModule, IBoardModuleProps } from "../../module/modules/BoardModule/BoardModule";
import { CalendarEventsModule } from "../../module/modules/CalendarEventsModule/CalendarEventsModule";
import { CalendarModule } from "../../module/modules/CalendarModule/CalendarModule";
import { CardModule, ICardModuleProps } from "../../module/modules/CardModule/CardModule";
import { CardModuleFood } from "../../module/modules/CardModuleFood/CardModuleFood";
import { CardModuleWare } from "../../module/modules/CardModuleWare/CardModuleWare";
import { NotYetImplementedModule } from "../../module/modules/NotYetImplementedModule/NotYetImplementedModule";
import { IReactGridModuleProps, ReactGridModule } from "../../module/modules/ReactGridModule/ReactGridModule";
import { ScheduledActivityCardModule } from "../../module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule";
import { ShoppingListModule } from "../../module/modules/ShoppingListModule/ShoppingListModule";
import { TodoBoardModule } from "../../module/modules/TodoBoardModule/TodoBoardModule";
import { getModuleCreator } from "../../module/moduleUtils";
import { modules, breakpointTypes } from "../../utils/constants";
import { addDaysStringDate, convertTimestampToStringDate, getToday } from "../../utils/TimeUtils";
import { DataStore } from "./DataStore";

export class AppUtils {
    dataStore: DataStore;

    constructor(dataStore: DataStore) {
        this.dataStore = dataStore;
    }

    updateScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, oldDate: string) => {

        // const deleteScheduledActivityFromDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        //     calendarData.schedule = calendarData?.schedule.filter(s => s.id !== scheduledActivity.id);
        // }

        // const newDate = convertTimestampToStringDate(scheduledActivity.scheduledActivity.startTime);
        // if (newDate !== oldDate) {
        //     const oldCalendarD = this.dates.find(d => d.date === oldDate)
        //     const newCalendarD = this.dates.find(d => d.date === newDate)
        //     oldCalendarD && deleteScheduledActivityFromDate(oldCalendarD, scheduledActivity);
        //     this.addScheduledActivityToDate(newCalendarD, scheduledActivity);
        //     // this.onScheduledActivityUpdate(scheduledActivity, newCalendarD.date);
        // }
        // else {
        //     const oldCalendarD = this.dates.find(d => d.date === oldDate)
        //     // this.onScheduledActivityUpdate(scheduledActivity, oldCalendarD.date);
        // }
    }

    getModuleType = async (baseProps: IBaseModule, parentModule: BaseModule, extra?: any): Promise<BaseModule> => {
        let module;
        if (baseProps.type === modules.CARD) {
            module = new CardModule(
                baseProps,
                {
                    checkbox: (baseProps as unknown as ICardModuleProps).checkbox || extra?.hasCheckbox,
                    checkboxChecked: (baseProps as unknown as ICardModuleProps).checkboxChecked,
                    moduleOptions: (baseProps as unknown as ICardModuleProps).moduleOptions,
                },
                this.dataStore
            ) as CardModule;
        }
        else if (baseProps.type === modules.CARD_FOOD) {
            module = new CardModuleFood(
                baseProps,
                {
                    checkbox: (baseProps as unknown as ICardModuleProps).checkbox || extra?.hasCheckbox,
                    checkboxChecked: (baseProps as unknown as ICardModuleProps).checkboxChecked,
                    moduleOptions: (baseProps as unknown as ICardModuleProps).moduleOptions,
                },
                this.dataStore
            ) as CardModuleFood;
        }
        else if (baseProps.type === modules.CARD_WARE) {
            module = new CardModuleWare(
                baseProps,
                {
                    checkbox: (baseProps as unknown as ICardModuleProps).checkbox || extra?.hasCheckbox,
                    checkboxChecked: (baseProps as unknown as ICardModuleProps).checkboxChecked,
                    moduleOptions: (baseProps as unknown as ICardModuleProps).moduleOptions,
                },
                this.dataStore
            ) as CardModuleWare;
        }
        else if (baseProps.type === modules.SCHEDULED_ACTIVITY_CARD) {
            module = new ScheduledActivityCardModule(
                baseProps,
                {
                    checkbox: (baseProps as unknown as ICardModuleProps).checkbox || extra?.hasCheckbox,
                    checkboxChecked: (baseProps as unknown as ICardModuleProps).checkboxChecked,
                    moduleOptions: (baseProps as unknown as ICardModuleProps).moduleOptions,
                },
                //@ts-ignore
                this.dataStore
            )
        }
        else if (baseProps.type === modules.BOARDS) {
            const boardModuleProps: IReactGridModuleProps = {
                currentBreakpoint: breakpointTypes.LG, //TODO: get from appContext
            }
            module = new BoardModule(
                baseProps,
                boardModuleProps,
                this.dataStore,
                { createCardWithCheckbox: extra?.createCardWithCheckbox || (baseProps as any)?.createCardWithCheckbox },
            );
        }
        else if (baseProps.type === modules.CALENDAR) {
            module = new CalendarModule(baseProps, this.dataStore);
        }
        else if (baseProps.type === modules.CALENDAR_EVENTS) {
            module = new CalendarEventsModule(
                baseProps,
                this.dataStore,
            )
            // module.app = new CalendarEventsAppInterface(module, dataStore);
        }
        else if (baseProps.type === modules.GRID_LAYOUT) {
            module = new ReactGridModule(
                baseProps,
                { currentBreakpoint: breakpointTypes.LG },
                this.dataStore
                // { currentBreakpoint: appContext.currentBreakpoint }
            );
        }
        else if (baseProps.type === modules.ACTIVITY_TABLE) {

            const activityTableModuleProps: IActivityTableModuleProps = {
                startDate: getToday(),
                endDate: addDaysStringDate(getToday(), 10),
            }
            module = new ActivityTableModule(
                baseProps,
                activityTableModuleProps,
                this.dataStore
            );
        }
        else if (baseProps.type === modules.TODO_BOARD) {
            const reactGridModuleProps: IReactGridModuleProps = {
                currentBreakpoint: breakpointTypes.LG//appContext.currentBreakpoint,
            }

            const boardProps: IBoardModuleProps = {
                showChecked: (baseProps as any).hideChecked,
            }

            baseProps.user = this.dataStore.user;
            module = new TodoBoardModule(
                baseProps,
                reactGridModuleProps,
                this.dataStore,
                null,
                boardProps,
                []
            ) as TodoBoardModule;

        }
        else if (baseProps.type === modules.SHOPPING_LIST) {
            const reactGridModuleProps: IReactGridModuleProps = {
                currentBreakpoint: breakpointTypes.LG//appContext.currentBreakpoint,
            }

            const boardProps: IBoardModuleProps = {
                showChecked: (baseProps as any).hideChecked,
            }

            baseProps.user = this.dataStore.user;
            module = new ShoppingListModule(
                baseProps,
                reactGridModuleProps,
                this.dataStore,
                boardProps,
            ) as ShoppingListModule;
            console.log(module);
        }
        else {
            module = new NotYetImplementedModule({ ...baseProps }, this.dataStore);
        }

        module.parentModule = parentModule;
        !module.dataStore && (module.dataStore = this.dataStore);
        // await module.init();

        return module;
    }
    
}