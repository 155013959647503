import { Activity, IActivity } from "./ItemTypes/Activity";
import { GET_USER } from "../../utils/constants";
import { readData } from "../../utils/Firebase";
import { BaseData } from "./BaseData";
import { DataStore } from "./DataStore";

export class Activities extends BaseData<Activity> {
    activitiesPath: string;

    constructor(dataStore: DataStore) {
        super(dataStore, "activities.data", `${GET_USER(dataStore.user)}/data/activity`);
    }

    async init() {
        const acts: Activity[] = await readData(this.firebasePath);

        readData(
            this.firebasePath,
        ).then((activities: { [key: string]: IActivity }) => {
            if (activities) {
                const activitiesObject = Object.values(activities).reduce((previous, current) => {
                    const newScheduledActivity = new Activity(current);
                    previous[newScheduledActivity.id] = newScheduledActivity;
                    return previous;
                }, {});
                this.data = activitiesObject;
            }
        })
    }

    getAllActivitiesFromFirebase = async (): Promise<{ [key: string]: Activity }> => {
        const acts: Activity[] = await readData(this.firebasePath);
        if (acts) {
            const mappedActivities = this.convertFirebaseActivities(acts);
            return mappedActivities.reduce((previous, current) => {
                previous[current.id] = current;
                return previous;
            }, {});
        }
    }

    // deleteActivity = (activity: Activity, updateFirebase?: boolean): Activity[] => {
    //     const newActivities = this.activities.filter((a: Activity) => a.id !== activity.id);
    //     if (updateFirebase) {
    //         deleteData(getDataPath(this.user, data.ACTIVITY, activity.id));
    //     }
    //     return newActivities;
    // }

    // addActivity = (activity: Activity, updateFirebase?: boolean) => {
    //     this.activities = [...this.activities, activity];
    //     if (updateFirebase) {
    //         writeData(getDataPath(this.user, data.ACTIVITY, activity.id), activity.toJSON())
    //     }
    //     return activity;
    // }

    // getActivity = (id: string): Activity => {
    //     const foundActivity: Activity = this.activities?.find((activity: Activity) => activity.id === id);
    //     return foundActivity;
    // }

    // getRootActivities = (): Activity[] => {
    //     return this.activities.filter(a => a.isRoot())
    // }

    // getSiblingActivities = (activity: Activity, excludeSelf: boolean = false): Activity[] => {
    //     const sibblings: Activity[] = [];

    //     if (activity.isRoot()) {
    //         return this.activities.filter(a => (a?.isRoot() && !(excludeSelf && a.id === activity.id)))
    //     }

    //     activity.parentActivities.forEach((parent: Activity) => {
    //         parent.childActivities.forEach((child: Activity) => {
    //             if (!sibblings.includes(child) && !(excludeSelf && child.id === activity.id)) {
    //                 sibblings.push(child);
    //             }
    //         })
    //     })
    //     return sibblings
    // }

    // getPreviousSibbling = (activity: Activity): Activity => {
    //     const sibblings: Activity[] = this.getSiblingActivities(activity);
    //     if (!sibblings) {
    //         return null;
    //     }
    //     const indexOfACtivity = sibblings.findIndex(s => s.id === activity.id);
    //     if (indexOfACtivity === 0) {
    //         return null;
    //     }
    //     return sibblings[indexOfACtivity - 1]
    // }

    // getNextSibbling = (activity: Activity): Activity => {
    //     const sibblings: Activity[] = this.getSiblingActivities(activity);
    //     if (!sibblings) {
    //         return null;
    //     }
    //     const indexOfACtivity = sibblings.findIndex(s => s.id === activity.id);
    //     if (indexOfACtivity === sibblings.length - 1) {
    //         return null;
    //     }
    //     return sibblings[indexOfACtivity + 1]
    // }

    // updateActivity = (user, activity: Activity, updateFirebase) => {
    //     const i = this.activities.findIndex(a => a.id === activity.id);
    //     this.activities[i] = activity;
    //     if (updateFirebase) {
    //         activity.updateFirebase(user)
    //     }
    // }



    // getAveragePointsAllActivities = (): number => {
    //     if (!this.activities?.length) {
    //         return 0;
    //     }
    //     const average: number = Math.round(getAverageValueFromArray(this.activities, x => x.fixedBonusPoints ? x.fixedBonusPoints : x.averageTime > 0 ? x.averageTime / 60000 : 75))
    //     return average;
    // }

    // getExpiredActivitiesSorted = async (dataContext, startDate: string, endDate: string, expired: boolean = true): Promise<Activity[]> => {
    //     const t = await asyncMap([...this.activities], async s => {
    //         const test = await s.getSelectedActivity(dataContext, startDate, endDate);
    //         return { dueTime: test?.dueTime, activity: s }
    //     })
    //     const acts = t.sort((a, b) => {
    //         if (a.dueTime && b.dueTime) {
    //             return a.dueTime < b.dueTime ? -1 : 1
    //         }
    //         else if (a.dueTime && !b.dueTime) {
    //             return -1;
    //         }
    //         else if (!a.dueTime && b.dueTime) {
    //             return 1;
    //         }
    //         else return -1;
    //     });
    //     if (expired) {
    //         const now = getNow();
    //         return acts.filter(a => a.dueTime < now).map(a => a.activity)
    //     }
    //     return acts.map(a => a.activity);
    // }

    // createActivity = (name: string, updateFirebase: boolean): Activity => {
    //     const activity: Activity = getDefaultActivity(name);
    //     this.addActivity(activity, updateFirebase);
    //     return activity;
    // }

    getActivities = (ids: string[], customActivities): Activity[] => {
        if (!customActivities?.length) {
            return [];
        }
        const filteredActivities: Activity[] = [];
        for (const a of customActivities) {
            if (ids?.includes(a.id)) {
                filteredActivities.push(a);
            }
        }
        return filteredActivities;
    }

    convertFirebaseActivities = (acts): Activity[] => {
        if (acts) {
            const mappedActivities: Activity[] = Object.values(acts).map((act, idx) => {
                //@ts-ignore
                const activity: Activity = act.isGym ? new GymActivity(act) : new Activity(act);
                return activity;
            })

            mappedActivities.forEach((activity: Activity) => {
                activity.parentActivities = this.getActivities(activity.parentActivities as unknown as string[], mappedActivities);
                activity.childActivities = this.getActivities(activity.childActivities as unknown as string[], mappedActivities);
                activity.rootActivities = this.getActivities(activity.rootActivities as unknown as string[], mappedActivities);
                activity.chainedActivities = this.getActivities(activity.chainedActivities as unknown as string[], mappedActivities);
                activity.leafActivities = this.getActivities(activity.leafActivities as unknown as string[], mappedActivities);
            })
            return mappedActivities;
        }
    }

    tofirebaseData(data: Activity) {
        return null
    }
    fromFirebaseData(): Activity {
        return null
    }
}