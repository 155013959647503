import * as React from 'react'
import "./SubBar.scss";
import { BaseModule } from '../module/modules/BaseModule';
import { Row } from '../components/layout/row/Row';
import { getModuleCreator } from '../module/moduleUtils';
import { DataStore } from './DataStore/DataStore';
import { ReactGridModule } from '../module/modules/ReactGridModule/ReactGridModule';
import { createAndAddModuleToRootLayout } from '../components/page/page_loader/Startkits';

const SubBar = ({ dataStore }: { dataStore: DataStore }) => {
    // const [startModule, setStartModule] = React.useState<ReactGridModule>();

    // React.useEffect(() => {
    //     if (dataStore) {
    //         initLayout();
    //     }
    // }, [dataStore])

    // React.useEffect(() => {
    //     if (dataStore?.data.modules.data["LayoutStartPoint"].forceRender) {
    //         console.log('hej')
    //         //@ts-ignore
    //         setStartModule(dataStore?.data.modules.data["LayoutStartPoint"])
    //     }
    // }, [dataStore?.data?.modules?.data["LayoutStartPoint"]?.forceRender])

    // const initLayout = async () => {
    //     const startModule: ReactGridModule = await dataStore.data.modules.getModule("LayoutStartPoint", dataStore, null) as ReactGridModule;
    //     setStartModule(startModule);
    // }

    // console.log(startModule?.forceRender);

    return (
        <Row className="subbar">
            <div className="subbar-container">
                {dataStore?.user && <Row>
                    {
                        getModuleCreator(
                            dataStore.user,
                            async (
                                newModuleName: string,
                                newModuleType: string,
                                params?: any
                            ) => {
                                const startModule: ReactGridModule = await dataStore.data.modules.getModule("LayoutStartPoint", dataStore, null) as ReactGridModule;
                                console.log(newModuleName, newModuleType, params);
                                const newModule = await dataStore.appUtils.getModuleType(
                                    BaseModule.getDefaultBaseModuleProps(newModuleName, newModuleType, dataStore.user),
                                    startModule,
                                    params
                                )
                                await createAndAddModuleToRootLayout(dataStore, newModule, startModule);
                                startModule.forceRender();
                                // startModule.addChildModule(newModule, startModule);
                                // dataStore.data.modules.set(newModule, true, true);
                                // dataStore.data.modules.set(startModule, true, true);
                                // const layout = customLayout ? Layout.createILayouts(module.id,
                                //         { i: module.id, ...customLayout }
                                //     ) : undefined;
                                // const rootLayout = await dataStore.data.modules.getRootLayout(parentModule)
                                // const newModule = await getModuleType(
                                //     BaseModule.getDefaultBaseModuleProps(newModuleName, newModuleType, { user: appContext.user }),
                                //     dataContext,
                                //     appContext,
                                //     params
                                // );
                                // rootModule.addChildModule(newModule, rootModule);
                            }
                        )
                    }
                </Row>}
            </div>
        </Row>
    )
}

export { SubBar }
