import { Item } from "./ItemTypes/Item";
import { readData } from "../../utils/Firebase";
import { DataStore } from "./DataStore";

export abstract class BaseData<T extends Item> {
    appPath: string;
    firebasePath: string;
    dataStore: DataStore;
    data: { [key: string]: T } = {};

    constructor(dataStore: DataStore, appPath: string, firebasePath: string) {
        this.dataStore = dataStore;
        this.appPath = appPath;
        this.firebasePath = firebasePath;
        this.init();
    }

    abstract init(): Promise<void>
    abstract tofirebaseData(data: T)
    abstract fromFirebaseData(): T

    set(item: T, broadcast: boolean = true, updateFirebase = false) {
        this.data[item.id] = item;
        this.dataStore.set(this.appPath, this.data, broadcast);
        if (updateFirebase) {
            this.dataStore.updateFirebase(`${this.firebasePath}/${item.id}`, item.toFirebaseObject());
        }
    }

    delete(item: T, broadcast: boolean = true, updateFirebase = false) {
        delete this.data[item.id];
        this.dataStore.set(this.appPath, this.data, broadcast);
        if(updateFirebase){
            // deleteData(`${this.firebasePath}/${item.id}`);
            this.dataStore.deleteFromFirebase(`${this.firebasePath}/${item.id}`);
        }
    }

    getAsArray = (): T[] => {
        return Object.values(this.data);
    }

    getAllFromFirebase = async (): Promise<{[key: string]: T}> => {
        console.log(this.firebasePath);
        return await readData(this.firebasePath);
    }

}