import * as React from 'react';
import { BoardModule, IBoardModuleProps } from '../BoardModule/BoardModule';
import { breakpointTypes, modules } from '../../../utils/constants';
import { Column } from '../../../components/layout/column/Column';
import { Activity } from '../../../app/DataStore/ItemTypes/Activity';
import { BaseModule, IBaseModule } from '../BaseModule';
import { IReactGridModuleProps } from '../ReactGridModule/ReactGridModule';
import { DataStore } from '../../../app/DataStore/DataStore';
import { Row } from '../../../components/layout/row/Row';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { PlusIcon } from '../../../icons/PlusIcon';
import { CardModuleFood } from '../CardModuleFood/CardModuleFood';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { CardModuleWare } from '../CardModuleWare/CardModuleWare';
import "./ShoppingListModule.scss";
import { Button } from '../../../components/button/Button';

export class ShoppingListModule extends BoardModule {
    dataTestid = "shopping-list-module";
    showWares: boolean = true;
    showFood: boolean = true;

    constructor(
        baseProps: IBaseModule,
        reactGridProps: IReactGridModuleProps,
        dataStore: DataStore,
        boardProps?: IBoardModuleProps,
    ) {
        super(baseProps, reactGridProps, dataStore, boardProps);
        const showWares = (baseProps as any).showWares;
        const showFood = (baseProps as any).showFood;
        this.showWares = showWares === undefined ? true : showWares
        this.showFood = showFood === undefined ? true : showFood
        this.setFoodFilter(this.showFood);
        this.setWareFilter(this.showWares);
    }

    async createFoodItem(name: string, useCheckbox: boolean) {
        const cardModule: CardModuleFood = CardModuleFood.createModule(
            this.dataStore,
            { name, user: this.user },
            { checkbox: useCheckbox },
        );
        await this.addChildModule(cardModule, this);
        this.renderModule();
        this.closeModal();
    }

    async createWareItem(name: string, useCheckbox: boolean) {
        const cardModule: CardModuleWare = CardModuleWare.createModule(
            this.dataStore,
            { name, user: this.user },
            { checkbox: useCheckbox },
        );
        await this.addChildModule(cardModule, this);
        this.renderModule();
        this.closeModal();
    }

    setFoodFilter = (activate: boolean) => {
        const filterFunction = (baseModule: BaseModule) => baseModule.type === modules.CARD_FOOD
        activate ? (this.filters["foodfilter"] = {func: filterFunction}) : delete this.filters["foodfilter"];
        this.showFood = activate;
        this.dataStore.data.modules.set(this, true, true);
    }
    
    setWareFilter = (activate: boolean) => {
        const filterFunction = (baseModule: BaseModule) => baseModule.type === modules.CARD_WARE
        activate ? (this.filters["warefilter"] = {func: filterFunction}) : delete this.filters["warefilter"];
        this.showWares = activate;
        this.dataStore.data.modules.set(this, true, true);
    }

    renderTopMenu = (): JSX.Element => {
        return <Column alignLeft className='board-top'>
            <Row>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(() =>
                            <InputConfirm testId='add-food' label='Lägg till matvara' onConfirm={name => {
                                this.createFoodItem(name, true);
                            }} />
                        )
                    }}>
                    Lägg till en matvara
                    <PlusIcon />
                </div>
                <div
                    data-testid='add-card-button'
                    className="center-content"
                    role="button"
                    onClick={(e) => {
                        this.toggleModal(() =>
                            <InputConfirm testId='add-ware' label='Lägg till en pryl' onConfirm={name => {
                                this.createWareItem(name, true);
                            }} />
                        )
                    }}>
                    Lägg till en vara
                    <PlusIcon />
                </div>
                <Checkbox label='Visa matvaror' checked={this.showFood} onChange={checked => {
                    this.setFoodFilter(checked);
                    this.forceRender();
                }} />
                <Checkbox label='Visa prylar' checked={this.showWares} onChange={checked => {
                    this.setWareFilter(checked);
                    this.forceRender();
                }} />
                {this.renderCheckedBoxesControl()}
            </Row>
        </Column>
    }
     footer(): JSX.Element {
         return <Button label="Klar" onClick={() => {
            const test = this.getCheckedModule();
            test.forEach(t => {
                this.removeChildModule(t, this);
            });
            this.dataStore.data.modules.set(this, true, true);
            this.forceRender();
         }} />
     }

    render(): JSX.Element {
        return super.render()
    }

    static createShoppingListModule = (
        dataStore: DataStore,
        baseProps?: Partial<IBaseModule>,
        // customRelationProps?: Partial<IModuleRelationsProps>,
        customBoardModuleProps?: Partial<IReactGridModuleProps>,
        activities?: Activity[],
    ): ShoppingListModule => {
        const boardModuleProps: IReactGridModuleProps = {
            ...customBoardModuleProps,
            currentBreakpoint: breakpointTypes.LG
        }
        const shoppingListModule = new ShoppingListModule(
            BaseModule.getDefaultBaseModuleProps(
                "ShoppinglistModule",
                modules.SHOPPING_LIST,
                dataStore.user,
                baseProps
            ),
            boardModuleProps,
            dataStore,
            null,
        );
        return shoppingListModule
    }

    toFirebaseObject() {
        return {
            ...super.toFirebaseObject(),
            showWares: this.showWares,
            showFood: this.showFood
        }
    }

}