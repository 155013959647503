import { breakpointTypes } from "../../utils/constants";
import { Activities } from "./Activities";
import { AppUtils } from "./AppUtils";
import { CalendarDates } from "./calendarDates";
import { Modules } from "./Modules";
import { ScheduledActivities } from "./ItemTypes/ScheduledActivties";
import { deleteData, writeData } from "../../utils/Firebase";

interface IData {
    modules: Modules;
    calendarDates: CalendarDates;
    scheduledActivities: ScheduledActivities;
    activities: Activities;
}

export interface IDataStore {
    data: IData;
    appUtils: AppUtils;
    user: string;
    subscribers: { [key: string]: Function[] };
    subscribe: (key: string, callback: Function) => void;
    set: (key: string, value: any) => void;
    get: (key: string) => any;
}

export class DataStore implements IDataStore {
    data: IData;
    appUtils: AppUtils
    user: string;
    currentBreakpoint: breakpointTypes;
    subscribers: { [key: string]: Function[] };

    constructor(user: string) {
        this.user = user;
        this.currentBreakpoint = breakpointTypes.LG;
        const modules = new Modules(this);
        const scheduledActivities = new ScheduledActivities(this)
        const calendarDates = new CalendarDates(this);
        const activities = new Activities(this);
        this.data = {
            modules,
            scheduledActivities,
            calendarDates,
            activities
        };
        this.appUtils = new AppUtils(this);
        this.subscribers = {};
    }

    subscribe(path: string, callback: Function) {
        if (!this.subscribers[path]) {
            this.subscribers[path] = [];
        }
        this.subscribers[path].push(callback);
    }

    set(path: string, value: any, broadcast: boolean = true) {
        const keys = path.split('.');
        let obj: any = this.data;
        for (let i = 0; i < keys.length - 1; i++) {
            obj = obj[keys[i]];
        }
        obj[keys[keys.length - 1]] = value;

        if (broadcast) {
            this.broadcast(path, value);
        }
    }

    broadcast(path, value) {
        if (this.subscribers[path]) {
            this.subscribers[path].forEach(callback => callback(value));
        }
    }

    get(path: string) {
        const keys = path.split('.');
        let obj: any = this.data;
        for (let i = 0; i < keys.length; i++) {
            obj = obj[keys[i]];
        } return obj;
    }

    updateFirebase(path: string, data: any) {
        writeData(path, data);
    }

    deleteFromFirebase(path: string){
        deleteData(path);
    }
}