import * as React from 'react';
import { BaseModuleRenderer } from "./BaseModuleRenderer";
import Modal from '../../components/modal/Modal';
import { CloseIcon } from '../../icons/CloseIcon';
import { breakpointTypes, getModulesPath, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../utils/constants';
import { TData } from '../../app/DataContextInterface';
import { deleteData, writeData } from '../../utils/Firebase';
import { GridItem } from './ReactGridModule/GridItem';
import { generateId } from '../../utils/Utils';
import { Settings } from './Settings';
import { ITag } from '../../components/tags/Tags';
import { Layout } from '../Layout';
import { DataStore } from '../../app/DataStore/DataStore';
import { IBaseData } from '../../data/interfaces/BaseDataInterface';
import { Item } from '../../app/DataStore/ItemTypes/Item';
import { TodoBoardModule } from './TodoBoardModule/TodoBoardModule';
import { ScheduledActivityCardModule } from './ScheduledActivityCardModule/ScheduledActivityCardModule';

export interface IBaseModule extends IBaseData {
    user: string;
    type: string;
    layout: Layout;
    parentModule: BaseModule;
    parentModuleId?: string;
    childModules: BaseModule[];
    noDataContextSync?: boolean
    settings?: Settings
    tags?: ITag[];
}

/**
 * Om inte en komponent återrenderas, Se BaseModuleRenderers memo och compare funktion så att alla BaseModule variabler är med där ifall några nya lagts till
 */
export abstract class BaseModule extends Item implements IBaseModule {
    id: string;
    name: string;
    user: string;
    layout: Layout;
    dragHandle: string;
    type: string;
    parentModule: BaseModule;
    parentModuleId?: string;
    childModules: BaseModule[];
    tags?: ITag[];

    modalContentFunction: () => JSX.Element
    modalContent: JSX.Element;
    noBox?: boolean;
    select?: Function;
    autoPackingDimensions: { w: number, h: number }
    forceReactGridLayoutRerender?: Function
    dragged: boolean
    forceRender: Function;
    dataTestid: string;
    settings?: Settings;
    dataStore: DataStore

    constructor(props: IBaseModule, dataStore: DataStore) {
        super(props.id, props.name);
        Object.assign(this, props);
        this.dataStore = dataStore;
        if (!this.childModules) {
            this.childModules = [];
        }
        this.layout = new Layout(props.layout);
        if (!this.tags) {
            this.tags = [];
        }
        this.init();
    }

    abstract init(): Promise<void>;
    abstract getCreationDialog(onCreate: (module: BaseModule) => void): JSX.Element
    abstract renderTopMenu(): JSX.Element
    abstract render(): JSX.Element;

    customIcons(): JSX.Element[] {
        return null;
    }

    onDelete = (self: BaseModule, extra?: any) => {

    }

    onRemoveChildModule = (removedModule?: BaseModule, parentModule?: BaseModule) => {
        removedModule.parentModule.layout.updateFirebaseData(this.dataStore.user, removedModule.parentModule.id, this.dataStore.currentBreakpoint);
        // this.dataStore.data.modules.updateModules([removedModule, parentModule], true)
        this.dataStore.data.modules.set(removedModule, true, true);
        this.dataStore.data.modules.set(parentModule, true, true);
        this.parentModule.rerender();
        removedModule.deleteFromFirebase();
    };
    newChildModule = async (params?: any): Promise<BaseModule> => {
        return null;
    };

    sendToTodoList = async (scheduledActivityCardModule: ScheduledActivityCardModule) => {
        const todoBoardModule: TodoBoardModule = await this.dataStore.data.modules.getTodoBoardModule(this);
        await todoBoardModule.addChildModule(scheduledActivityCardModule, todoBoardModule);
        // console.log(scheduledActivityCardModule);
        // console.log(todoBoardModule);
        // todoBoardModule.forceRender();
    }

    toFirebaseObject() {
        return {
            id: this.id,
            user: this.user,
            type: this.type,
            name: this.name,
            tags: this.tags.map(tag => tag.name),
            layout: { lg: this.layout?.lg, sm: this.layout?.lg },
            childModules: this.childModules.map(m => m.id),
            parentModule: this.parentModule?.id
        }
    };

    createSettings(...params) {
        if (!this.settings) {
            this.settings = new Settings(`${getModulesPath(this.user, this.id)}/settings`);
        }
        // console.log(this.type, this.settings);
    }

    getFirebasePath() {
        return `${getModulesPath(this.user, this.id)}`;
    }

    updateFirebase() {
        writeData(this.getFirebasePath(), this.toFirebaseObject());
    }

    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.CARD,
                h: GridHeightsSM.CARD,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.CARD,
                h: GridHeightsLG.CARD,
            }, this.id)
        }
    }

    actionMenu?(): JSX.Element[] {
        return [];
    }

    footer(): JSX.Element {
        return null;
    };

    toggleModal = (modalContent: () => JSX.Element) => {
        this.modalContentFunction = modalContent;
        if (!this.modalContentFunction) {
            this.modalContent = null;
        } else {
            this.modalContent = modalContent();
        }
        this.forceRender && this.forceRender()
    }

    refreshModal = () => {
        this.modalContent = this.modalContentFunction();
        this.forceRender && this.forceRender()
    }

    closeModal = () => {
        this.modalContent = null;
        this.modalContentFunction = null;
        // console.log("close modal", this.name, this.modalContent);
        // console.log("close modal", this.name, this.modalContentFunction);
        this.dataStore.data.modules.set(this, true, true);
        this.rerender();
    }


    deleteFromFirebase() {
        deleteData(this.getFirebasePath());
    }

    renderModule(customDragHandle?: string, customElement?: (BaseModule: BaseModule) => JSX.Element, noDataContextSync?: boolean): JSX.Element {
        const dragHandle = customDragHandle /* || (this.sitemapData.type === modules.MODULE_OVERVIEW ? `handle-${this.sitemapData.id}` : (!parentId ? "" : `handle-${parentId}`)) */;
        return <>
            <BaseModuleRenderer baseModule={this} dragHandle={dragHandle} customElement={customElement} />
        </>
    }

    setTags = (tags: ITag[]) => {
        this.tags = tags;
        writeData(`${getModulesPath(this.user, this.id)}/tags`, tags);
    }

    getCurrentBreakpoint() {
        const rootParentModule = this.getRootParentModule();
        if (rootParentModule.currentBreakpoint)
            return rootParentModule.currentBreakpoint;
        else
            return ""
    }

    getRootParentModule = () => {
        const parentModule = this.parentModule;
        if (!parentModule) {
            return this;
        }
        else {
            return parentModule.getRootParentModule();
        }
    }

    addChildModule = async (newModule, parentModule, onAdd: boolean = true, customLayout?: ILayouts, params?: any): Promise<BaseModule> => {
        const module: BaseModule = newModule instanceof BaseModule ? newModule : this ? await this.newChildModule(newModule) : null;
        module.parentModule = parentModule;
        if (this.id !== parentModule.id && this.parentModule) {
            this.parentModule.addChildModule(newModule, parentModule);
        }
        else if (this.id === parentModule.id) {
            if (!this.layout) {
                this.layout = new Layout({ id: this.id, [breakpointTypes.SM]: {}, [breakpointTypes.LG]: {} });
            }
            if (!this.childModules) {
                this.childModules = []
            }
            this.layout.addGrid(customLayout || module.getDefaultGrid());
            if (!this.childModules.some(m => m.id === module.id)) {
                this.childModules.push(module)
            }
            if (this && this.onCreateChildModule && onAdd) {
                this?.onCreateChildModule(module, parentModule, params);
            } else if (this.parentModule) {
                this.parentModule.addChildModule(newModule, parentModule);
            }
            else {
                // console.log("no onCreateChildModule was called");
            }
            // this.closeModal();
        }
        else if (!this?.onCreateChildModule) {
            if (this.parentModule) {
                this.parentModule.addChildModule(newModule, parentModule);
            }
            else if (this?.onCreateChildModule && onAdd) {
                this.onCreateChildModule(module, parentModule, params);
            }
        }
        else if (this?.onCreateChildModule && onAdd) {
            this.onCreateChildModule(module, parentModule, params);
        }
        this.forceRender && this.forceRender();
        return module;
    }

    onCreateChildModule(createdModule: BaseModule, parentModule: BaseModule, params?: any) {
        // this.dataContext.modules.addModule(createdModule, true);
        this.dataStore.data.modules.addModule(createdModule, true, true);
        this.dataStore.data.modules.set(createdModule, true, true);
        this.dataStore.data.modules.set(parentModule, true, true);
        // parentModule.updateFirebase();
    };

    // onCreateChildModule(createdModule: CardModule, parentModule: BaseModule, params?: any) {
    //     // this.dataContext.scheduledActivities.updateScheduledActivity(createdModule.scheduledActivity, this.dataContext);
    //     // super.onCreateChildModule(createdModule, parentModule, params);
    // };


    getAncestors = (ancestors: string[] = []): string[] => {
        if (this.parentModule instanceof BaseModule) {
            ancestors.push(this.parentModule.name);
            return this.parentModule.getAncestors(ancestors);
        }
        return ancestors?.reverse();
    }

    moveToAnotherModule =
        (destinationModule: BaseModule) => {
            if (this.parentModule) {
                this.parentModule.removeChildModule(this, this.parentModule);
                destinationModule.addChildModule(this, destinationModule);
                this.parentModule.rerender();
                destinationModule.rerender();
            }
        }

    removeChildModule = (module: BaseModule, parentModule: BaseModule) => {
        if (this.id === parentModule.id) {
            this.layout?.removeGrid(module.id);
            this.childModules = this.childModules.filter(m => m.id !== module.id);
            if (this?.onRemoveChildModule) {
                this.onRemoveChildModule(module, parentModule);
            } else if (this.parentModule?.removeChildModule) {
                this.parentModule.removeChildModule(module, parentModule);
            }
        }
        else {
            if (this.onRemoveChildModule) {
                this.onRemoveChildModule(module, parentModule);
            }
            else if (this.parentModule) {
                this.parentModule.removeChildModule(module, parentModule);
            }
        }

        return module;
    }

    moveModuleUp = () => {
        if (this.parentModule && this.parentModule.parentModule) {
            this.moveToAnotherModule(this.parentModule.parentModule);
        }
    }

    rerender = () => {
        if (this.forceRender) {
            this.forceRender();
        }
        else{
            console.log(this.name, "no forcerender function");
        }
    }

    static getDefaultBaseModuleProps = (name: string, type: string, user: string, props?: Partial<IBaseModule>): IBaseModule => {
        return {
            id: generateId(),
            name,
            type,
            user,
            layout: props?.layout || null,
            parentModule: props?.parentModule || null,
            // onDelete: (self) => {
            //     self.closeModal();
            //     if ((self?.parentModule as any)?.showCardContainer) {
            //         self.parentModule.closeModal();
            //         (self.parentModule as any).showCardContainer()
            //     }
            //     self.parentModule.removeChildModule(self, self.parentModule);
            //     self.parentModule.forceRender();
            // },
            // newChildModule: async (params?: any) => { return null },
            // onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => { },
            // onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => { },
            childModules: [],
            ...props
        }

    }

}

