import * as React from "react";
import { useRef } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import "./Main.scss";
import { useContainerDimensions } from "../components/calendar/useContainerDimensions";
import { PageLoader } from "../components/page/page_loader/PageLoader";
import { Home } from "../pages/home/Home";
import { breakpoints, breakpointTypes } from "../utils/constants";
import { DataStore } from "./DataStore/DataStore";
import { SubBar } from "./SubBar";
import { TopBar } from "./TopBar";

const Main = () => {
  const componentRef = useRef();
  const [mainWidth, setMainWidth] = React.useState(0);
  const { width } = useContainerDimensions(componentRef);
  const [user, setUser] = React.useState(null);
  const [dataStore, setDataStore] = React.useState<DataStore>();

  React.useEffect(() => {
    setMainWidth(width);
  }, [width])

  React.useEffect(() => {
    if (user) {
      setDataStore(new DataStore(user));
    }
  }, [user])

  React.useEffect(() => {
    if (dataStore?.currentBreakpoint) {
      if (mainWidth >= breakpoints.lg) {
        dataStore.currentBreakpoint = breakpointTypes.LG;
      }
      else {
        dataStore.currentBreakpoint = breakpointTypes.SM;
      }
    }
  }, [mainWidth]);

  return (<>
    <TopBar />
    <SubBar dataStore={dataStore}/>
    <div className="content" data-testid="content" ref={componentRef}>
      <Routes>
        <Route path="/" element={<Navigate to="/Home" replace />} />
        {dataStore?.user && <Route path={"/Home"} element={<PageLoader dataStore={dataStore} />} />}
        {!dataStore?.user && <Route path={"/Home"} element={<Home onUser={setUser} />} />}
      </Routes>
    </div>
  </>
  );
};


export default Main;
