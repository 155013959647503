import * as React from 'react'
import './Signup.scss';
import { createUser, writeData } from '../utils/Firebase';
import { Activity } from '../app/DataStore/ItemTypes/Activity';
import { ActivityTableModule } from '../module/modules/ActivityTableModule/ActivityTableModule';
import { CalendarModule } from '../module/modules/CalendarModule/CalendarModule';
import { ReactGridModule } from '../module/modules/ReactGridModule/ReactGridModule';
import { TodoBoardModule } from '../module/modules/TodoBoardModule/TodoBoardModule';
import { breakpointTypes, data, GET_USER, modules } from '../utils/constants';
import { getNow } from '../utils/TimeUtils';
import { CalendarEventsModule } from '../module/modules/CalendarEventsModule/CalendarEventsModule';
import { BaseModule } from '../module/modules/BaseModule';
import { Layout } from '../module/Layout';
import { DataStore } from '../app/DataStore/DataStore';

export interface IStartkit {
    userId: string;
    userName: string;
    activities: Activity[],
    rootLayoutModule: ReactGridModule,
    calendarEventsModule?: CalendarEventsModule,
    todoBoardModule?: TodoBoardModule,
    calendarModule?: CalendarModule,
    activityTableModule?: ActivityTableModule
}

const createFirebaseProfile = async (userId, username) => {
    writeData(`users/${userId}/profile/`, { id: userId, name: username });
    writeData(`${GET_USER(userId)}/${username}`, "dummy data")
}

export const createStartKit = (userId, userName, updateFirebasebase, dataStore: DataStore): IStartkit => {

    const hushallActivity: Activity = new Activity({ name: "Hushåll", id: "hushall", created: getNow() });
    const fritidActivity: Activity = new Activity({ name: "Fritid", id: "fritid", created: getNow() });

    const rootLayoutModule = new ReactGridModule(
        { id: "LayoutStartPoint", name: "Mainlayout", user: userId, type: modules.GRID_LAYOUT, parentModule: null, childModules: [], layout: null },
        { currentBreakpoint: breakpointTypes.LG },
        dataStore
    );

    const calendarEventsModule: CalendarEventsModule = new CalendarEventsModule(
        BaseModule.getDefaultBaseModuleProps(
            "CalendarEventsModule",
            modules.CALENDAR_EVENTS,
            dataStore.user,
            {
                id: "calendarEventsModule",
                parentModule: rootLayoutModule
            }
        ),
        dataStore
    )
    rootLayoutModule.layout = new Layout(
        {
            id: rootLayoutModule.id,
            [breakpointTypes.SM]: {
                calendarEventsModule: { i: "calendarEventsModule", w: 4, h: 20, x: 0, y: 0 },
                todoBoardModule: { i: "todoBoardModule", w: 4, x: 4, h: 20, y: 0 },
                activityTableModule: { i: "activityTableModule", w: 4, x: 8, h: 20, y: 0 },
                calendarModule: { i: "calendarModule", w: 12, x: 0, y: 20, h: 25 }
            },
            [breakpointTypes.LG]: {
                calendarEventsModule: { i: "calendarEventsModule", w: 4, h: 25, x: 0, y: 0 },
                todoBoardModule: { i: "todoBoardModule", w: 4, x: 4, h: 25, y: 0 },
                activityTableModule: { i: "activityTableModule", w: 4, x: 8, h: 25, y: 0 },
                calendarModule: { i: "calendarModule", w: 12, x: 0, y: 20, h: 25 }
            },
        }
    );
    const todoBoardModule: TodoBoardModule = TodoBoardModule.createTodoBoardModule(dataStore, { id: "todoBoardModule", name: "TodoBoardModule", user: userId, parentModule: rootLayoutModule })
    const calendarModule: CalendarModule = CalendarModule.createModule(dataStore, { id: "calendarModule", name: "CalendarModule", user: userId, parentModule: rootLayoutModule });
    const activityTableModule: ActivityTableModule = ActivityTableModule.createModule(dataStore, { id: "activityTableModule", name: "ActivityTableModule", user: userId, parentModule: rootLayoutModule });

    rootLayoutModule.childModules = [calendarEventsModule, todoBoardModule, calendarModule, activityTableModule];

    const startKit: IStartkit = { userId, userName, activities: [hushallActivity, fritidActivity], rootLayoutModule, calendarEventsModule, todoBoardModule, calendarModule, activityTableModule }
    updateFirebasebase && createStartkitFirebase(startKit)
    return startKit;
}

export const createStartKitNoModules = (userId, userName, updateFirebasebase, dataStore: DataStore): IStartkit => {

    const hushallActivity: Activity = new Activity({
        name: "Hushåll",
        id: "hushall",
        created: getNow(),
        rules: [
            {
                "id": "v2ccfac5-a722-4bc1-a7b8-0b2c631fac7b",
                "name": "",
                "ruleSet": [
                    {
                        "func": "oddEvenWeek",
                        "funcParams": {
                            "oddEven": "even"
                        },
                        "id": "j62935e8-d41f-479b-9ec3-2e22c0bbdc6b"
                    },
                    {
                        "func": "isWeekday",
                        "funcParams": {
                            "day": [
                                5
                            ]
                        },
                        "id": "k337d97b-93fa-4d9c-b5a0-8b0a1b266503"
                    },
                    {
                        "func": "startTime",
                        "funcParams": {
                            "startTime": 1734090600057
                        },
                        "id": "i1a8eb21-5a5b-47a4-87d3-faac6f06dac1"
                    }
                ]
            }
        ]
    });
    const fritidActivity: Activity = new Activity({ name: "Fritid", id: "fritid", created: getNow() });

    const rootLayoutModule = new ReactGridModule(
        { id: "LayoutStartPoint", name: "Mainlayout", user: userId, type: modules.GRID_LAYOUT, parentModule: null, childModules: [], layout: null },
        { currentBreakpoint: breakpointTypes.LG },
        dataStore
    );

    rootLayoutModule.layout = new Layout(
        {
            id: rootLayoutModule.id,
            [breakpointTypes.SM]: {
            },
            [breakpointTypes.LG]: {
            },
        }
    );

    const startKit: IStartkit = { userId, userName, activities: [hushallActivity, fritidActivity], rootLayoutModule }
    updateFirebasebase && createStartkitFirebase(startKit)
    return startKit;
}

export const createStartKitCustomModules = (userId, userName, customModules, dataStore: DataStore, updateFirebasebase): IStartkit => {

    const hushallActivity: Activity = new Activity({ name: "Hushåll", id: "hushall", created: getNow() });
    const fritidActivity: Activity = new Activity({ name: "Fritid", id: "fritid", created: getNow() });

    const rootLayoutModule = new ReactGridModule(
        { id: "LayoutStartPoint", name: "Mainlayout", user: userId, type: modules.GRID_LAYOUT, parentModule: null, childModules: [], layout: null },
        { currentBreakpoint: breakpointTypes.LG },
        dataStore
    );

    rootLayoutModule.layout = new Layout(
        {
            id: rootLayoutModule.id,
            [breakpointTypes.SM]: {
            },
            [breakpointTypes.LG]: {
            },
        }
    );

    customModules.forEach((module: BaseModule) => {
        // dataContext.modules.addModule(module)
        // module.updateFirebase();
        // rootLayoutModule.addChildModule(module, rootLayoutModule)
    })

    const startKit: IStartkit = { userId, userName, activities: [hushallActivity, fritidActivity], rootLayoutModule }
    updateFirebasebase && createStartkitFirebase(startKit)
    return startKit;
}

export const createStartkitFirebase = (startkit: IStartkit) => {
    const { userId, userName, activities, rootLayoutModule, calendarEventsModule, todoBoardModule, calendarModule, activityTableModule } = startkit;
    createFirebaseProfile(userId, userName);
    activities.forEach(activity => activity.updateFirebase(userId));

    rootLayoutModule?.updateFirebase()
    calendarEventsModule?.updateFirebase()
    todoBoardModule?.updateFirebase()
    activityTableModule?.updateFirebase()
    calendarModule?.updateFirebase();
}

export const Signup = (
    { onCreateUser, defaultName, defaultEmail, defaultPassword, }: { defaultName?: string, defaultEmail?: string, defaultPassword?: string, onCreateUser: (startkit: IStartkit) => void },
    dataStore: DataStore
) => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const { username, email, password } = event.target.elements;
        try {
            const user = await createUser(email.value, password.value)
            const startkit: IStartkit = createStartKit(user, username.value, true, dataStore);
            // createFirebaseProfile(user, username.value);
            onCreateUser(startkit);
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div className="signup">
            <h1>Sign up</h1>
            <form className="signup__form" onSubmit={onSubmit}>
                <label className="margin-bottom">
                    Användarnamn{" "}
                    <input data-testid="user-signup" defaultValue={defaultName} name="username" type="username" placeholder="Username" />
                </label>
                <label className="margin-bottom">
                    Email{" "}
                    <input data-testid="email-signup" defaultValue={defaultEmail} name="email" type="email" placeholder="Email" />
                </label>
                <label className="margin-bottom">
                    Lösenord{" "}
                    <input data-testid="pass-signup" defaultValue={defaultPassword} name="password" type="password" placeholder="Password" />
                </label>
                <button data-testid="confirm-signup" type="submit">Sign up</button>
            </form>
        </div>
    )
}
