import { GridItem } from '../module/modules/ReactGridModule/GridItem';
import { convertStringDateToTimestamp, convertTimestampToStringObject } from './TimeUtils';

export const sitemapBasePath = (userName) => `users/${userName}/sitemap`
export const GET_SITEMAP_SITE_BASE_PATH = (userName, pageType, extra = "") => `${sitemapBasePath(userName)}/sites/${pageType}${extra ? "/" + extra : ""}`
export const GET_USER = userName => `users/${userName}`
export const getModulesPath = (userName, id) => `${GET_USER(userName)}/modules/${id || ""}`
export const getDataPath = (userName, dataType, id = "") => `${GET_USER(userName)}/data/${dataType}${id ? "/" + id : ""}`

export const DEFAULT_USER = "keva";
export const TEST_USER = "test_user";
export const TEST_KEVA = "test_keva";

export enum breakpointTypes {
    LG = "lg",
    SM = "sm"
};

export const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }

export const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }

export const GET_SITEMAP_SITE_PATH = (userName, pageType, id = "") => `${GET_SITEMAP_SITE_BASE_PATH(userName, pageType)}/modules${id ? "/" + id : ""}`
export const pages = {
    "CALENDAR": "calendar",
    "HOME": "home",
    "INKOP": "inkop",
    "DASHBOARD": "dashboard",
    "DEVS_CORNER": "devscorner",
    "DETERMINATOR": "determinator",
    "INKOPS_LISTA": "inköpslista",
    "BOARD": "board",
    "ABOUT": "about",
    "PROJECTS": "projects",
    "CONTACT": "contact",
    "PLAYGROUND": "playground",
    "UNIT_TEST_PLAYGROUND": "unit_test_playground",
    "ORGANICER": "organicer",
    "SCHEDULE": "schedule",
    "SCHEDULE_WEEK": "schedule_week",
    "TEST": "test"
}

export const weekdays = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag']

export enum WeekdayEnumsSwe {
    MONDAY = "Måndag",
    TUESDAY = "Tisdag",
    WEDNESDAY = "Onsdag",
    THURSDAY = "Torsdag",
    FRIDAY = "Fredag",
    SATURDAY = "Lördag",
    SUNDAY = "Söndag"
}

export enum WeekdayEnums {
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
    SUNDAY = "Sunday"
}

export const modules = {
    ACTIVITY_CREATOR: "activity_creator",
    ACTIVITY_VIEWER: "activity_viewer",
    ACTIVITY_TABLE: "activity_table",
    BOARDS: "boards",
    CARD: "card",
    CARD_FOOD: "card_food",
    CARD_WARE: "card_ware",
    DASHBOARD: "dashboard",
    DATES: "dates",
    FAVORITE: "favorite",
    GOAL: "goal",
    GRID: "grid",
    INKOPSLISTA: "inkopslista",
    CALENDAR: "calendarModule",
    CALENDAR_DATE: "calendar_date",
    CALENDAR_DATES: "calendar_dates",
    CALENDAR_EVENTS: "calendar_events",
    COUNTDOWN: "countdown",
    INKOPSLISTA_STANDARD: "inkopslista_standard",
    GRID_LAYOUT: "grid_layout",
    DEVS_CORNER: "devscorner",
    MERCHANDISE: "merchandise",
    MERCHANDISE_LIST: "merchandise_list",
    MERCHANDISE_TABLE: "merchandise_table",
    MODULE_CREATOR: "module_creator",
    MODULE_OVERVIEW: "module_overview",
    MODULE_TABLE: "module_table",
    PAGE: "page",
    PAGE_CREATOR: "page_creator",
    QUESTION: "question",
    RECIPE: "recipe",
    REMINDERS_TABLE: "reminders_table",
    ROADMAP: "roadmaps",
    SCHEDULES: "schedules",
    SCHEDULE_PLANNER: "schedule_planner",
    SCHEDULE_PLANNER_TODO: "schedule_planner_todo",
    SCHEDULED_ACTIVITY_CARD: "scheduled_activity_card",
    SEAOFCARDS: "sea_of_cards",
    SHOPPING_LIST: "shopping_list",
    STATUS_CARD: "status_card",
    TAGS: "tags",
    TASK: "task",
    TEST: "test",
    TIMESLOTS: "timeslots",
    TODO_BOARD: "todo_board",
}

export const data = {
    ACTIVITY: "activity",
    SCHEDULED_ACTIVITY: "scheduled_activity",
    CHECKLIST: "checklist",
    INBOX: "inbox",
    TAGS: "tags",

}

// export const getBaseModulePath = (userName: string, moduleType?: string) => `${GET_USER(userName)}/base_module${moduleType ? "/" + moduleType : ""}`

export const getCalendarModulePath = (userName: string) => `${getModulesPath(userName, modules.CALENDAR)}`

export const getDatePath = date => `${date.year}/${date.month}/${date.date}`
//users/keva/boards/1
export const firebaseSettingsPath = (user, key = "") => `${GET_USER(user)}/settings${key ? "/" + key : ""}`

// export const firebaseModulePaths = {
//     GET_ACTIVITY_MODULE_PATH: (userName, activityId = "") => getDataPath(userName, `${data.ACTIVITY}`, activityId),

//     GET_CALENDAR_MODULE_PATH: (userName) => getCalendarModulePath(userName),
//     // GET_CALENDAR_DATE_MODULE_PATH: (userName, date: string) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}/${getDatePath(convertTimestampToStringObject(convertStringDateToTimestamp(date)))}`,
//     GET_CALENDAR_DATE_SCHEDULE_MODULE_PATH: (userName, date: string) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}/${getDatePath(convertTimestampToStringObject(convertStringDateToTimestamp(date)))}/schedule`,
//     GET_CALENDAR_DATE_SCHEDULE_ACTIVITY_PATH: (userName, date: string, id: string) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}/${getDatePath(convertTimestampToStringObject(convertStringDateToTimestamp(date)))}/schedule/${id}`,
//     GET_CALENDAR_WEEK_MODULE_PATH: (userName, year: number, week: number) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}/${year}/week/${week}`,
//     GET_CALENDAR_MONTH_MODULE_PATH: (userName, year: number, month: number) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}/${year}/${month}`,
//     GET_CALENDAR_YEAR_MODULE_PATH: (userName, year: number = -1) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}${year > -1 ? "/" + year : ""}`,
//     GET_CALENDAR_DATES_MODULE_PATH: (userName) => `${getCalendarModulePath(userName)}/${modules.CALENDAR_DATES}`,



//     GET_DATES_MODULE_PATH: (userName, date, extraPath) => `users/${userName}/${modules.DATES}/${date}/${extraPath}`,

//     GET_SCHEDULE: (userName, scheduleId) => `users/${userName}/${modules.SCHEDULES}/${scheduleId}`,
//     GET_SCHEDULE_DATE_MODULE_PATH: (userName, scheduleId, startOfDate) => `users/${userName}/${modules.SCHEDULES}/${scheduleId}/${modules.DATES}/${startOfDate}`,
//     GET_SCHEDULE_TIMESLOTS_MODULE_PATH: (userName, scheduleId, startOfDate) => `users/${userName}/${modules.SCHEDULES}/${scheduleId}/${modules.DATES}/${startOfDate}/${modules.TIMESLOTS}`,
//     GET_SCHEDULE_TIMESLOT_MODULE_PATH: (userName, scheduleId, startOfDate, timeslotId) => `users/${userName}/${modules.SCHEDULES}/${scheduleId}/${modules.DATES}/${startOfDate}/${modules.TIMESLOTS}/${timeslotId}`,

// }

export const firebaseSitemapPaths = {
    GET_CALENDAR_EVENTS_SITEMAP_PATH: (userName, pageType, id = "") => GET_SITEMAP_SITE_PATH(userName, pageType) + (id ? "/" + id : ""),
    GET_DEVS_CORNERS_SITEMAP_PATH: (userName, id = "") => GET_SITEMAP_SITE_PATH(userName, modules.DEVS_CORNER) + (id ? "/" + id : ""),
    GET_INKOPSLISTA_SITEMAP_PATH: (userName, id = "") => GET_SITEMAP_SITE_PATH(userName, modules.INKOPSLISTA) + (id ? "/" + id : ""),
    GET_DASHBOARD_SITEMAP_PATH: (userName, id = "") => GET_SITEMAP_SITE_PATH(userName, modules.DASHBOARD) + (id ? "/" + id : ""),
    GET_TEST_SITEMAP_PATH: (userName, id = "") => GET_SITEMAP_SITE_PATH(userName, modules.TEST) + (id ? "/" + id : ""),
    GET_ACTIVITY_TABLE_SITEMAP_PATH: (userName, pageType, id = "") => GET_SITEMAP_SITE_PATH(userName, pageType) + (id ? "/" + id : ""),
    GET_SCHEDULE_PLANNER_SITEMAP_PATH: (userName, pageType, id = "") => GET_SITEMAP_SITE_PATH(userName, pageType) + (id ? "/" + id : ""),
    GET_MERCHANDISE_LIST_SITEMAP_PATH: (userName, pageType, id = "") => GET_SITEMAP_SITE_PATH(userName, pageType) + (id ? "/" + id : ""),
}


export const board_paths = {
    ROWS: "rows",
    CARDS: "cards",
    ARCHIVED_ROWS: "archived_rows",
    ARCHIVED_CARDS: "archived_cards"
}

export const weatherSymbolNameSwe = [
    "Klar himmel",
    "Nästan klar himmel",
    "Varierande molnighet",
    "Halvklar himmel",
    "Målnigt",
    "Mulet",
    "Dimmigt",
    "Lätta regnskurar",
    "Måttliga regnskurar",
    "Kraftiga regnskurar",
    "Åskväder",
    "Lätta snöslaskskurar",
    "Måttliga snöslaskskurar",
    "Kraftiga snöslaskskurar",
    "Lätta snöskurar",
    "Måttliga snöskurar",
    "Kraftiga snöskurar",
    "Duggregn",
    "Måttligt regn",
    "Mycket regn",
    "Åska",
    "Lätt snöslask",
    "Måttligt snöslask",
    "Kraftigt snöslask",
    "Lätt snöfall",
    "Måttligt snöfall",
    "Kraftigt snöfall"]

export const weatherSymbolName = [
    "Clear sky",
    "Nearly clear sky",
    "Variable cloudiness",
    "Halfclear sky",
    "Cloudy sky",
    "Overcast",
    "Fog",
    "Light rain showers",
    "Moderate rain showers",
    "Heavy rain showers",
    "Thunderstorm",
    "Light sleet showers",
    "Moderate sleet showers",
    "Heavy sleet showers",
    "Light snow showers",
    "Moderate snow showers",
    "Heavy snow showers",
    "Light rain",
    "Moderate rain",
    "Heavy rain",
    "Thunder",
    "Light sleet",
    "Moderate sleet",
    "Heavy sleet",
    "Light snowfall",
    "Moderate snowfall",
    "Heavy snowfall"]

export const getMonthName = monthNumber => {
    switch (monthNumber) {
        case 0:
            return "Januari";
        case 1:
            return "Februari"
        case 2:
            return "Mars"
        case 3:
            return "April"
        case 4:
            return "Maj"
        case 5:
            return "Juni"
        case 6:
            return "Juli"
        case 7:
            return "Augusti"
        case 8:
            return "September"
        case 9:
            return "Oktober"
        case 10:
            return "November"
        case 11:
            return "December"
        default:
            return ""
    }
}

export enum LogTypeEnums {
    TAG = "tag",
}

export interface IGridLayout {
    [breakpointTypes.SM]: GridItem[];
    [breakpointTypes.LG]: GridItem[];
}

export interface ILayouts {
    id: string;
    lg: GridItem;
    sm: GridItem;
    hideHandle?: boolean;
}

export enum GridWidthsSM {
    CARD = 2,
    QUARTER = 6,
    HALF = 12,
    FULL = 24
}

export enum GridWidthsLG {
    CARD = 6,
    QUARTER = 6,
    HALF = 12,
    FULL = 24
}

export enum GridHeightsSM {
    CARD = 2,
    QUARTER = 9,
    HALF = 18,
    FULL = 36
}

export enum GridHeightsLG {
    CARD = 5,
    QUARTER = 9,
    HALF = 18,
    FULL = 36
}

export enum ActivityStatus {
    IN_PROGRESS = "in_progress",
    DONE = "done",
    PAUSED = "paused",
    NOT_STARTED = "not_started",
    WONT_DO = "wont_do",
    DIDNT_DO = "didnt_do"
}


export const ACTIVITY_PATH = (user, activityId) => `users/${user}/data/activity/${activityId}`;