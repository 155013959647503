import * as React from 'react'
import { useState } from 'react';
import "./Box.scss";
import InputConfirm from '../input_and_confirm/InputConfirm';
import { MenuIcon } from '../../icons/MenuIcon';
import Modal from '../modal/Modal';
import { Button } from '../button/Button';
import { ScrollContext } from './ScrollContext';
import { Tags } from '../tags/Tags';
import { TagIcon } from '../../icons/TagIcon';
import { BaseModule } from '../../module/modules/BaseModule';

interface IBox {
    headerText?: string;
    className?: string;
    minimized?: boolean;
    hideMinimizer?: boolean;
    style?: any;
    settingsId?: string;
    deletePaths?: string[];
    children?: any;
    hideHeader?: boolean
    onHeaderEditConfirm?: Function
    closeOnConfirm?: boolean;
    onMinimize?: (minimized: boolean) => void;
    width?: number;
    hideMenu?: boolean
    actionMenu?: JSX.Element[];
    customIcons?: JSX.Element[];
    dragHandle?: string;
    onDelete?: any;
    footer?: JSX.Element;
    testId?: string;
    module?: BaseModule;
}

const MenuItem = ({ text, onClick, testId = "" }) => <div className="menu-item" onClick={onClick} data-testid={testId}>
    {text}
</div>

const Box = ({ dragHandle = "drag-handle", headerText, className, hideMinimizer = false, minimized = false, style, settingsId, deletePaths = [], children, hideHeader = false, onHeaderEditConfirm, closeOnConfirm = false, onMinimize, width, hideMenu = true, actionMenu = null, onDelete, footer, testId, module, customIcons }: IBox) => {
    const [isMinimized, setIsMinimized] = useState(minimized);
    const [editHeader, setEditHeader] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [xScrollOffset, setXScrollOffset] = useState(0);
    const [yScrollOffset, setYScrollOffset] = useState(0);
    const [moveModule, setMoveModule] = React.useState(false);
    const [showModal, setShowModal] = React.useState("");

    const onConfirm = val => {
        if (closeOnConfirm) {
            setEditHeader(false);
        }
        onHeaderEditConfirm(val);
    }

    const defaultActionMenu = () => <div data-testid="action-menu">
        {/* <MenuItem text={isMinimized ? "Maximera modulen" : "Minimera modulen"} onClick={handleMinimize} /> */}
        {/* <MenuItem text={!isCard ? "Visa som kort" : "Visa som behållare"} onClick={toggleCardContainer} /> */}
        {/* <MenuItem text="Flytta modulen till ett annat kort" onClick={() => {
            // setMoveModuleCard(true)
            setShowModal("movecard")
        }} /> */}
        {/* <MenuItem text="Flytta modulen till en annan sida" onClick={() => setMoveModule(true)} /> */}
        {deleteModule && <MenuItem text={"Ta bort modulen " + headerText} onClick={deleteModule} testId="delete-module" />}
        <MenuItem
            text="Lägg till taggar"
            onClick={() => {
                setShowModal("tags")
            }} />
    </div>

    const renderStandardModalContent = () => <div data-testid="modal-menu">
        {defaultActionMenu()}
        {actionMenu?.map((item: JSX.Element, i) => {
            return <div key={i} className="margin-bottom" style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid black', padding: '16px' }}>
                {item}
            </div>
        })}
        <Button label="Stäng" onClick={() => {
            setMenuOpen(false);
        }} />
    </div>

    const renderMoveToAnotherCard = async () => {
        // const module = await m.getModule(moduleId);
        return <div>
            {/* <Dropdown label={"Välj kort"} options={[]} onChange={(name) => {

            }} /> */}
            {/* <ModulePicker modules={module.availableModulesToMoveTo || Object.values(dataContext.modules.allModules)} onPick={(pickedModule: BaseModule) => {
                module.moveToAnotherCardContainer(pickedModule.id, dataContext, true);
            }} /> */}
        </div>
    }

    // const renderMoveModule = () => {
    //     return <div>
    //         <Dropdown label={"Flytta modul"} options={sites.filter(s => s.id !== currentPageType).map(s => s.name)} onChange={(s) => {
    //             const test = sites.find(site => site.name === s).id;
    //             setPageMoveModule(test);
    //         }} />
    //         <Button label={"Klar"} onClick={() => {
    //             readData(sitemapPath).then(async data => {
    //                 data.sitemapPath = data.sitemapPath.replace(currentPageType, pageMoveModule);
    //                 const module: BaseModule = await m.getModule(data.id, dataContext, currentPageType, true, true);
    //                 m.deleteModule(module);
    //                 // writeData(data.sitemapPath, data);
    //                 // deleteData(data.sitemapPath.replace(pageMoveModule, currentPageType))
    //             })
    //         }} />
    //     </div>
    // }

    const renderCustomIcons = () => {
        return customIcons?.map((icon, i) => {
            return <div key={i} className="box__header--icon">{icon}</div>
        })
    }

    const renderHeader = () => {
        return <div className="box__header">
            {!editHeader && <div className={`box__header--header-text ${dragHandle}`}><h3 onClick={() => { setEditHeader(true) }}>{headerText}</h3></div>}
            {editHeader && <InputConfirm defaultValue={headerText} onConfirm={onConfirm} />}
            <div className="box__header--right-menu">
                {renderCustomIcons()}
                <TagIcon onClick={() => {
                    setShowModal("tagsfilter")
                }} />
                {!hideMenu && <MenuIcon onClick={() => {
                    setMenuOpen(!menuOpen);
                }} />}
                <Modal showModal={menuOpen} setShowModal={setMenuOpen}>
                    {showModal === "movecard" && renderMoveToAnotherCard()}
                    {!moveModule && renderStandardModalContent()}
                    {/* {moveModule && renderMoveModule()} */}
                    {showModal === "tags" && <Tags
                        onClose={() => setShowModal("")}
                        initialSelectedTags={module.tags}
                        onChange={async tags => {
                            module.setTags(tags);
                            module.forceRender();
                        }} />}
                </Modal>

            </div>
        </div>
    }

    const deleteModule = () => {
        if (onDelete) {
            onDelete();
        }
    }

    // const handleMinimize = async (e) => {
    //     const module = await m.getModule(moduleId, dataContext, currentPageType, true, true);
    //     module.toggleMinimize(dataContext, currentBreakpoint);
    // }

    const onScroll = (e) => {
        setXScrollOffset(e.currentTarget.scrollLeft);
        setYScrollOffset(e.currentTarget.scrollTop);
    }

    return (
        <div className={`box${className ? " " + className : ""}`} style={{ ...style, width: width ? width + "px" : {} }} data-testid={testId}>
            <ScrollContext.Provider value={{ xScrollOffset, yScrollOffset }}>
                {!hideHeader && renderHeader()}
                {showModal === "tagsfilter" && <Modal
                    showModal={showModal === 'tagsfilter'}
                    setShowModal={setShowModal}>
                    <Tags
                        initialSelectedTags={module.tags}
                        onClose={() => setShowModal("")}
                        onChange={tags => {
                            module.setTags(tags);
                            module.forceRender();
                        }} />
                </Modal>}
                {!isMinimized && <div
                    className={`box_content`}
                    onScroll={onScroll}
                >
                    {children}
                </div>}
                {<div className="footer">{footer}</div>}
            </ScrollContext.Provider>
        </div>
    )
}

export { Box, MenuItem }
