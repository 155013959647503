import { BaseModule, IBaseModule } from "../../module/modules/BaseModule";
import { TodoBoardModule } from "../../module/modules/TodoBoardModule/TodoBoardModule";
import { GET_USER, getModulesPath, modules as m, modules } from "../../utils/constants";
import { readData } from "../../utils/Firebase";
import { BaseData } from "./BaseData";
import { DataStore } from "./DataStore";

export class Modules extends BaseData<BaseModule> {

    constructor(dataStore: DataStore) {
        super(dataStore, "modules.data", `${GET_USER(dataStore.user)}/modules`);
    }

    async init() {

    }

    getRootLayout = async (parentModule: BaseModule) => {
        const rootModule = await this.dataStore.data.modules.getModule("LayoutStartPoint", this.dataStore, parentModule);
        return rootModule;
    }

    getTodoBoardModule = async (parentModule: BaseModule): Promise<TodoBoardModule> => {
        const todoBoardModule: TodoBoardModule = await this.dataStore.data.modules.getModule("todoBoardModule", this.dataStore, parentModule) as TodoBoardModule;
        return todoBoardModule;
    }

    addModule = (module: BaseModule, broadcast: boolean = true, updateFirebase: boolean = true) => {
        this.set(module, broadcast, updateFirebase);
    }

    getModule = async (moduleId: string, dataStore: DataStore, parentModule: BaseModule, broadcast: boolean = false): Promise<BaseModule> => {
        // console.log(moduleId);
        if (moduleId in this.data) {
            return this.data[moduleId]
        }
        else if (dataStore.user) {
            const baseModuleObject: IBaseModule = await readData(getModulesPath(dataStore.user, moduleId))
            if (baseModuleObject) {
                const module: BaseModule = await this.dataStore.appUtils.getModuleType(baseModuleObject, parentModule, dataStore);
                module.user = dataStore.user;
                module.parentModuleId = (baseModuleObject?.parentModule as unknown as string);
                this.set(module, broadcast);
                // if (setModules) {
                //     refresh();
                // }
                return module;
            }
        }
    }

    getModuleByName = (name: string): BaseModule => {
        return this.getAsArray().find((module: BaseModule) => module.name === name);
    }

    getAllModules = async (): Promise<BaseModule[]> => {
        const moduleData: { [key: string]: IBaseModule } = await readData(`${GET_USER(this.dataStore.user)}/modules`) as { [key: string]: IBaseModule };
        const baseModules = [];
        if (moduleData) {
            const modules = Object.values(moduleData);
            for (const module of modules) {
                if (module.id in this.data) {
                    baseModules.push(this.data[module.id]);
                }
                else {
                    const baseModule = await this.dataStore.appUtils.getModuleType(module, null, this.dataStore);
                    this.dataStore.data.modules.set(baseModule);
                    baseModules.push(baseModule);
                }
            }
            // baseModules.forEach((module: BaseModule) => {
            //     module.parentModule = baseModules.find((m: BaseModule) => {
            //         if(module.parentModule instanceof BaseModule){
            //             return m.id === module.parentModule.id;
            //         }
            //         else{
            //             return m.id === module.parentModule;
            //         }
            //     });
            // })
        }
        return baseModules;
        // const modulePromises = asyncMap(Object.values(moduleData), async (m: IBaseModule) => {
        //     if (moduleData) {
        //         const modulePromises: Promise<BaseModule>[] = Object.values(moduleData).map((m: IBaseModule): Promise<BaseModule> => {
        //             return this.dataStore.appUtils.getModuleType(m, null, this.dataStore);
        //         })
        //         return modulePromises;
        //         // Promise.all(Object.values(modulePromises)).then((mods: BaseModule[]) => {
        //         //     return mods;
        //         // })
        //     }
        // })
        // return modulePromises;
        // const module: BaseModule = await this.dataStore.appUtils.getModuleType(baseModuleObject, parentModule, dataStore);
    }

    getModuleDataPromises = (moduleIds: string[], dataStore: DataStore): Promise<IBaseModule>[] => {
        return moduleIds.map(moduleId => {
            return readData(getModulesPath(dataStore.user, moduleId))
        });
    }

    getModulePromises = (moduleIds: string[], dataStore: DataStore, parentModule: BaseModule): Promise<BaseModule>[] => {
        return moduleIds.map(moduleId => this.getModule(moduleId, dataStore, parentModule));
    }

    getModules = async (moduleIds: string[], dataStore: DataStore, parentModule: BaseModule): Promise<BaseModule[]> => {
        const modules = [];
        for (const moduleId of moduleIds) {
            const module = await this.getModule(moduleId, dataStore, parentModule);
            if (module) {
                modules.push(module);
            }
        }
        // setModules && this.refresh();
        return modules;
    }

    tofirebaseData(data: BaseModule) {
        return null
    }
    fromFirebaseData(): BaseModule {
        return null
    }

}