import { DataStore } from "../app/DataStore/DataStore"
import { createStartKit } from "../login/Signup"
import { GET_USER } from "../utils/constants"
import { readData, deleteData, writeData } from "../utils/Firebase"

export const resetFirebase = async (userId: string, userName: string, deleteFirebaseUser: boolean, updateFirebase, dataStore: DataStore) => {
    if (deleteFirebaseUser) {
        deleteData(`${GET_USER(userId)}`)
    }
    createStartKit(userId, userName, updateFirebase, dataStore)
    // const modules = await readData(`${GET_USER(user)}/modules`)
    // const savedModules = [
    //     modules["LayoutStartPoint"],
    //     modules["activityTableModule"],
    //     modules["calendarModule"],
    //     modules["todoBoardModule"],
    //     modules["upcomingEventsModule"],
    // ].reduce((acc, module) => {
    //     return { ...acc, [module.id]: module }
    // }, {})


    // await writeData(`${GET_USER(user)}/modules/`, savedModules)
    // deleteData(`${GET_USER(user)}/modules/calendarModule/calendar_dates/`)
}