import * as React from 'react'
import { useEffect, useState } from 'react'
import { getMonth, getYear, eachDayOfInterval, startOfToday, startOfMonth, lastDayOfMonth, subMonths, addMonths, subYears, addYears } from 'date-fns'
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { writeData } from '../../utils/Firebase';
import { CalendarMonth } from './CalendarMonth'
import "./Calendar.scss";
import { getMonthName } from '../../utils/constants';
import { IconTypes } from '../string_icons/StringIcons';
import { IHolidays } from './CalendarContext';
import { Row } from '../layout/row/Row';
import { AppContext } from '../../app/AppContext';
import { Activity } from '../../app/DataStore/ItemTypes/Activity';
import { CalendarData } from './CalendarData';
import { convertDateObjectToString } from '../../utils/TimeUtils';
import { CalendarModule } from '../../module/modules/CalendarModule/CalendarModule';

export interface ILog {
    id: string;
    created: number;
    title?: string;
    content: string | JSX.Element;

}

export interface ICalendarEvent {
    created: number;
    title?: string;
    content: string | JSX.Element;
    date?: string;
    finished: boolean;
    icons?: IconTypes[];
    type: string;
    extra?: any;
}

export interface ICalendarDate {
    timeReport?: string;
    purchases?: { merchandiseId: string, time: number }[];
    logs: ILog[];
    icons: IconTypes[];
}

export interface IDate {
    date: string;
    data: ICalendarDate;
    schedule?: Activity[];
}

export interface ICalendar {
    id: string;
    name: string;
    dates: any
}

export const getDefaultCalendarData = () => ({ notes: [], events: [], icons: [] })

const Calendar = ({ startDate = startOfToday(), calendarModule }: {startDate?, calendarModule: CalendarModule}) => {
    const { user } = React.useContext(AppContext);
    const [data, setData] = useState<CalendarData[]>();
    const [today, setToday] = useState(startDate)
    const [thisMonthsDates, setDisplayedDates] = useState<Array<Date>>([])
    // const [selectedDate, setSelectedDate] = useState("");
    // const [activeIcons, setActiveIcons] = useState<string[]>([]);
    // const [weatherForecast, setWeatherForecast] = useState({})
    // // const [holidays, setHolidays] = useState<IHoliday[]>()
    // const [showTidRapport, setShowTidRapport] = useState(false);

    useEffect(() => {
        const init = async () => {
            // await testGetWeatherForecast();
            // const getData = async () => {
            //     const calendarDatesData = await getCalendarDatesData(user);
            //     setData(calendarDatesData);
            // }

            // await getData();
            const generatedDates = eachDayOfInterval({
                start: startOfMonth(today),
                end: lastDayOfMonth(today)
            })
            
            setDisplayedDates([...generatedDates]);

            // generatedDates.map(date => {
            //     calendarModule.dataStore.data.calendarDates.getDates(convertDateObjectToString(startOfMonth(today)), convertDateObjectToString(startOfMonth(today)), true, false).then((data) => {
            //         console.log(data);
            //     })
            // })
        }

        init();
    }, [today, user])
    const renderMonthArrows = () => <Row className="calendar-arrows margin-bottom">
        <ArrowBack className="arrow-back" onClick={() => {
            const newMonth = subMonths(today, 1).getMonth();
            if (newMonth === 11) {
                const newYear = subYears(today, 1).getFullYear();
                //@ts-ignore
                today.setYear(newYear);
            }
            today.setMonth(newMonth);
            const copiedDate = new Date(today.getTime());
            setToday(copiedDate);
        }} />
        <ArrowForward className="arrow-forward" onClick={() => {
            const newMonth = addMonths(today, 1).getMonth();
            if (newMonth === 0) {
                const newYear = addYears(today, 1).getFullYear();
                //@ts-ignore
                today.setYear(newYear);
            }
            today.setMonth(newMonth);
            const copiedDate = new Date(today.getTime());
            setToday(copiedDate);
        }} />
    </Row>

    const renderMonthAndYear = () => <div className="calendar-header margin-bottom">{getMonthName(getMonth(today))} - {getYear(today)}</div>

    const renderCalendar = () => {
        return <div data-testid="calendar" className="calendar">
            {/* {data && <CalendarContext.Provider value={{ data, setData, setDate, selectedDate, setSelectedDate, activeIcons, setActiveIcons, weatherForecast }}>
                {<Row className="center-content">{renderMonthAndYear()}{renderMonthArrows()}</Row>}
                {<CalendarMonth displayedDates={thisMonthsDates} today={today} />}
            </CalendarContext.Provider>} */}
            <Row className="center-content">{renderMonthAndYear()}{renderMonthArrows()}</Row>
            <CalendarMonth displayedDates={thisMonthsDates} today={today} />
        </div>
    }

    return (
        <>
            {renderCalendar()}
        </>
    )
}

export { Calendar }
