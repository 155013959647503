import * as React from 'react'
import { useState } from 'react'
import { modules } from '../../utils/constants'
import "./ModuleCreator.scss";
import { CardIcon } from '../../icons/CardIcon';
import Modal from '../../components/modal/Modal';
import { Column } from '../../components/layout/column/Column';
import { CloseIcon } from '../../icons/CloseIcon';
import { BoardModule } from '../modules/BoardModule/BoardModule';
import { CardModule } from '../modules/CardModule/CardModule';
import { ScheduledActivityCardModule } from '../modules/ScheduledActivityCardModule/ScheduledActivityCardModule';

interface IOption {
    name: string,
    title: string,
    creationDialog: JSX.Element,
    testId: string
}

interface IModuleCreator {
    user: string;
    onCreate: (newModuleName: string, moduleType: string, params?: any) => void;
    dataTestid?: string;
    horisontal?: boolean
    closeIconTestid?: string;
}

const ModuleCreator = (props: IModuleCreator) => {
    const { horisontal, closeIconTestid } = props
    const [modalContent, setModalContent] = React.useState();

    const columnStyles = { alignItems: 'flex-start' }

    const options: IOption[] = [
        {
            name: modules.CARD,
            creationDialog: CardModule.getCreationDialog(
                async (
                    newModuleName: string,
                    hasCheckbox: boolean
                ) => {
                    setModalContent(undefined)
                    props.onCreate(newModuleName, modules.CARD, { hasCheckbox });
                }
            ),
            testId: 'create-card',
            title: 'Skapa nytt kort'
        },
        {
            name: modules.SCHEDULED_ACTIVITY_CARD,
            creationDialog: ScheduledActivityCardModule.getCreationDialog(
                async (newModuleName: string) => {
                    setModalContent(undefined)
                    props.onCreate(newModuleName, modules.SCHEDULED_ACTIVITY_CARD);
                }
            ),
            testId: 'create-scheduled',
            title: 'Skapa en ny uppgift'
        },
        {
            name: modules.BOARDS,
            creationDialog: BoardModule.getCreationDialog(
                async (newModuleName: string, createCardWithCheckbox: boolean) => {
                    setModalContent(undefined)
                    props.onCreate(newModuleName, modules.BOARDS, createCardWithCheckbox ? { createCardWithCheckbox } : undefined);
                }
            ),
            testId: 'create-board',
            title: 'Skapa en ny lista'
        },
        // this.moduleOptions?.length > 0 ? this.moduleOptions : []
    ]

    return <div className="module-creator" style={{ display: 'flex', flexDirection: horisontal ? 'row' : 'column' }}>
        {options && options.map((option: IOption) => {

            return <React.Fragment key={option.name}>
                <Column
                    dataTestid={option.testId}
                    style={columnStyles}
                    className="margin-right"
                    onClick={() => {
                        //@ts-ignore
                        setModalContent(option.creationDialog);
                    }}
                >
                    <CardIcon />
                    {option.title}
                </Column>
            </React.Fragment>
        })}


        {modalContent && <Modal showModal={!!modalContent} setShowModal={setModalContent}>
            <CloseIcon
                onClick={() => { setModalContent(undefined) }}
                dataTestid={closeIconTestid} />
            {modalContent}
        </Modal>}

    </div>
}

export { ModuleCreator }
