import * as React from 'react'
import { BaseModule, IBaseModule } from '../BaseModule';
import "../CardModule/CardModule";
import { DataStore } from '../../../app/DataStore/DataStore';
import { CardModule, ICardModuleProps } from '../CardModule/CardModule';
import { modules } from '../../../utils/constants';

export class CardModuleWare extends CardModule implements ICardModuleProps {

    constructor(
        baseProps: IBaseModule,
        cardModuleProps: ICardModuleProps,
        dataStore: DataStore
    ) {
        super(baseProps, cardModuleProps, dataStore);
    }

    static createModule = (dataStore: DataStore, baseModuleProps?: Partial<IBaseModule>, cardModuleProps?: Partial<ICardModuleProps>): CardModule => {
        const newCardModule = new CardModuleWare(
            BaseModule.getDefaultBaseModuleProps(
                baseModuleProps?.name || "",
                modules.CARD_WARE,
                dataStore.user,
                baseModuleProps
            ),
            cardModuleProps,
            dataStore
        )

        return newCardModule;
    }

}