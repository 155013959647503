import * as React from "react"
import { TAppData } from "../app/AppContext"
import { TData } from "../app/DataContextInterface"
import { removeModule, removeModuleFromRootLayout } from "../app/utils"
import { ScheduledActivity } from "../components/activity_generator/ScheduledActivity"
import { CalendarData } from "../components/calendar/CalendarData"
import { Activity } from "../app/DataStore/ItemTypes/Activity"
import { ActivityStatus, breakpointTypes, getModulesPath, modules } from "../utils/constants"
import { getToday, addDaysStringDate } from "../utils/TimeUtils"
import { Layout } from "./Layout"
import { BaseModule, IBaseModule } from "./modules/BaseModule"
import { BoardModule, IBoardModuleProps } from "./modules/BoardModule/BoardModule"
import { CalendarEventsModule, ICalendarEventsModule } from "./modules/CalendarEventsModule/CalendarEventsModule"
import { ICardModuleProps, CardModule } from "./modules/CardModule/CardModule"
import { GridItem } from "./modules/ReactGridModule/GridItem"
import { IReactGridModuleProps, ReactGridModule } from "./modules/ReactGridModule/ReactGridModule"
import { TodoBoardModule } from "./modules/TodoBoardModule/TodoBoardModule"
import { ActivityTableModule, IActivityTableModuleProps } from './modules/ActivityTableModule/ActivityTableModule';
import { generateId } from "../utils/Utils"
import { Diary } from "./modules/CalendarEventsModule/Diary"
import { ModuleCreator } from "./module_creator/ModuleCreator"

export const getModuleCreator = (
    user: string,
    onCreate: (newModuleName: string, newModuleType: string) => void
): JSX.Element => <ModuleCreator
        horisontal
        closeIconTestid="subbar__close-icon"
        user={user}
        dataTestid={''}
        onCreate={onCreate}
    />

export const createScheduledActivityCardModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    // let module;
    // // const childModules = await getModuleChildren(baseProps, appContext, dataContext)
    // const scheduledActivity = await dataContext.scheduledActivities.getScheduledActivity(baseProps.id)

    // const onCardClick = (module) => module.toggleModal(<>
    //     <Column alignLeft>
    //         <div>test</div>
    //         {/* <div>parent: {module.parentModule?.name}</div>
    //     <div>user: {module.user}</div>
    //     <div>id: {module.id}</div> */}
    //     </Column>
    //     <BoxedModule
    //         testId={`card-container`}
    //         module={module}
    //         moduleName={module.name}
    //         onDelete={() => {
    //             removeModule(module);
    //         }}
    //         dragHandle={''}
    //         onHeaderNameUpdate={(name: string) => {

    //         }}
    //         content={<div>
    //             {module.containerContent()}
    //         </div>}
    //     />
    // </>)

    // const cardModuleProps: ICardModuleProps = {
    //     // onCardClick,
    //     // containerOnDragStop: (baseModule: BaseModule) => { baseModule.updateFirebase(); },
    //     // containerOnResizeStop: (self: BaseModule) => {
    //     //     self.updateFirebase();
    //     // },
    //     checkbox: (baseProps as any).checkbox,
    //     checkboxChecked: (baseProps as any).checkboxChecked,
    //     modulePicker: await renderModulePicker(baseProps.id, dataContext, appContext),
    //     moduleCreator: getModuleCreator(baseProps.user, (newModuleName: string, newModuleType: string) => {
    //         // module.addChildModule(newModule, this);
    //     }),
    //     // onCheckboxChange: async (module: CardModule) => {
    //     //     module.updateFirebase();
    //     //     if (module instanceof ScheduledActivityCardModule) {
    //     //         const newScheduledActivity: ScheduledActivity = module.scheduledActivity.finish();
    //     //         dataContext.scheduledActivities.updateScheduledActivity(module.scheduledActivity, dataContext);
    //     //         module.scheduledActivity.updateFirebase(appContext.user);
    //     //         if (newScheduledActivity) {
    //     //             const newModule: ScheduledActivityCardModule = await createScheduledActivityCardModule(
    //     //                 // {
    //     //                 //     user: appContext.user,
    //     //                 //     id: newScheduledActivity.id,
    //     //                 //     parentModule: module,
    //     //                 //     type: modules.SCHEDULED_ACTIVITY_CARD,
    //     //                 //     name: module.name,
    //     //                 //     onDelete: () => { }
    //     //                 // },
    //     //                 BaseModule.getDefaultBaseModuleProps(module.name, modules.SCHEDULED_ACTIVITY_CARD, { user: appContext.user, id: generateId(), parentModule: module, type: modules.SCHEDULED_ACTIVITY_CARD, onDelete: () => { } }),
    //     //                 appContext,
    //     //                 dataContext
    //     //             )
    //     //             newModule.scheduledActivity = newScheduledActivity;
    //     //             dataContext.modules.addModule(newModule, true);
    //     //             dataContext.scheduledActivities.setScheduledActivity(newScheduledActivity);
    //     //             dataContext.calendarData.addScheduleToDate(newScheduledActivity, newScheduledActivity.getStartTimeDate(), dataContext, true, true);

    //     //             newModule.updateFirebase();
    //     //             newScheduledActivity.updateFirebase(appContext.user);
    //     //         }

    //     //     }
    //     //     const todoBoardModule = await getTodoModule(dataContext, appContext);
    //     //     todoBoardModule.hideCheckedModules()
    //     // }
    // }
    // const scheduledActivityCardModuleProps: IScheduledActivityCardModuleProps = {
    //     scheduledActivity,
    //     onUpdateScheduledActivity: async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string) => {
    //         console.log(updatedScheduledActivity.name, updatedScheduledActivity.scheduledActivity.getStartTimeDate());
    //         const newDate = updatedScheduledActivity.scheduledActivity.getStartTimeDate();
    //         dataContext.calendarData.updateScheduledActivity(updatedScheduledActivity.scheduledActivity, oldDate, newDate, dataContext);
    //         dataContext.scheduledActivities.updateScheduledActivity(updatedScheduledActivity.scheduledActivity, dataContext);
    //         updatedScheduledActivity.scheduledActivity.updateFirebase(appContext.user);
    //         updatedScheduledActivity.updateFirebase();

    //         const calendarEventsModule = await getCalendarEventsModule(dataContext, appContext);
    //         calendarEventsModule.onScheduledActivityUpdate(updatedScheduledActivity, oldDate);
    //     }
    // }

    // module = new ScheduledActivityCardModule(
    //     {
    //         ...baseProps,
    //         onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => {
    //             removedModule.parentModule.layout.updateFirebaseData(appContext.user, removedModule.parentModule.id, appContext.currentBreakpoint);
    //             removedModule.deleteFromFirebase();
    //         },
    //         onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule) => {
    //             createdModule.updateFirebase();
    //             yourSelf.updateFirebase();
    //         },
    //     },
    //     cardModuleProps,
    //     scheduledActivityCardModuleProps,
    // )
    return module;
}

export const createCalendarEventsModule = async (baseProps: IBaseModule, appContext: TAppData, dataContext: TData) => {
    // let module: CalendarEventsModule;
    // const startDate = getToday()
    // const endDate = addDaysStringDate(startDate, 10)
    // const dates = await dataContext.calendarData.getDates(startDate, endDate, true, false, dataContext);
    // const scheduledActivities: ScheduledActivity[] = await dataContext.calendarData.getScheduledActivitiesInTimespan(startDate, endDate, dataContext);
    // const scheduledActivityCardModules: ScheduledActivityCardModule[] = await dataContext.modules.getModules(scheduledActivities.map(s => s.id), dataContext, appContext, false) as ScheduledActivityCardModule[];
    // const calendarEventsProps: ICalendarEventsModule = {
    //     dates,
    //     startDate,
    //     endDate,
    //     activities: dataContext.activities.activities,
    //     updateCalendarData: (calendarData: CalendarData) => {
    //         calendarData.updateFirebase(appContext.user)
    //     },
    //     onDeleteScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {

    //         dataContext.calendarData.deleteScheduledActivity(calendarData.date, scheduledActivity.id, dataContext);
    //         dataContext.scheduledActivities.deleteScheduledActivity(scheduledActivity.scheduledActivity);
    //         scheduledActivity.scheduledActivity.deleteFirebase(appContext.user);

    //         if (scheduledActivity.parentModule instanceof CalendarEventsModule) {
    //             scheduledActivity.parentModule.childModules = scheduledActivity.parentModule.childModules.filter(m => m.id !== scheduledActivity.id);
    //         }

    //         calendarData.updateFirebase(appContext.user);

    //     },
    //     onTemporaryScheduledActivityCreated: (newTemporaryScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {
    //         // newTemporaryScheduledActivity.updateFirebase();
    //         // newTemporaryScheduledActivity.scheduledActivity.updateFirebase(appContext.user);
    //         // calendarData.updateFirebase(appContext.user);
    //     },
    //     onScheduledActivityUpdate: async (updatedScheduledActivity: ScheduledActivityCardModule, oldDate?: string,) => {
    //         module.updateScheduledActivity(updatedScheduledActivity, oldDate);
    //         updatedScheduledActivity.closeModal();

    //     },
    //     onScheduledActivityClick: (activity: Activity) => { console.log("Scheduled Activity Click", activity) },
    //     onFinishScheduledActivity: async (scheduledActivity: ScheduledActivityCardModule) => {
    //         dataContext.scheduledActivities.updateScheduledActivity(scheduledActivity.scheduledActivity, dataContext);
    //         scheduledActivity.scheduledActivity.updateFirebase(appContext.user);
    //         // if (newScheduledActivity) {
    //         //     const calendarData: CalendarData = await dataContext.calendarData.getCalendarDate(newScheduledActivity.scheduledActivity.getStartTimeDate(), true, true, dataContext);
    //         //     calendarData.schedule.push(newScheduledActivity.scheduledActivity);
    //         //     newScheduledActivity.scheduledActivity.addScheduledActivity(dataContext, calendarData, appContext.user);
    //         // }
    //     },
    //     diary: <Diary />
    // }

    // module = new CalendarEventsModule(
    //     {
    //         newChildModule: async (params) => {
    //             const newModule = await createScheduledActivityCardModule(
    //                 // { user: appContext.user, id: generateId(), parentModule: module, type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { }
    //                 BaseModule.getDefaultBaseModuleProps(params.name, modules.SCHEDULED_ACTIVITY_CARD, { user: appContext.user, id: generateId(), parentModule: module, type: modules.SCHEDULED_ACTIVITY_CARD, name: params.name, onDelete: () => { } }),
    //                 appContext, dataContext)
    //             const newScheduledActivity = new ScheduledActivity({
    //                 id: newModule.id,
    //                 content: params.name,
    //                 status: ActivityStatus.NOT_STARTED,
    //                 activityId: params.activityId,
    //                 interval: params.interval,
    //                 startTime: params.startTime
    //             });
    //             newModule.scheduledActivity = newScheduledActivity;
    //             return newModule;
    //         },
    //         onCreateChildModule: (createdModule: BaseModule, yourSelf: BaseModule, params) => {
    //             dataContext.scheduledActivities.setScheduledActivity((createdModule as ScheduledActivityCardModule).scheduledActivity);
    //             dataContext.modules.addModule(createdModule, true);
    //             createdModule.updateFirebase();

    //             if (params?.date) {
    //                 dataContext.calendarData.addScheduleToDate((createdModule as ScheduledActivityCardModule).scheduledActivity, params.date, dataContext, true, true);
    //             }
    //         },
    //         onRemoveChildModule: (removedModule?: BaseModule, yourself?: BaseModule) => {
    //             removedModule.deleteFromFirebase();
    //             if (yourself instanceof CalendarEventsModule) {
    //                 // const calendarData = yourself.dates.find(c => c.schedule.includes(removedModule.id))
    //                 const calendarData = yourself.dates.find(c => c.schedule.some(m => m.id === removedModule.id));
    //                 if (calendarData) {
    //                     // calendarData.schedule = calendarData.schedule.filter(s => s !== removedModule.id);
    //                     calendarData.removeScheduledActivity((removedModule as ScheduledActivityCardModule).scheduledActivity);
    //                     calendarData.updateFirebase(appContext.user);
    //                 }
    //             }
    //         },

    //         childModules: scheduledActivityCardModules,
    //         layout: (baseProps as any).layout ? new Layout((baseProps as any).layout) : null,
    //         ...baseProps
    //     },
    //     calendarEventsProps,
    // )
    return module;
}