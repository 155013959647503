import * as React from "react";
import App from "./App";
import { IsolatedEnvironment } from "./IsolatedEnvironment";

export const AppInitiator = ({ appContextDefaults = undefined, dataContextDefaults = undefined, mockUser = "", runInIsolation = false, testModule = null, width = undefined, testButton = false }) => {
    // const { appContext, dataContext, } = useData({ appContextDefaults, dataContextDefaults, mockUser });
    if (runInIsolation) {
        return <IsolatedEnvironment testModules={[testModule]} width={width} testButton={testButton}/>
    }
    else {
        return <App /* appContext={appContext} dataContext={dataContext} */ />
    }
}