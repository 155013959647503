import * as React from 'react'
import { BoxedModule } from '../BoxedModule'
import Modal from '../../components/modal/Modal';

const moduleStyling = {
    display: 'flex',
    // backgroundColor: 'lightgreen',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
}

const BaseModuleRenderer = ({ baseModule, dragHandle, customElement }) => {
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);
    const topMenu = baseModule.renderTopMenu();
    const actionMenu = baseModule.actionMenu();
    const content = baseModule.render();
    const footer = baseModule.footer()
    const customIcons = baseModule.customIcons();
    const modalContent = baseModule?.modalContent;

    React.useEffect(() => {
        baseModule.forceRender = forceUpdate;
        // console.log(baseModule.name, "set forcerender", !!baseModule.forceRender)
        // baseModule.dataStore.data.modules.set(baseModule, true, false);
        baseModule.rerender();
    }, [])

    const renderModal = () => {
        return <Modal
            showModal={!!modalContent}
            setShowModal={() => { }}
            dataTestid={`modal${baseModule.testId ? " " + baseModule.testId : ""}`}
            onBackdropClick={() => baseModule.closeModal()}
            onEscape={() => {
                console.log("escape")
                baseModule.closeModal()
            }}
        >
            {modalContent}
        </Modal>
    }

    return (<div
        style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}
        className="base-module-renderer"
        data-testid={baseModule.dataTestid}
    >
        {baseModule.noBox && <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }} className={`no-box ${dragHandle}`}>
            {topMenu}
            {content}
        </div>}
        {customElement && <div
            className={`custom-element ${dragHandle ? " " + dragHandle : ""}`}
            style={moduleStyling}>
            {customElement(baseModule)}
        </div>
        }
        {/* <Column alignLeft>
            <div>hasRerender: {String(!!baseModule.forceRender)}</div>
            <div>hasRerender dataStore: {String(!!baseModule.dataStore.data.modules.data[baseModule.id].forceRender)}</div>
        </Column> */}
        {!baseModule.noBox && !customElement &&
            <BoxedModule
                moduleName={baseModule.name}
                module={baseModule}
                actionMenu={actionMenu}
                dragHandle={dragHandle}
                customIcons={customIcons}
                topMenu={topMenu}
                content={content}
                footer={footer}
                onMinimize={() => { }}
                onDelete={() => baseModule.onDelete && baseModule.onDelete(baseModule)}
                // lastChecked={lastChecked}
                onHeaderNameUpdate={newName => {
                    baseModule.sitemapData.name = newName;
                    baseModule.updateFirebase();
                    baseModule.refresh();
                }}
            />
        }
        {renderModal()}
    </div>
    )
}

export { BaseModuleRenderer };