import { IScheduledActivity, ScheduledActivity } from "../../../components/activity_generator/ScheduledActivity";
import { IBaseModule } from "../../../module/modules/BaseModule";
import { ScheduledActivityCardModule } from "../../../module/modules/ScheduledActivityCardModule/ScheduledActivityCardModule";
import { getDataPath, data, ActivityStatus, GET_USER } from "../../../utils/constants";
import { readData } from "../../../utils/Firebase";
import { convertTimestampToStringDate } from "../../../utils/TimeUtils";
import { generateId } from "../../../utils/Utils";
import { TData } from "../../DataContextInterface";
import { BaseData } from "../BaseData";
import { DataStore } from "../DataStore";

export class ScheduledActivities extends BaseData<ScheduledActivity> {

    constructor(dataStore: DataStore) {
        super(dataStore, "scheduledActivities.data", `${GET_USER(dataStore.user)}/data/scheduled_activity`);
    }

    async init() {
        readData(
            this.firebasePath,
        ).then((scheduledActivities: { [key: string]: IScheduledActivity }) => {
            if(scheduledActivities){
                const scheduledActivitiesObject = Object.values(scheduledActivities).reduce((previous, current) => {
                    const newScheduledActivity = new ScheduledActivity(current);
                    previous[newScheduledActivity.id] = newScheduledActivity;
                    return previous;
                }, {});
                this.data = scheduledActivitiesObject;
            }
        })
    }

    createScheduledActivityCardModule(
        baseModuleProps?: Partial<IBaseModule>,
        scheduledActivityProps?: Partial<ScheduledActivity>
    ): void {
        const scheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createScheduledActivityCardModule(
            this.dataStore,
            baseModuleProps,
            scheduledActivityProps
        );

        if (scheduledActivityCardModule.scheduledActivity.startTime) {
            this.dataStore.data.calendarDates.addScheduleToDate(scheduledActivityCardModule.scheduledActivity, scheduledActivityCardModule.scheduledActivity.getStartTimeDate());
        }

        this.dataStore.data.modules.set(scheduledActivityCardModule, true, true);
        this.dataStore.data.scheduledActivities.set(scheduledActivityCardModule.scheduledActivity, true, true);
    }

    getScheduledActivity = async(id: string): Promise<ScheduledActivity> => {
        if(id in this.data){
            return this.data[id];
        }
        else{
            const scheduledActivityObject: IScheduledActivity = await readData(`${this.firebasePath}/${id}`);
            if(!scheduledActivityObject){
                return null;
            }
            const scheduledActivity: ScheduledActivity = new ScheduledActivity(scheduledActivityObject);
            return scheduledActivity;
        }
    }

    async setStartTime(scheduledActivity: ScheduledActivity, newTime: number, oldDate: string, broadcast: boolean = true, updateFirebase: boolean = true) {
        scheduledActivity.startTime = newTime;
        const newDate = scheduledActivity.getStartTimeDate();
        this.dataStore.data.scheduledActivities.set(scheduledActivity,broadcast, updateFirebase);
        
        if(oldDate && oldDate !== newDate){
            this.dataStore.data.calendarDates.removeScheduleFromDate(scheduledActivity, oldDate);
        }
        if(newDate){
            this.dataStore.data.calendarDates.addScheduleToDate(scheduledActivity, newDate);
        }
    }

    delete(item: ScheduledActivity, broadcast: boolean = true, updateFirebase: boolean = true) {
        this.dataStore.data.calendarDates.removeScheduleFromDate(item, item.getStartTimeDate());
        super.delete(item, broadcast, updateFirebase);
    }

    tofirebaseData(data: ScheduledActivity) {
        return null
    }
    fromFirebaseData(): ScheduledActivity {
        return null
    }
    

}