import { ShoppingListModule } from './../../../module/modules/ShoppingListModule/ShoppingListModule';
import { TAppData } from "../../../app/AppContext";
import { TData } from "../../../app/DataContextInterface";
import { createStartKitNoModules } from "../../../login/Signup";
import { BoardModule } from "../../../module/modules/BoardModule/BoardModule";
import { CardModule } from "../../../module/modules/CardModule/CardModule";
import { TodoBoardModule } from "../../../module/modules/TodoBoardModule/TodoBoardModule";
import { GET_USER } from "../../../utils/constants";
import { deleteData } from "../../../utils/Firebase";
import { ActivityTableModule } from '../../../module/modules/ActivityTableModule/ActivityTableModule';
import { CalendarEventsModule } from '../../../module/modules/CalendarEventsModule/CalendarEventsModule';
import { DataStore } from "../../../app/DataStore/DataStore";
import { BaseModule } from "../../../module/modules/BaseModule";
import { Layout } from "../../../module/Layout";
import { IGrid } from "../../../module/modules/ReactGridModule/GridItem";
import { CalendarModule } from "../../../module/modules/CalendarModule/CalendarModule";

export const resetData = async (dataStore: DataStore) => {
    await deleteData(`${GET_USER(dataStore.user)}`);
    createStartKitNoModules(dataStore.user, "TestName", true, dataStore);
}

export const cardWithListInide = async (dataStore: DataStore, parentModule: BaseModule) => {
    const cardModule = CardModule.createModule(dataStore, { id: "cardModule", name: "CardModule", user: dataStore.user, parentModule: null })
    const boardModule = BoardModule.createModule(dataStore, { id: "boardModule", name: "BoardModule", user: dataStore.user, parentModule: null })
    cardModule.addChildModule(boardModule, cardModule);
    createAndAddModuleToRootLayout(dataStore, cardModule, parentModule);
}

export const createAndAddModuleToRootLayout = async (dataStore: DataStore, module: BaseModule, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">, params?: any) => {
    const layout = customLayout ? Layout.createILayouts(module.id,
        { i: module.id, ...customLayout }
    ) : undefined;
    const rootLayout = await dataStore.data.modules.getRootLayout(parentModule)
    rootLayout.addChildModule(module, rootLayout, true, layout);
}

export const createEmptyCard = async (dataStore: DataStore, parentModule: BaseModule,  customLayout?: Omit<IGrid, "i">) => {
    const cardModule = CardModule.createModule(
        dataStore,
        { id: "cardModule", name: "CardModule", user: dataStore.user, parentModule: null },
    )
    createAndAddModuleToRootLayout(dataStore, cardModule, parentModule, customLayout);
}

export const createTodoBoardModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">): Promise<TodoBoardModule> => {
    const todoBoardModule: TodoBoardModule = TodoBoardModule.createTodoBoardModule(dataStore, { id: "todoBoardModule", name: "TodoBoardModule", user: dataStore.user, parentModule: null })
    createAndAddModuleToRootLayout(dataStore, todoBoardModule, parentModule, customLayout);
    return todoBoardModule;
}

export const createBoardModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">) => {
    const boardModule: BoardModule = BoardModule.createModule(dataStore, { id: "boardModule", name: "BoardModule", user: dataStore.user, parentModule: null })
    createAndAddModuleToRootLayout(dataStore, boardModule, parentModule, customLayout);
}

export const createShoppingListModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">) => {
    const todoBoardModule: ShoppingListModule = ShoppingListModule.createShoppingListModule(dataStore, { id: "shoppingListModule", name: "ShoppingListModule", user: dataStore.user, parentModule: null })
    createAndAddModuleToRootLayout(dataStore, todoBoardModule, parentModule, customLayout);
}

export const createActivityModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">) => {
    const activityTableModule = ActivityTableModule.createModule(dataStore, { id: "activityTableModule", name: "ActivityTableModule", user: dataStore.user, parentModule: null })
    createAndAddModuleToRootLayout(
        dataStore,
        activityTableModule,
        parentModule,
        customLayout
    )
}

export const createCalendarModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">) => {
    const calendarModule = CalendarModule.createModule(dataStore)
    createAndAddModuleToRootLayout(
        dataStore,
        calendarModule,
        parentModule,
        customLayout
    )
}

export const createCalendarEventsModule = async (dataStore: DataStore, parentModule: BaseModule, customLayout?: Omit<IGrid, "i">) => {
    const calendarEventsModule = CalendarEventsModule.createModule(
        dataStore,
        { id: "calendarEventsModule", name: "CalendarEventsModule", user: dataStore.user, parentModule: null },
    )
    createAndAddModuleToRootLayout(
        dataStore,
        calendarEventsModule,
        parentModule,
        customLayout
    );
}

export const createHighlightedCard = async (dataStore: DataStore, parentModule: BaseModule) => {
    const cardModule = CardModule.createModule(dataStore, { id: "cardModule", name: "CardModule", user: dataStore.user, parentModule: null })
    createAndAddModuleToRootLayout(dataStore, cardModule, parentModule/* dataContext, appContext, cardModule */);
}

const addModuleTableModule = async (dataContext: TData, appContext: TAppData) => {
    // const moduleTableModule: ModuleTableModule = new ModuleTableModule(
    //     BaseModule.getDefaultBaseModuleProps("ModuleTableModule", modules.MODULE_TABLE, { user: appContext.user, id: "moduleTableModule" }),
    //     async () => {
    //         const ids = await dataContext.modules.getAllModuleIds(dataContext, appContext);
    //         const promises = dataContext.modules.getModulePromises(ids, dataContext, appContext, false);
    //         promises.forEach(p => {
    //             p.then(m => {
    //                 moduleTableModule.childModules.push(m);
    //                 moduleTableModule?.forceRender && moduleTableModule.forceRender();
    //             })
    //         })

    //         Promise.all(promises).then(() => {
    //             moduleTableModule.childModules.forEach(m => {
    //                 const parentModule = moduleTableModule.childModules.find(c => c.id === m.parentModuleId);
    //                 // console.log(m.name, parentModule.name);
    //                 if (m.parentModuleId) {
    //                     m.parentModule = parentModule;
    //                     moduleTableModule.forceRender();
    //                 }
    //             })
    //             const duplicates = moduleTableModule.childModules.reduce((acc, module) => {
    //                 const count = moduleTableModule.childModules.filter(m => m.name === module.name).length;
    //                 if (count > 1 && !acc.find(m => m.name === module.name)) {
    //                     acc.push({ name: module.name, count });
    //                 }
    //                 return acc;
    //             }, []);
    //             duplicates.sort((a, b) => b.count - a.count);
    //             // duplicates.forEach(duplicate => {
    //             //     console.log(`Duplicate module: ${duplicate.name}, Count: ${duplicate.count}`);
    //             // });
    //             // console.log('Duplicate modules:', duplicates);
    //         })

    //     }
    // )

    // await addModuleToRootLayout(
    //     dataContext,
    //     appContext,
    //     moduleTableModule,
    //     false
    // );
}