import * as React from 'react'
import { Input } from '../../input/Input';
import { Column } from '../../layout/column/Column';
import { BaseModule } from '../../../module/modules/BaseModule';
import "./ModulePicker.scss"
import { MarginBottom } from '../../layout/margin/MarginBottom';
import { readData, writeData } from '../../../utils/Firebase';
import { Button } from '../../button/Button';
import { DataStore } from '../../../app/DataStore/DataStore';


const ModulePicker = ({
    onPick,
    customRow,
    dataStore }: {
        onPick: (module: BaseModule) => void,
        customRow?: (module: BaseModule) => JSX.Element
        dataStore: DataStore
    },
) => {
    const [displayedModules, setDisplayedModules] = React.useState([]);
    const [mostCommonModules, setMostCommonModules] = React.useState<{ [key: string]: number }>({});
    const [filterQuery, setFilterQuery] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentMode, setCurrentMode] = React.useState<"all" | "mostCommon">("mostCommon");

    // const modulePickerSettingPath = `${GET_USER(appContext.user)}/settings/module_picker/frequent_modules`
    // const [selectedModules, setSelectedModules] = React.useState<string[]>([]);
    React.useEffect(() => {
        // loadMostCommonModules();
        loadAllModules();
    }, [])

    const loadAllModules = () => {
        setDisplayedModules([]);
        setLoading(true);
        dataStore.data.modules.getAllModules().then(async modules => {
            // const modulePromises = modules.map((m) => {
            //     return dataStore.appUtils.getModuleType(m, null, dataStore);
            // })
            // console.log(modules);
            setLoading(false);
            setCurrentMode("all");
            setDisplayedModules(modules);
        })
        // dataContext.modules.getAllModules(dataContext, appContext, true).then(modules => {
            
        // })
    }

    const loadMostCommonModules = () => {
        setDisplayedModules([]);
        // readData(modulePickerSettingPath).then((data) => {
        //     // setMostCommonModules(data || {});
        //     // const sortedModuleKeys = data ? Object.keys(data).sort((a, b) => data[b] - data[a]) : [];
        //     // dataContext.modules.getModules(sortedModuleKeys, dataContext, appContext, true).then(modules => {
        //     //     console.log(modules);
        //     //     const mods = modules.filter(mod => sortedModuleKeys.includes(mod.id));
        //     //     setCurrentMode("mostCommon");
        //     //     setDisplayedModules(mods);
        //     // })
        // })

    }

    const handlePick = (module: BaseModule) => {
        if (module.id in mostCommonModules) {
            mostCommonModules[module.id] += 1;
        }
        else {
            mostCommonModules[module.id] = 1;
        }
        // writeData(modulePickerSettingPath, mostCommonModules);
        onPick(module);
    }

    return (
        <div className="module-picker">
            <div>ModulePicker</div>
            <Input
                debounce={500}
                onChange={setFilterQuery} />
            <MarginBottom />
            <Column alignLeft>
                {/* {selectedModule && selectedModule.name} */}
                <Button label={currentMode === "mostCommon" ? 'Ladda in alla moduler' : "Ladda in de vanligaste modulerna"} onClick={() => {
                    currentMode === "mostCommon" ? loadAllModules() : loadMostCommonModules();
                }} />
                {loading && <>
                    <MarginBottom />
                    <div>Laddar...</div>
                    <MarginBottom />
                </>}
                {/* !selectedModule && */ displayedModules.filter(m => {
                    if (!filterQuery) return true
                    if (filterQuery) return m?.name?.toLowerCase()?.includes(filterQuery?.toLowerCase())
                }).map((mod, key) => {
                    return <div
                        className={`item-row`}
                        key={key}
                        onClick={() => handlePick(mod)}>
                        {customRow ? customRow(mod) : mod.name}
                    </div>
                })}
            </Column>
        </div>
    )
}

export default ModulePicker