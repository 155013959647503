import * as React from 'react';
import { BaseModule, IBaseModule } from "../BaseModule";
import { ActivityStatus, breakpointTypes, GridHeightsLG, GridHeightsSM, GridWidthsLG, GridWidthsSM, ILayouts, modules } from '../../../utils/constants';
import { CalendarData } from '../../../components/calendar/CalendarData';
import { Activity } from '../../../app/DataStore/ItemTypes/Activity';
import { Column } from '../../../components/layout/column/Column';
import { MarginBottom } from '../../../components/layout/margin/MarginBottom';
import { Button } from '../../../components/button/Button';
import { getNow, getToday, Millis, convertStringTimeAndDateToTimestamp, addDaysStringDate, getZeroTimestampForDate } from '../../../utils/TimeUtils';
import StringIcons, { IconTypes } from '../../../components/string_icons/StringIcons';
import { ILog } from '../../../components/calendar/Calendar';
import './Upcoming.scss'
import { ActivityPicker } from '../../../components/pickers/activity_picker/ActivityPicker';
import { generateId } from '../../../utils/Utils';
import InputConfirm from '../../../components/input_and_confirm/InputConfirm';
import { TextArea } from '../../../components/textarea/TextArea';
import { Input } from '../../../components/input/Input';
import { GridItem } from '../ReactGridModule/GridItem';
import { ScheduledActivityCardModule } from '../ScheduledActivityCardModule/ScheduledActivityCardModule';
import { Checkbox } from '../../../components/checkbox/Checkbox';
import { Row } from '../../../components/layout/row/Row';
import { MarginRight } from '../../../components/layout/margin/MarginRight';
import { ExclamationIcon } from '../../../icons/ExclamationIcon';
import { Diary } from './Diary';
import { DataStore } from '../../../app/DataStore/DataStore';

export interface ICalendarEventsModule {
    startDate: string;
    endDate: string;
    diary: JSX.Element;
}

export class CalendarEventsModule extends BaseModule implements ICalendarEventsModule {
    startDate: string;
    endDate: string;
    activitiesToSchedule: { scheduledActivityCardModule: ScheduledActivityCardModule, interval: number, date: CalendarData, startTime: string, addSchedule: boolean, alreadyExists: boolean }[] = [];
    diary: JSX.Element;
    dataStore: DataStore;
    rows: JSX.Element[] = [];

    constructor(
        props: IBaseModule,
        dataStore: DataStore
        // calendarEventsProps: Partial<ICalendarEventsModule> = {}
    ) {
        super(props, dataStore);
        this.autoPackingDimensions = { w: GridWidthsLG.QUARTER, h: GridHeightsLG.HALF };
        // Object.assign(this, calendarEventsProps);
    }

    async init() {
        this.dataStore.subscribe(
            "scheduledActivities.data",
            (data) => {
                // console.log("scheduled", data);
            }
        )

        this.dataStore.subscribe(
            "calendarDates.data",
            (data) => {
                console.log("calendar", data);
                this.getRows(this.parentModule).then(rows => {
                    this.rows = rows;
                    this.forceRender();
                });
            }
        )

        // this.dataStore.data.calendarDates.getDates(getToday(), addDaysStringDate(getToday(), 10), true, false).then(async dates => {
        //     console.log(dates);
        // })

        this.rows = await this.getRows(this.parentModule);
        this.forceRender && this.forceRender();
        // this.dataStore.data.calendarData.getDates(getToday(), addDaysStringDate(getToday(), 10), true, false).then(async dates => {
        //     // (this as CalendarEventsModule).dates = dates;
        //     const childModules = [];
        //     for (const date of dates) {
        //         for (const s of date.schedule) {
        //             const scheduledActivityCardModule = await this.dataStore.data.modules.getModule(s.id, this.dataStore);
        //             scheduledActivityCardModule.parentModule = this;
        //             childModules.push(scheduledActivityCardModule);
        //         }
        //     }
        //     this.childModules = childModules;
        //     this.forceRender();
        // })
    }

    // onDataContextUpdate = async (dataContext: TData, appContext: TAppData): Promise<void> => {
    //     if (!this.noDataContextSync && dataContext) {
    //         this.activities = dataContext.activities.activities;
    //         // const startDate = getToday()
    //         // const endDate = addDaysStringDate(startDate, 10);
    //         // this.dates = await dataContext.calendarData.getDates(startDate, endDate, true, false, dataContext);
    //         // const allModuleIds = this.dates.reduce((previous, current) => {
    //         //     current.schedule.forEach(s => {
    //         //         if (!previous.includes(s.id)) {
    //         //             previous.push(s.id);
    //         //         }
    //         //     });
    //         //     return previous;
    //         // }, [])
    //         // this.childModules = await dataContext.modules.getModules(allModuleIds, dataContext, appContext);
    //         this.forceRender();
    //     }
    // }

    renderTopMenu(): JSX.Element {
        return null;
    }

    // updateModuleFirebase() {
    //     const firebaseData: Omit<IBaseModule, "onDelete"> = { id: this.id, user: this.user, type: this.type, name: this.name }
    //     writeData(getModulesPath(this.user, this.id), firebaseData);
    // }

    getCreationDialog(): JSX.Element {
        return <div>creation dialog</div>
    }


    getDefaultGrid(): ILayouts {
        return {
            id: this.id,
            [breakpointTypes.SM]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsSM.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id),
            [breakpointTypes.LG]: new GridItem({
                i: this.id,
                x: 0,
                y: 0,
                w: GridWidthsLG.QUARTER,
                h: GridHeightsSM.HALF,
            }, this.id)
        }
    }

    deleteScheduledActivityFromDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule = calendarData?.schedule.filter(s => s.id !== scheduledActivity.id);
    }

    addScheduledActivityToDate = (calendarData: CalendarData, scheduledActivity: ScheduledActivityCardModule) => {
        calendarData.schedule?.push(scheduledActivity.scheduledActivity);
    }


    updateScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, oldDate: string) => {
        // const newDate = convertTimestampToStringDate(scheduledActivity.scheduledActivity.startTime);
        // if (newDate !== oldDate) {
        //     const oldCalendarD = this.dates.find(d => d.date === oldDate)
        //     const newCalendarD = this.dates.find(d => d.date === newDate)
        //     oldCalendarD && this.deleteScheduledActivityFromDate(oldCalendarD, scheduledActivity);
        //     this.addScheduledActivityToDate(newCalendarD, scheduledActivity);
        //     // this.onScheduledActivityUpdate(scheduledActivity, newCalendarD.date);
        // }
        // else {
        //     const oldCalendarD = this.dates.find(d => d.date === oldDate)
        //     // this.onScheduledActivityUpdate(scheduledActivity, oldCalendarD.date);
        // }
        // this.forceRender();
    }

    renderContent = (schedule: ScheduledActivityCardModule[], cd: CalendarData) => {
        // if(!schedule?.length){
        //     return null;
        // }
        schedule?.sort((c, d) => {
            const a = c.scheduledActivity;
            const b = d.scheduledActivity;
            if (a?.isFinished() && !b?.isFinished()) {
                return -1;
            }
            else if (!a?.isFinished() && b?.isFinished()) {
                return 1;
            }
            else if (a?.isFinished() && b?.isFinished()) {
                if (a.startTime && !b.startTime) {
                    return 1;
                }
                else if (!a.startTime && b.startTime) {
                    return -1;
                }
                else if (a.startTime && b.startTime) {
                    return a.startTime < b.startTime ? -1 : 1
                }
            }
            else if (!a?.isFinished() && !b?.isFinished()) {
                return a?.startTime < b?.startTime ? -1 : 1;
            }
        })
        return <>
            {
                schedule.map((scheduledActivityCardModule: ScheduledActivityCardModule, key) => {
                    if (!scheduledActivityCardModule) {
                        return null;
                    }
                    return <React.Fragment key={key}>{scheduledActivityCardModule.renderModule()}</React.Fragment>
                })
            }

            {cd.data?.logs?.length > 0 && cd.data?.logs.map((log: ILog, key) => {
                return <div onClick={() => {
                    this.toggleModal(() => <div>{log.content}</div>)
                }} key={key}>- {log.title}</div>
            })}
        </>
    }


    handleDayHeaderClick = (calendarData: CalendarData) => {
        this.toggleModal(() => <Column alignLeft>
            <h1>{calendarData.date}</h1>
            <Button label={'Lägg till en befintlig aktivitet'} onClick={() => {
                this.dataStore.data.activities.getAllActivitiesFromFirebase().then(activities => {
                    if (activities) {
                        this.toggleModal(() => <ActivityPicker activities={Object.values(activities)} onSelect={async (activity: Activity) => {
                            this.dataStore.data.scheduledActivities.createScheduledActivityCardModule(
                                { name: activity.name, parentModule: this },
                                { name: activity.name, activityId: activity.id, startTime: getZeroTimestampForDate(calendarData.date) }
                            )
                            this.closeModal();
                        }} />)
                    }
                    return null;
                })
            }} />
            <MarginBottom />
            <Button label={'Lägg till en temporär aktivitet'} onClick={() => {
                this.toggleModal(() => 
                    <InputConfirm
                        label='Namn på aktiviteten'
                        onConfirm={name => {
                            this.dataStore.data.scheduledActivities.createScheduledActivityCardModule(
                                { name, parentModule: this },
                                { name, startTime: getZeroTimestampForDate(calendarData.date) }
                            )
                            this.closeModal();
                        }
                        }
                    />)
            }} />
            <MarginBottom />
            <Button
                label="Skapa ett dagboksinlägg"
                onClick={() => {
                    let title = "";
                    let text = "";
                    this.toggleModal(() => <div>
                        <Input onChange={t => {
                            title = t;
                        }} />
                        <MarginBottom />
                        <TextArea
                            rows={10}
                            cols={50}
                            onChange={txt => {
                                if (!calendarData?.data) {
                                    calendarData.data = { logs: [], icons: [] }
                                }
                                if (!calendarData?.data?.logs) {
                                    calendarData.data.logs = [];
                                }
                                text = txt
                            }}
                        />
                        <Button label="Skapa" onClick={() => {
                            calendarData?.data?.logs?.push({ id: generateId(), content: text, created: getNow(), title });
                            this.dataStore.data.calendarDates.set(calendarData, true, true);
                            // this.updateCalendarData(calendarData)
                            this.closeModal();
                        }} />
                        <Button
                            label="Visa dagbok"
                            onClick={() => {
                                this.toggleModal(() => <div>
                                    <Diary dataStore={this.dataStore} />
                                    {/* {calendarData?.data?.logs?.map((log, key) => {
                                        return <div key={key}>
                                            <div>{log.title}</div>
                                            <div>{log.content}</div>
                                        </div>
                                    })
                                    } */}
                                </div>
                                )
                            }}
                        />
                        {calendarData?.data?.logs?.map((log, key) => {
                            return <div key={key}>
                                <div>{log.title}</div>
                                <div>{log.content}</div>
                            </div>
                        })
                        }
                    </div>
                    )
                }}
            />
        </Column>)
    }

    async getRows(parentModule: BaseModule) {
        const today = getToday();
        let i = 0;
        const t = [];
        let allExpiredActivities = [];
        const dates = await this.dataStore.data.calendarDates.getDates(getToday(), addDaysStringDate(getToday(), 10), true, false);
        for (const calendarData of dates) {
            const isToday = calendarData.date === today;
            const activitiesExpiredMoraThanHoursAgo = calendarData.schedule.filter(s => {
                if (!s) {
                    return false;
                }
                const scheduledActivityCardModule: ScheduledActivityCardModule = this.childModules.find(m => m.id === s.activityId) as ScheduledActivityCardModule;
                const timeSinceStart = scheduledActivityCardModule?.scheduledActivity?.startTime + (Millis.hour * 3);
                const now = getNow();
                const isFinished = scheduledActivityCardModule?.scheduledActivity?.isFinished()
                return timeSinceStart < now && !isFinished
            });
            allExpiredActivities = allExpiredActivities.concat(activitiesExpiredMoraThanHoursAgo);
            const icons = calendarData.data?.icons;
            const nCompletedTasks = calendarData.schedule.filter(s => (this.childModules.find(m => m.id === s.activityId) as ScheduledActivityCardModule)?.scheduledActivity?.status === ActivityStatus.DONE)?.length;
            const totalTasks = calendarData.schedule.length;
            // const schedule: ScheduledActivityCardModule[] = calendarData.schedule.map(s => this.childModules.find(m => m.id === s.id) as ScheduledActivityCardModule).filter(s => s);
            const ids = calendarData.schedule.map(s => s.id);
            if (calendarData.date === "20250114") {
                console.log(ids);
            }
            const schedule = await this.dataStore?.data?.modules?.getModules(ids, this.dataStore, this.parentModule) || [];
            t.push(
                <React.Fragment key={i}>
                    <Column className="upcoming-calendar-events-row" dataTestid="upcoming-calendar-event-row">
                        <div
                            data-testid="upcoming-calendar-event-header"
                            onClick={() => {
                                this.handleDayHeaderClick(calendarData);
                            }}
                            className={`upcoming-calendar-event-header${isToday ? " today" : ""}`}
                        >
                            <div className="margin-right">
                                {calendarData.date} {nCompletedTasks + "/" + totalTasks}
                            </div>
                            {icons?.length > 0 && icons.map((icon: IconTypes, key: number) => {
                                return <div key={key}>
                                    {StringIcons(icon as IconTypes, () => { })}
                                </div>
                            })}
                        </div>
                        {this.renderContent(schedule as unknown as ScheduledActivityCardModule[], calendarData)}
                    </Column>
                </React.Fragment>
            )
            i++;
        }
        return t;
    }

    autoGenerateButton = () => <Button label={'Generera schema'} onClick={() => {
        // this.activities.forEach(activity => {
        this.activitiesToSchedule = [];
        this.dataStore.data.activities.getAsArray().forEach((activity, i) => {
            // const dates = SchedulingService.getDatesValidForActivity(activity, this.dates);
            // const mergedDates: { date: string, startTime: number }[] = dates?.reduce((previous, current) => {
            //     current.validDates.forEach(date => {
            //         if (!previous.some(d => d.date === date)) {
            //             previous.push({ date, startTime: current.startTime || 0 });
            //         }
            //     });
            //     return previous;
            // }, []);

            // // mergedDates?.forEach(date => {
            // mergedDates?.forEach((mergedDate, j) => {
            //     // // const d = this.dates.find(d => d.date === date);
            //     // const d = this.dates.find(d => d.date === mergedDate.date);
            //     // const startTime = convertTimestampToTimepickerStringTime(mergedDate.startTime);
            //     // const newScheduledActivityCardModule: ScheduledActivityCardModule = ScheduledActivityCardModule.createModule(
            //     //     <div>placeholder för modulecreator</div>,
            //     //     { id: generateId(), name: activity.name, type: modules.SCHEDULED_ACTIVITY_CARD, user: this.user },
            //     //     undefined,
            //     //     // { parentModule: this, onRemoveChildModule: () => { } },
            //     //     { /* startTime: startTimeNumber, */
            //     //         activityId: activity.id
            //     //     }
            //     // )
            //     // const test = this.dates.find(d => d.date === mergedDate.date)/* .scheduleHasActivity(newScheduledActivityCardModule.id) */
            //     // const activityAlreadyExists = test?.scheduleHasActivity(newScheduledActivityCardModule.scheduledActivity.activityId);
            //     // this.activitiesToSchedule.push({ scheduledActivityCardModule: newScheduledActivityCardModule, interval: activity.interval, date: d, startTime: startTime, addSchedule: false, alreadyExists: activityAlreadyExists })
            // })
        })

        this.toggleModal(() => <Column alignLeft>
            {this.activitiesToSchedule
                .sort((a, b) => a.date.date < b.date.date ? -1 : 1)
                .filter(a => !a.date.scheduleHasActivity(a.scheduledActivityCardModule.id))
                .map((a: { scheduledActivityCardModule: ScheduledActivityCardModule, interval: number, date: CalendarData, startTime: string, addSchedule: boolean, alreadyExists }, key) => {
                    return <Row>
                        {a.date.date}
                        <MarginRight />
                        {a.startTime}
                        <MarginRight />
                        <Checkbox label={a.scheduledActivityCardModule.name} checked={a.addSchedule} onChange={checked => {
                            a.addSchedule = checked;
                        }} />
                        {a.alreadyExists ? <>
                            <ExclamationIcon className={undefined} /><span>Aktiviteten finns redan</span>
                        </> : null}
                    </Row>
                })}
            <Button label={'Välj valda aktiviteter'} onClick={() => {
                this.activitiesToSchedule.forEach(a => {
                    a.scheduledActivityCardModule.scheduledActivity.startTime = convertStringTimeAndDateToTimestamp(a.date.date, a.startTime);
                    if (a.addSchedule) {
                        // this.addScheduledActivity(a.scheduledActivityCardModule.name, a.scheduledActivityCardModule.scheduledActivity.activityId, a.scheduledActivityCardModule.scheduledActivity.startTime, a.interval, a.date, this.dataStore);
                    }
                })
                this.closeModal();
            }} />
        </Column>);
    }} />

    onCreateChildModule(createdModule: BaseModule, parentModule: BaseModule, params?: any) {
        // createdModule.app = new ScheduledActivityCardAppInterface((createdModule as ScheduledActivityCardModule), this.dataStore);
        // super.onCreateChildModule(createdModule, parentModule, params);
        // this.dataContext.calendarData.addScheduleToDate(
        //     (createdModule as ScheduledActivityCardModule).scheduledActivity,
        //     params.date,
        //     this.dataContext
        // )
    };

    // updateCalendarData = (calendarData: CalendarData) => {
    //     calendarData.updateFirebase(this.user);
    // }

    onScheduledActivityClick = (activity: Activity) => {
        console.log(activity);
    }
    onTemporaryScheduledActivityCreated = (newScheduledActivity: ScheduledActivityCardModule, calendarData: CalendarData) => {

    }
    onScheduledActivityUpdate = (updatedScheduledActivityCardModule: ScheduledActivityCardModule, oldDate?: string) => {
        if (updatedScheduledActivityCardModule.scheduledActivity.getStartTimeDate() !== oldDate) {
            (this as CalendarEventsModule).updateScheduledActivity(updatedScheduledActivityCardModule, oldDate);
        }
    }
    onDeleteScheduledActivity = (scheduledActivity: ScheduledActivityCardModule, calendarDate: CalendarData) => {
        (this as CalendarEventsModule).deleteScheduledActivityFromDate(
            calendarDate,
            scheduledActivity
        );
    }
    onFinishScheduledActivity = (finishedScheduledActivity: ScheduledActivityCardModule, newScheduledActivity: ScheduledActivityCardModule) => {

    }

    render(): JSX.Element {
        return <div data-testid="upcoming-events" className="upcoming-events">
            {this.autoGenerateButton()}
            {this.rows}
        </div>
    }

    static createModule = (dataStore: DataStore, baseModuleProps?: Partial<IBaseModule>) => {
        const calendarEventsModule = new CalendarEventsModule(
            BaseModule.getDefaultBaseModuleProps(
                "CalendarEvents",
                modules.CALENDAR_EVENTS,
                dataStore.user,
                baseModuleProps
            ),
            dataStore
        )
        return calendarEventsModule;
    }

}