import * as React from 'react'
import { Column } from '../layout/column/Column';
import { Row } from '../layout/row/Row';
import { weekdays } from '../../utils/constants';
import { MarginRight } from '../layout/margin/MarginRight';
import { MarginBottom } from '../layout/margin/MarginBottom';
import "./ActivityRulesSelector.scss"
import { Activity } from '../../app/DataStore/ItemTypes/Activity';
import { convertTimestampToTimepickerStringTime, getDuration } from '../../utils/TimeUtils';

const ActivityRulesSelector = ({ activity, onSelect, onRemove }: {
    activity: Activity
    // rules: { id: string, name: string, rules: Rule[] }[],
    onSelect: (id: string) => void
    onRemove: (id: string) => void;
    // onUpdateActivity: (ruleset: { id: string, rules: Rule[] }) => void
}) => {
    console.log(activity);

    const renderRemoveRuleButton = (rule) => <div onClick={(e) => {
        e.stopPropagation();
        onRemove(rule.id);
    }}><b>Ta bort</b></div>

    const renderRuleCard = (rule): any => {
        console.log(rule);

        if (rule.func === "isDateInMonth") {
            const days = rule.funcParams.day;
            return <Row>
                <b>Valda dagar i månaden: </b>
                <span>{days.join(", ")}</span>
                <MarginRight />
                {renderRemoveRuleButton(rule)}
            </Row>
        }

        if (rule.func === "isWeekday") {
            const days = rule.funcParams.day;
            return <Row>
                <b>Valda veckodagar: </b>
                <span>{days.map(day => weekdays[day]).join(", ")}</span>
                <MarginRight />
                {renderRemoveRuleButton(rule)}
            </Row>
        }

        if (rule.func === "oddEvenWeek") {
            return <Row>
                <b>{rule.func}:</b>
                <span>{rule.funcParams ? " " + Object.values(rule.funcParams) : ""}</span>
                <MarginRight />
                {renderRemoveRuleButton(rule)}
            </Row>
        }

        if(rule.func === "startTime"){
            const test = rule.funcParams.startTime
            return <Row>
                <b>{rule.func}:</b>
                <span>{test > 0 ? convertTimestampToTimepickerStringTime(test) : ""}</span>
                <MarginRight />
                {renderRemoveRuleButton(rule)}
            </Row>
        }
    }

    const renderAllRules = (rule) => {
    return <Column
        alignLeft>
        {rule && rule.ruleSet?.map((r, idx) => <div
            key={idx}
        >
            {renderRuleCard(r)}
        </div>)}
    </Column>}

    const renderRulesetPicker = () => {

    return <Column alignLeft>
        <b>Välj regelset</b>
        <MarginBottom />
        {activity.rules.map(rule =>
            <Column
                className="rule-card"
                alignLeft
                key={rule.id}
                onClick={() => {
                    onSelect(rule.id);
                }}>
                {rule.name}
                {renderAllRules(rule)}
            </Column>
        )}
    </Column>}

    const renderInterval = () => <Column
        className="rule-card"
        alignLeft
        onClick={() => {
            // onSelect(rule.id);
        }}>
        <b>Intervall</b>
        {activity.interval > 0 ? getDuration(activity.interval) : ""}
    </Column >

    // const renderStartTime = () => <Column
    //     className="rule-card"
    //     alignLeft
    //     onClick={() => {
    //         // onSelect(rule.id);
    //     }}>
    //     <b>Starttid</b>
    //     {activity.defaultStartTime}
    // </Column >

    const hasRules = activity.rules?.length > 0;
    return (
        <div>
            {hasRules && renderRulesetPicker()}
            {activity.interval > 0 && renderInterval()}
            {/* {activity.defaultStartTime && renderStartTime()} */}
        </div>
    )
}

export { ActivityRulesSelector }