// import { WeekdayEnums } from './constants';
import { addDays, differenceInCalendarDays, eachDayOfInterval, format, getDay, intervalToDuration, millisecondsToHours, millisecondsToMinutes, millisecondsToSeconds, startOfDay, subDays } from "date-fns"
import { oneDecimal, leadingZero } from './Utils';

enum WeekdayEnums {
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
    SUNDAY = "Sunday"
}

export enum TimeUnits {
    SECOND = "s",
    MINUTE = "m",
    HOUR = "h",
    DAY = "d"
}

export interface IDateObject {
    year: string;
    month: string;
    date: string;
    hours: string;
    minutes: string;
    seconds: string;
}

export const Millis = {
    year: 31557600000,
    month: 2629800000,
    week: 604800000,
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000
}

const setDateToZeroTime = (date: Date): Date => {
    return startOfDay(date);
}

export const millisToMinutesOrHours = (minutes) => {
    if (!minutes) {
        return ""
    }
    return minutes < 3600000 ? `${millisecondsToMinutes(minutes)} m` : `${millisecondsToHours(minutes)} h`;
}

export const getTimeUnit = (millis) => {
    const milliDay = 86400000;
    const milliHour = 3600000;
    const milliMinute = 60000;
    if (millis >= milliDay) {
        return TimeUnits.DAY;
    }
    else if (millis >= milliHour) {
        return TimeUnits.HOUR
    }
    else if (millis >= milliMinute) {
        return TimeUnits.MINUTE
    }
    else {
        return TimeUnits.SECOND
    }
}

export const getTimeInUnit = (millis: number, timeUnit: TimeUnits, round: boolean = true): number => {
    if (timeUnit === TimeUnits.DAY) {
        if (round) {
            return millisecondsToHours(millis) / 24
        }
        const hours = millisecondsToHours(millis);
        const days = Math.floor(hours / 24);
        return days;
    }
    else if (timeUnit === TimeUnits.HOUR) {
        if (round) {
            return millisecondsToHours(millis)
        }
        const minutes: number = millisecondsToMinutes(millis)
        return oneDecimal(minutes / 60);
    }
    else if (timeUnit === TimeUnits.MINUTE) {
        if (round) {
            return millisecondsToMinutes(millis);
        }
        const seconds: number = millisecondsToSeconds(millis)
        return oneDecimal(seconds / 60)
    }
    else if (timeUnit === TimeUnits.SECOND) {
        return millisecondsToSeconds(millis);
    }
    else {
        console.log("Wrong timeunit")
        return -1;
    }
}

export const timeUnitConverterMinutsHoursDays = (millis): { value: number, unit: string } => {
    const timeUnit = getTimeUnit(millis);

    if (timeUnit === TimeUnits.DAY) {
        const hours = millisecondsToHours(millis);
        const days = Math.floor(hours / 24);
        return { value: days, unit: "d" };
    }
    else if (timeUnit === TimeUnits.HOUR) {
        return { value: millisecondsToHours(millis), unit: "h" };
    }
    else {
        return { value: millisecondsToMinutes(millis), unit: "m" };
    }
}

export const convertTimestampToStringObject = (date: number): IDateObject => {
    // const d = setDateToZeroTime(new Date(date));
    const d = new Date(date);
    const year = d.getFullYear().toString();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const da = (d.getDate()).toString().padStart(2, "0");
    const hours = (d.getHours()).toString().padStart(2, "0");
    const minutes = (d.getMinutes()).toString().padStart(2, "0");
    const seconds = (d.getSeconds()).toString().padStart(2, "0");
    return { year, month, date: da, hours, minutes, seconds }
}

export const getDateFromStringDate = (date: string): string => {
    return date.substring(6, 8);
}

export const getNumberOfWeeksFromMillis = (millis: number): number => {
    if (millis >= Millis.week) {
        return Math.floor(millis / Millis.week);
    }
    return 0;
}

export const getNumberOfDaysFromMillis = (millis: number): number => {
    if (millis >= Millis.day) {
        return Math.floor(millis / Millis.day);
    }
    return 0;
}

export const getNumberOfHoursFromMillis = (millis: number): number => {
    if (millis >= Millis.hour) {
        return Math.floor(millis / Millis.hour);
    }
    return 0;
}

export const getNumberOfMinutesFromMillis = (millis: number): number => {
    if (millis >= Millis.minute) {
        return Math.floor(millis / Millis.minute);
    }
    return 0;
}

export const getNumberOfSecondsFromMillis = (millis: number): number => {
    if (millis >= Millis.second) {
        return Math.floor(millis / Millis.second);
    }
    return 0;
}

export const test = (s: number) => {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return leadingZero(hrs) + ':' + leadingZero(mins) + ':' + leadingZero(secs);
}

export const getDuration = (millis: number): string => {  
    let duration = "";
    let negativeDuration = false;
    if(millis < 0){
        negativeDuration = true;
        millis *= -1;
    }

    if (millis <= 0) {
        duration = "";
    }
    // const durationObject = convertMillisToDurationObject(millis);
    if (millis < Millis.second) {
        duration = millis.toString() + "ms";
    }
    else if (millis < Millis.minute) {
        duration = millisecondsToSeconds(millis).toString() + "s";
    }
    else if (millis < Millis.hour) {
        duration = millisecondsToMinutes(millis).toString() + "m";
    }
    else if (millis < Millis.day) {
        duration = Math.round(millis / Millis.hour).toString() + "h";
    }
    else if (millis < Millis.week) {
        duration = Math.round(millis / Millis.day).toString() + "d";
    }
    else if (millis < Millis.month) {
        duration = Math.round(millis / Millis.week).toString() + "v";
    }
    else if (millis < Millis.year) {
        const months = Math.round(millis / Millis.month)
        const daysMillis = millis - (months * Millis.month);
        const days = Math.round(daysMillis / Millis.day).toString()
        duration = months + "mo" + days + "d";
    }
    else if (millis >= Millis.year) {
        // duration = Math.round(millis / Millis.year).toString() + "y";
        const years = Math.round(millis / Millis.year)
        const monthsMillis = millis - (years * Millis.year);
        const months = Math.round(monthsMillis / Millis.month).toString()
        duration = years + "y" + months + "mo";
    }
    else {
        return "error"
    }
    return negativeDuration ? "-" + duration : duration;
    // return `${durationObject.weeks ? durationObject.weeks + "v" : ""}${durationObject.days ? durationObject.days + "d" : ""}${durationObject.hours ? durationObject.hours + "t" : ""}${durationObject.minutes ? durationObject.minutes + "m" : ""}${durationObject.seconds ? durationObject.seconds + "s" : ""}`
}

export const convertMillisToDurationObject = (millis: number) => {
    const weeks = getNumberOfWeeksFromMillis(millis)
    let rest = (millis - (weeks * Millis.week))
    const days = getNumberOfDaysFromMillis(rest);
    rest = (rest - (days * Millis.day))
    const hours = getNumberOfHoursFromMillis(rest);
    rest = (rest - (hours * Millis.hour))
    const minutes = getNumberOfMinutesFromMillis(rest);
    rest = (rest - (minutes * Millis.minute))
    const seconds = getNumberOfSecondsFromMillis(rest);
    rest = (rest - (seconds * Millis.second))
    return { weeks, days, hours, minutes, seconds }
}

export const convertTimestampToStringDate = (date: number): string => {
    const dateObject = convertTimestampToStringObject(date);
    const stringDate = `${dateObject.year}${dateObject.month}${dateObject.date}`
    if(stringDate.toLowerCase().includes("nan")){
        return undefined;
    }
    return stringDate;
}

export const covertTimestampToStringTime = (date: number): string => {
    const dateObject = convertTimestampToStringObject(date);
    const stringTime = `${dateObject.hours}:${dateObject.minutes}`
    return stringTime;
}

export const convertTimestampToStringDateAndTime = (date: number): string => {
    if (!date) {
        return "";
    }
    return `${convertTimestampToStringDate(date)}-${covertTimestampToStringTime(date)}`;
}

export const getTimeLeft = (futureTime: number): string => {
    if (!Number.isInteger(futureTime)) {
        return "";
    }
    const durationObject = intervalToDuration({ start: new Date(), end: new Date(futureTime) })
    const timeLeft = `
    ${durationObject.days ? leadingZero(durationObject.days.toString() + "d") : ""}${durationObject.hours + "t" ? ((durationObject.days ? ":" : "") + leadingZero(durationObject.hours.toString() + "h")) : ""}${durationObject.minutes ? ((durationObject.days + "d " || durationObject.hours + "t " ? ":" : "") + leadingZero(durationObject.minutes.toString() + "m")) : ""}${durationObject.seconds + "s" ? ((durationObject.days + "d" || durationObject.hours + "t" || durationObject.minutes + "m" ? ":" : "") + leadingZero(durationObject.seconds.toString() + "s")) : ""}`
    return timeLeft;
}

export const convertTimestampToTimepickerStringTime = (timestamp: number): string => {
    const timeObject = convertTimestampToStringObject(timestamp);
    const timepickerStringTime = `${timeObject.hours}:${timeObject.minutes}`
    return timepickerStringTime;
}

export const convertDateObjectToTimepickerStringTime = (timestamp: number): string => {
    const timeObject = convertTimestampToStringObject(timestamp);
    const timepickerStringTime = `${timeObject.hours}:${timeObject.minutes}`
    return timepickerStringTime;
}


export const convertDateObjectToString = (date: Date): string => {
    const timestamp = date.getTime();
    const stringValue = convertTimestampToStringDate(timestamp);
    return stringValue;
}

export const convertStringDateToWeekday = (date: string): string => {
    const weekday = format(new Date(convertStringDateToTimestamp(date)), 'EEEE')
    return weekday;
}

export const convertStringTimeAndDateToTimestamp = (date: string, time: string = "00:00"): number => {
    const dateString = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6);
    const dateTimeString = `${dateString}T${time}`;
    const dateTime = new Date(dateTimeString);
    return dateTime.getTime();
}

export const convertStringDateToWeekdayEnum = (date: string): WeekdayEnums => {
    const d = format(new Date(convertStringDateToTimestamp(date)), 'EEEE');
    // if(d === "Monday"){
    //     return WeekdayEnums.MONDAY
    // }
    // else if(d === "Tuesday"){
    //     return WeekdayEnums.TUESDAY
    // }
    // else if(d === "Wednesday"){
    //     return WeekdayEnums.WEDNESDAY
    // }
    // else if(d === "Thursday"){
    //     return WeekdayEnums.THURSDAY
    // }
    // else if(d === "Friday"){
    //     return WeekdayEnums.FRIDAY
    // }
    // else if(d === "Saturday"){
    //     return WeekdayEnums.SATURDAY
    // }
    // else if(d === "Sunday"){
    //     return WeekdayEnums.SUNDAY
    // }
    // else{
    return d as WeekdayEnums
    // }
}

export const timestampHasPassed = (timestamp: number): boolean => {
    return timestamp < new Date().getTime();
}

export const dateHasPassed = (date: string): boolean => {
    const today: string = getToday();
    return date < today;
}

export const convertStringDateToTimestamp = (date: string): number => {
    if (date.length !== 8) {
        console.error("faulty length of date string")
        return -1;
    }
    else if (date) {
        const year = parseInt(date.substring(0, 4));
        const month = parseInt(date.substring(4, 6)) - 1
        const d = parseInt(date.substring(6, 8));
        const newDate = setDateToZeroTime(new Date(year, month, d))
        return newDate.getTime();
    }
    else { return -1; }
}

//Behöver testas och verifieras att den funkar
// export const dateIsThisWeek = (date: string, dateOfWantedWeek: string = getToday()): boolean => {
//     if (date) {
//         const weekdate = convertStringDateToTimestamp(dateOfWantedWeek)
//         const from = convertDateObjectToString(startOfWeek(weekdate));
//         const to = addDaysStringDate(from, 7)
//         return isInDateSpan(date, from, to);
//     }
//     return false
// }

export const timeSinceTimestamp = (timestamp: number): string => {
    return getDuration(getNow() - timestamp);
}

export const getStartOfDayDateObj = (date: Date): Date => {
    const startDay = startOfDay(date);
    return startDay;
}

export const getStartOfdayTimestamp = (date: Date): number => {
    const startDay = startOfDay(date);
    return startDay.getTime();
}

export const getStringDatesBetween = (from: string, to: string): string[] => {
    const eachDate = eachDayOfInterval({ start: convertStringDateToTimestamp(from), end: convertStringDateToTimestamp(to) })
    const dates = []
    for (const d of eachDate) {
        const stringDate = convertTimestampToStringDate(d.getTime());
        dates.push(stringDate);
    }
    return dates;
}

export const getToday = (): string => {
    const d = new Date().getTime();
    return convertTimestampToStringDate(d);
}

export const getNow = (): number => {
    return new Date().getTime();
}

export const addDaysStringDate = (currentDate: string, add: number): string => {
    const timestamp = convertStringDateToTimestamp(currentDate);
    const nextDate = addDays(timestamp, add);
    const nextDateString = convertDateObjectToString(nextDate);
    return nextDateString;
}

export const getWeekdayIndex = (stringDate: string = getToday()): number => {
    const timestamp = convertStringDateToTimestamp(stringDate);
    let weekdayIndex = getDay(timestamp);
    weekdayIndex--
    if (weekdayIndex < 0) {
        weekdayIndex = 6
    }
    return weekdayIndex;
}

export const subDaysStringDate = (currentDate: string, sub: number): string => {
    const timestamp = convertStringDateToTimestamp(currentDate);
    const nextDate = subDays(timestamp, sub);
    const nextDateString = convertDateObjectToString(nextDate);
    return nextDateString;
}

export const isInDateSpan = (date, from, to) => {
    const dateTimestamp = convertStringDateToTimestamp(date);
    const fromTimestamp = convertStringDateToTimestamp(from);
    const toTimestamp = convertStringDateToTimestamp(to);
    if (dateTimestamp >= fromTimestamp && dateTimestamp <= toTimestamp) {
        return true;
    }
    return false;
}

export const diffNumberOfDaysBetweenStringDates = (firstDate: string, secondDate: string): number => {
    if (!firstDate || !secondDate) {
        return -1;
    }
    const firstTimestamp = convertStringDateToTimestamp(firstDate);
    const secondTimestamp = convertStringDateToTimestamp(secondDate);
    const diff = differenceInCalendarDays(Math.max(firstTimestamp, secondTimestamp), Math.min(firstTimestamp, secondTimestamp));
    return diff;
}

export const getTimestampFromStringDateAndTime = (date: string, time: string = "12:00:00"): number => {
    const dateString = date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6);
    const d = new Date(`${dateString}T${time}`)
    return d.getTime();
}

export const getCurrentTime = () => new Date().getTime();

export const daysLeft = (date: number) => {

    var msPerDay = 8.64e7;

    // Copy dates so don't mess them up
    const x0 = new Date();
    const x1 = new Date(date);

    // Set to noon - avoid DST errors
    x0.setHours(12, 0, 0);
    x1.setHours(12, 0, 0);

    const test = (x1.getTime() - x0.getTime()) / msPerDay
    // Round to remove daylight saving errors
    return Math.round(test);

}

export const roundToHour = (timestamp: number) => {
    const date = new Date(timestamp);
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds
    return covertTimestampToStringTime(date.getTime())
}

export const getZeroTimestampForDate = (date: string): number => {
    const timestamp = convertStringDateToTimestamp(date);
    const newDate = new Date(timestamp).setHours(0, 0, 0, 0);
    return newDate;
}

// export const getCurrentScheduledActivityTime = (activityAverageTime: number, scheduledActivity: IScheduledActivity) => {
//     return scheduledActivity.getTime(activityAverageTime)
// }