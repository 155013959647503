import * as React from 'react'
import { CalendarData } from '../../../components/calendar/CalendarData'
import { Column } from '../../../components/layout/column/Column'
import { convertTimestampToTimepickerStringTime, getToday } from '../../../utils/TimeUtils';
import { DataStore } from '../../../app/DataStore/DataStore';

const Diary = ({dataStore}: {dataStore: DataStore}/* {dates}: {dates: CalendarData[]} */) => {
    const [dates, setDates] = React.useState<CalendarData[]>([]);

    React.useEffect(() => {
        console.log("hämtar datum")
        // dataContext.calendarData.getDates("20230401", getToday(), false, true, dataContext).then((dates) => {
        //     console.log("klar")
        //     setDates(dates.filter(date => date.data?.logs?.length > 0));
        // })
        dataStore.data.calendarDates.getAllFromFirebase().then(data => {
            setDates(Object.values(data));
        })
    }, [])
    
  return (
    <Column>
        {dates.reverse().filter(d => d.data?.logs?.length).map((date, index) => {
            console.log(date);
            return <Column key={index}>
                <h2>{date.date}</h2>
                <Column>
                    {date.data.logs.map((log, index) => {
                        return <Column key={index}>
                            {log.title && <h3>{log.title}</h3>}
                            {convertTimestampToTimepickerStringTime(log.created)} : {log.content}
                        </Column>
                    })}
                </Column>
            </Column>
        })}
    </Column>
  )
}

export {Diary}