import * as React from 'react'
import { Button } from '../button/Button';
import { Activity } from '../../app/DataStore/ItemTypes/Activity';
import { ActivityStatus } from '../../utils/constants';
import { Column } from '../layout/column/Column';
import { MarginBottom } from '../layout/margin/MarginBottom';
import { DateTimepicker } from '../pickers/datetime_picker/DateTimepicker';
import { ScheduledActivity } from '../activity_generator/ScheduledActivity';
import { generateId } from '../../utils/Utils';
import { DurationPicker } from '../pickers/duration_picker/DurationPicker';
import Modal from '../modal/Modal';
import { ActivityRulesViewer } from './ActivityRulesViewer';
import { Table } from '../table/Table';
import { convertTimestampToStringDateAndTime } from '../../utils/TimeUtils';
import ScheduledActivityViewer from '../../module/modules/ScheduledActivityCardModule/ScheduledActivityViewer';

const ActivityViewer = ({ activity, sendToTodoList, onUpdateActivity, onDeleteActivity, onCreateScheduledActivity, latestScheduledActivities, onUpdateScheduledActivity, onDeleteScheduledActivity }: { activity: Activity, sendToTodoList, onUpdateActivity, onDeleteActivity, onCreateScheduledActivity, latestScheduledActivities: ScheduledActivity[], onUpdateScheduledActivity, onDeleteScheduledActivity }) => {
    const [plannedStartTime, setPlannedStartTime] = React.useState<Date>(new Date());
    const [showModal, setShowModal] = React.useState("");

    const rows = latestScheduledActivities.sort((a, b) => {
        if (a.status === ActivityStatus.DONE && b.status !== ActivityStatus.DONE) return 1;
        if (a.status !== ActivityStatus.DONE && b.status === ActivityStatus.DONE) return -1;
        return a.startTime - b.startTime;
    }).map(scheduledActivity => {
        return {
            id: scheduledActivity.id,
            cells: [
                { columnId: "id", content: scheduledActivity.id },
                { columnId: "startDate", content: scheduledActivity.startTime && convertTimestampToStringDateAndTime(scheduledActivity.startTime) || "" },
                { columnId: "stopDate", content: scheduledActivity.stopTime && convertTimestampToStringDateAndTime(scheduledActivity.stopTime) || "" },
                { columnId: "status", content: scheduledActivity.status }
            ]
        }
    })

    const columns = [
        {
            id: "id",
            label: "ID",
            onClickCellModal: async (...params) => {
                const scheduledActivity = latestScheduledActivities.find(scheduledActivity => scheduledActivity.id === params[0].id)
                return <ScheduledActivityViewer
                    scheduledActivity={scheduledActivity}
                    onDeleteScheduledActivity={(scheduledActivity) => {
                        onDeleteScheduledActivity(scheduledActivity)
                        setShowModal("")
                    }}
                    onUpdateScheduledActivity={onUpdateScheduledActivity}
                />
            }
        },
        {
            id: "startDate", 
            label: "Startdatum", 
        },
        { id: "stopTime", label: "Slutdatum" },
        { id: "status", label: "Status" },
    ]

    const renderScheduledActivitiesTable = () => {
        return <Table settingsId={''}
            columns={columns}
            rows={rows}
        />
    }

    return (
        <Column alignLeft>
            <h1>{activity.name}</h1>
            <span>{activity.id}</span>
            <MarginBottom />
            <Button dataTestid="send-to-todo-button" label={'Köa upp aktiviteten'} onClick={() => {
                sendToTodoList && sendToTodoList(activity);
                // onSendToTodoList && onSendToTodoList(activity);
            }} />
            <MarginBottom />
            <Button dataTestid="manual-scheduling-button" label={'Planerad starttid för aktiviteten'} onClick={() => setShowModal("starttime")} />
            <MarginBottom />
            <Button dataTestid="automate-scheduling" label={'Automatisera schemaläggning'} onClick={() => {
                setShowModal("automate");
            }} />
            <MarginBottom />
            <Button dataTestid="delete-activity" label={'Ta bort aktivitet'} onClick={async () => {
                onDeleteActivity(activity);
            }} />
            <Modal showModal={!!showModal} setShowModal={setShowModal}>
                {showModal === "automate" && <div>
                    <ActivityRulesViewer
                        activity={activity}
                        onIntervalChange={(interval: number) => {
                            activity.interval = interval;
                            onUpdateActivity(activity);
                            // setShowModal("")
                        }
                        }
                        // rules={Object.keys(activity?.rules)?.length > 0 ? Object.values(activity.rules) : []}
                        onUpdateActivity={(activity: Activity) => {
                            onUpdateActivity(activity)
                            setShowModal("")
                        }}
                    />
                    <MarginBottom />
                </div>}
                {showModal === "starttime" && <>
                    {
                        activity && <DateTimepicker
                            defaultTime={new Date()}
                            separateTimeAndDate
                            onChange={setPlannedStartTime}
                        />
                    }
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        const newScheduledActivity: ScheduledActivity = new ScheduledActivity({ id: generateId(), name: activity.name, status: ActivityStatus.NOT_STARTED, activityId: activity.id, startTime: plannedStartTime.getTime(), interval: activity.interval });
                        onCreateScheduledActivity(newScheduledActivity);
                        // this.closeModal()
                    }} />
                </>}
                {showModal === "interval" && <>
                    <DurationPicker
                        defaultValue={activity.interval || 0}
                        onChange={(millis: number) => {
                            activity.interval = millis;
                        }}
                    />
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        onUpdateActivity(activity);
                    }} />
                </>}
                {showModal === "starttime" && <>
                    <DurationPicker
                        defaultValue={activity.interval || 0}
                        onChange={(millis: number) => {
                            activity.interval = millis;
                        }}
                    />
                    <Button dataTestid="confirm-button" label={'Bekräfta'} onClick={() => {
                        onUpdateActivity(activity);
                    }} />
                </>}
            </Modal>
            {/* {latestScheduledActivities && latestScheduledActivities.length > 0 && latestScheduledActivities.map(scheduledActivity => {
                return <Column>
                    <span>{scheduledActivity?.content}</span>
                    <span>{scheduledActivity?.getStartTimeDate()}</span>
                </Column>
            })} */}
            {renderScheduledActivitiesTable()}
        </Column>
    )
}

export { ActivityViewer }