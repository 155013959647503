import React from 'react'
import { Button } from '../../components/button/Button';
import "./Test.scss";
import { createStartkitFirebase, IStartkit, Signup } from '../../login/Signup';
import { Column } from '../../components/layout/column/Column';
import { Login } from '../../login/Login';
import { MarginBottom } from '../../components/layout/margin/MarginBottom';
import { Row } from '../../components/layout/row/Row';
import { MarginRight } from '../../components/layout/margin/MarginRight';
import { useNavigate } from 'react-router-dom';

export const Home = ({onUser}: {onUser: Function}) => {

    const [showLogin, setShowLogin] = React.useState(true);
    const [showCreateAccount, setShowCreateAccount] = React.useState(false);
    let navigate = useNavigate();

    const LoginButton = () => <Button label={'Logga in'} onClick={() => {
        setShowLogin(true);
        setShowCreateAccount(false)
    }} />

    const SignupButton = () => <Button label={'Skapa konto'} onClick={() => {
        setShowCreateAccount(true);
        setShowLogin(false);
    }} />

    return (
        <>
            {showLogin && <Column>
                <Login onUser={onUser}/>
                <MarginBottom />
                <SignupButton />
            </Column>}
            {showCreateAccount && <Column>
                <Signup defaultName="TestUsername" defaultEmail="test@test.test" defaultPassword='test123' onCreateUser={(startkit: IStartkit) => {
                    createStartkitFirebase(startkit);
                    navigate(`/home`);
                }} />
                <MarginBottom />
                <LoginButton />
            </Column>}
            {!showLogin && !showCreateAccount && <Row>
                <LoginButton />
                <MarginRight />
                <SignupButton />
            </Row>
            }
            <MarginRight />
            {/* <LoginTestUserButton /> */}
        </>
    )
}
